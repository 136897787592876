import React from 'react';
import GetLoiSongHangNgay from './GetLoiSongHangNgay';
import PhatThanhTinLanh from './PhatThanhTinLanh';
const CardForAudio = (props) => {
    return (
        <div className='container'>
            <div className="row">
                <div className="col-sm-6">
                    <div className="card w-400">
                        <div className="card-body">
                            <h5 className="card-title">Lời Sống Hằng Ngày</h5>
                            <p className="card-text">Câp nhật mỗi ngày</p>
                            <GetLoiSongHangNgay />

                            {/*<a href="#" className="btn btn-primary">Go somewhere</a>*/}
                            <img src="https://thuvien.loichua.church/wp-content/uploads/2022/03/loisonghangngay-banner1.png" class="card-img-bottom" alt="bắt đầu ngày mơi với lời Chúa"></img>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="card w-400">
                        <div className="card-body">
                            <h5 className="card-title">Phát Thanh Tin Lành</h5>
                            <p className="card-text">Cập nhật mỗi tuần</p>
                            
                            <h2 style={{ color: "blueviolet" }}><a href='/' style={{ color: 'royalblue' }}>Phát sóng trên Radio Houston</a></h2>
                            <PhatThanhTinLanh />
                            {/*<a href="#" className="btn btn-primary">Go somewhere</a>*/}
                            <img src="https://thuvien.loichua.church/wp-content/uploads/2022/03/phatthanhtinlanh-banner.png" class="card-img-bottom" alt="phat thanh tin lanh radio houston texas"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CardForAudio;