import React from 'react';
//import { getClassSet } from 'react-bootstrap/lib/utils/bootstrapUtils';

const PaymentContext = React.createContext({
    version: '1.0.0',
    package0001: 931.25,  //King1A
    package0002: 1212.50,  //King2A
    package002A: 1493.75 ,  //kING3A 
    package0003: 1775.00,   //King4A
    package0004: 1496.00, //King2A2K
    package0005: 1212.50,  //Double2A
    package005A: 1354.25,  //Double2A1K
    
    package0006: 1775.00,  //Double4A
    package0007: 1496.00,  //double 2A2K
    package0008: 2058.50,  //double 4A2K
    package0009: 1779.50,   //double 2A4K
    
    API_KEY: '8e7j3hWm7QJ',
    TRANSACTION_KEY:'9nh6C4qjS2Nx9q78',   //'7Bu3k7tYX5tA4e5r' , 
    CLIENT_KEY: '8V7d494usnRwmYaUyJP9DE2WKdNj52XFf2vz35zTxbjpF95fAQ5G9TCLuU7az7Xg'
    
   
})
export default PaymentContext;