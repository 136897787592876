import React, { useState, useContext } from 'react';
import axios from 'axios';
import AuthContext from './store/auth';

const ContactForm = (props) => {
    const useCtx = useContext(AuthContext);
    const [fname, setFname] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('')
    const fnameHandler = (event) => {
        setFname(event.target.value);
    }
    const emailHandler = (event) => {
        setEmail(event.target.value);
    }
    const subjectHandler = (event) => {
        setSubject(event.target.value);
    }
    const messageHandler = (event) => {
        setMessage(event.target.value);
    }

    const formHandler = (event) => {
        event.preventDefault();
        const submitData = {
            fname: fname,
            email: email,
            subject: subject,
            message: message
        }
        console.log('got all submitData ' + JSON.stringify(submitData));
        axios.post(useCtx.sendEmailURL, submitData).then(
            response => {
                console.log(response.data)
                console.log('i got ' + response.status);
                if (response.status === 200) {
                    console.log('im here')
                    window.location.replace(useCtx.successURL);
                }
                else {
                    console.log('cant do')
                }
            }
        ).catch(error => {
            console.log('got error ' + error)
        })

    }
    return (

        <React.Fragment>

            <section class="contact default-section-spacing">

                <div class="container">

                    <form class="form contact__form" onSubmit={formHandler}>

                        <div class="row">

                            <div class="flex-md-6">

                                <div class="form__group">
                                    <label for="fname" class="form__label">Your first name <span class="color-danger">*</span></label>
                                    <input type="text" id="fname" class="form__input" name="fname" placeholder="Enter your full name" onChange={fnameHandler} />
                                </div>
                            </div>
                            <div class="flex-md-6 mar-b-md-2">

                                <div class="form__group">
                                    <label for="email-2" class="form__label">Your email address <span
                                        class="color-danger">*</span></label>
                                    <input type="email" id="email-2" class="form__input" name="email"
                                        placeholder="Enter your email address" onChange={emailHandler} />
                                </div>
                            </div>
                            <div class="flex-md-12 mar-b-md-2">

                                <div class="form__group">
                                    <label for="subject" class="form__label">Subject <span class="color-danger">*</span></label>
                                    <input type="text" id="subject" class="form__input" name="subject" placeholder="Enter your subject" onChange={subjectHandler} />
                                </div>
                            </div>
                            <div class="flex-md-12 mar-b-md-2">

                                <div class="form__group">
                                    <label for="message-2" class="form__label">Message <span class="color-danger">*</span></label>
                                    <textarea name="message" id="message-2" class="form__textarea form__input"
                                        placeholder="Enter your message..." onChange={messageHandler}></textarea>
                                </div>
                            </div>
                            <div class="flex-md-12">

                                <button class="button--primary button--fill" type="submit" name="submit">Send message</button>

                            </div>

                        </div>
                    </form>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ContactForm;
