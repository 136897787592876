import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'whatwg-encoding'
const WpPosts = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get('https://thuvien.loichua.church/wp-json/wp/v2/posts', 
               { headers:{
				'Access-Control-Allow-Origin':'https://loichua.church',
              }
        })
        setPosts(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {isLoading && <p>Loading posts...</p>}
      {error && <p>Error: {error.message}</p>}
      {posts.map((post) => (
        <div key={post.id}>
            <h3 style={{ color: '#ac645e' , fontSize:'1rem'}} dangerouslySetInnerHTML={{__html:post.title.rendered}} />
           <p><a href={post.link}>Đọc thêm...</a></p>
        </div>
      ))}
    </div>
  );
};

export default WpPosts;
