import React, { useState, useRef, useContext} from 'react'
//import Form from 'react-bootstrap/Form'
import { Form, Button, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import AuthContext from '../store/auth'
import NavBarSummerCamp from './NavBarSummerCamp'
import Popup from "reactjs-popup"
import { InfoCircle  , PencilSquare, CreditCard , EnvelopeAt,  FileBarGraph, PersonStanding, PersonStandingDress} from 'react-bootstrap-icons'


const MyModal = () => {

    return (
        <Popup trigger={<button className="button lead"><InfoCircle /><span style={{ color: 'darkblue', fontSize: '1.2rem', fontWeight: 'bold' }}> Read Me First! </span></button>} modal closeOnDocumentClick >
            {close => (<>

                <div className='d-flex justify-content-end'><a className='close' onClick={close}>[&times;]</a></div>
                <div className="card">
                    {/*
                    <div className="card-header text-primary">
                        <h2>&nbsp;</h2>
                    </div>
                    */}
                    <div className="card-body">
                       
                        <div className="d-flex justify-content-center">
                             
                                 <h5 className='Display-4'>Instruction to fill out the form</h5>
                                 
                            <ul>
                                  <li style={{color:'mediumvioletred', fontSize:'1rem'}}>You can only allow to use your phone and email once or it will cause 'duplication' errors</li>
                                  <li style={{color:'mediumvioletred', fontSize:'1rem'}}>Please complete  one (1) registration per family with children</li> 
                                  <li style={{color:'mediumvioletred', fontSize:'1rem'}}>If your group are all adults (11 years old and older) , you can register many dependents in one form</li>
                                    
                                  
                                  <li> <PencilSquare /> Step 1:  when you complete the form and click on Register / Đăng ký - This is only REGISTRATION and NO PAYMENT yet </li> 
                                   <li>&nbsp;</li>
                                  <li> <CreditCard />Step 2:  You can send payment by 
                                                        
                                   </li> 
                                    <li>Either by check to:
                                         Please make a Check / Money Order payable to
										"Austin Vietnamese Alliance Church" , memo "Camp 2024"
										
										Address: MS Tran Ngoc Hoa
										12708 Serafy Ct.
										Austin, TX 78753
                                    
                                    </li>
                                   <li>OR if you choose to pay by Zelle, then zelle payment to this email address: billing@tinlanhaustin.church</li>
                                  <li> <EnvelopeAt /> Payment status is responded with email confirmation</li>
                                  <li>Thank you and looking forward to seeing you at Summer Camp 2024</li>
                                  <li>God Bless!</li>
                                  <li>&nbsp;</li>
                                                      
                            </ul>
                              
                             <blockquote>
                                 <h6 className='Display-4'>Need help or question regarding registration:</h6> 
                                 <h6 className='Display-4'>Contact: <br/> David Bui / Thien Bao / Pham Quang Phuc</h6>
                                 <h6 className='Display-4'>Email to: admin@loichua.church</h6>
                             </blockquote>  
                        </div>
                    </div>
                </div>
            </>
            )}

        </Popup>
    )
};


//import validator from 'validator'
const TraiHeRegoForm = () => {
    const useCtx = useContext(AuthContext)
    const [cbNewCamper, setCbNewCamper] = useState(false)
    const [cbReturnCamper, setCbReturnCamper] = useState(false)
    const [fullName, setFullName] = useState('')
    const [age, setAge] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zipcode, setZipCode] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [churchName, setChurchName] = useState('')
    const [pastorName, setPastorName] = useState('')
    const [numDependents, setNumDependents] = useState('')
    const [shirtSize, setShirtSize] = useState('')
    const numDepInput = useRef('')
    const [personArray, setPersonArray] = useState([])
    const [gender, setGender] = useState('')
    const [numAdult, setNumAdult] = useState('')
    const [numChildren, setNumChildren] = useState('')
    const [feeOpt, setFeeOpt] = useState('')
    const [totalPayAble, setTotalPayAble] = useState('')
    const [dbResponse, setDbResponse] = useState('')
    const [dependList, setDependList] = useState([])
    const [dependValue, setDependValue] = useState([])
    const [numSmall, setNumSmall] = useState('')
    const [numMed, setNumMed] = useState('')
    const [numLarge, setNumLarge] = useState('')
    const [numXLarge, setNumXLarge] = useState('')
    localStorage.clear()

    

    const dependentDataList = []
    const PersonSubForm = (props) => {
        //const [dependValue, setDependValue] = useState([])
        //const dependentDataList = []
        const memberHandler = event => {
            event.preventDefault()
            let dependentDataObj = {}
            //console.log('to do with total dependents ' + numDependents)
            const strData = event.target.value
            const inputName = event.target.name
            console.log(' here data from form ' + strData)
            console.log(' here name of input = ' + inputName)
            dependentDataObj['value'] = strData
            //dependentDataObj['index']=i
            dependentDataList.push(dependentDataObj)
            //setDependValue(dependValue=>[dependValue,...dependentDataObj])
            //localStorage.setItem(inputName,strData)    
            console.log(' here i set dependvalue ...')
            //setDependValue(dependentDataList)


        }
        const personTotal = props.personTotal
        const arrayRange = (start, stop, step) =>
            Array.from(
                { length: (stop - start) / step + 1 },
                (value, index) => start + index * step
            );
        console.log(' got person total forms required = ' + personTotal)
        const personArr = arrayRange(1, parseInt(personTotal), 1)
        //const personArray= [...personArr]
        console.log(' got person array 1 ' + personArr[1] + ' type of ' + typeof (personArr))
        // get a list of object based on number of dependents
        const listOfObjects = [];
        personArr.forEach(function (entry) {
            var singleObj = {}
            singleObj['index'] = entry;
            singleObj['value'] = 'person' + entry;
            listOfObjects.push(singleObj);
        });
        //setPersonArray(listOfObjects)
        console.log(' now i got list of ' + JSON.stringify(listOfObjects))
        for (let i = 0; i < listOfObjects.length; i++) {
            console.log('i got list obj index ' + listOfObjects[i]['value'])
            return (
                listOfObjects.map((obj) => (
                    <Row key={obj.index}>
                        <Col xs={7}>
                            <Form.Control type='text' onBlur={memberHandler} placeholder='john doe,  son, 12, male ' name={obj.value} />

                        </Col>


                        <li key={obj.index}>{obj.value}, relationship, age , gender</li>
                    </Row>
                )
                ))

        }
    }//end personsubfrom

    const memberHandler = event => {
        event.preventDefault()
        let dependentDataObj = {}
        //console.log('to do with total dependents ' + numDependents)
        const strData = event.target.value
        const inputName = event.target.name
        console.log(' here data from form ' + strData)
        console.log(' here name of input = ' + inputName)
        dependentDataObj['value'] = strData
        //dependentDataObj['index']=i
        dependentDataList.push(dependentDataObj)
        //setDependValue(dependValue=>[dependValue,...dependentDataObj])
        //localStorage.setItem(inputName,strData)    

        //setDependValue(dependentDataList)


    }
    //console('***  got dep data list ' + dependentDataList)
    //const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const newCamperStatusHandler = (event) => {
        setCbNewCamper(event.target.value === 'on' ? true : false)
    }
    const returnCamperStatusHandler = (event) => {
        setCbReturnCamper(event.target.value === 'on' ? true : false)
    }
    const nameChangeHandler = event => {
        setFullName(event.target.value)
    }
    const ageChangeHandler = event => {
        setAge(event.target.value)
    }
    const addressHandler = event => {
        setAddress(event.target.value)
    }
    const cityHandler = event => {
        setCity(event.target.value)
    }
    const zipcodeHandler = event => {
        setZipCode(event.target.value)
    }
    const stateHandler = event => {
        setState(event.target.value)
    }
    const phoneHandler = event => {
        setPhone(event.target.value)
    }
    const isEmail = (email) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    const emailHandler = event => {
        setEmail(event.target.value)

    }
    const churchNameHandler = event => {
        setChurchName(event.target.value)
    }
    const pastorNameHandler = event => {
        setPastorName(event.target.value)
    }
    const numDependentHandler = event => {
        let value = event.target.value
        setNumDependents(event.target.value)
        // let value = numDepInput
        console.log('** here i have num dep input ref= ' + value)
        numDepInput.current = value
        console.log(" +++ have ref = " + numDepInput.current)
        if (numDependents) {
            console.log(' i need to return a ccomp here')
        }
    }
    const shirtSizeHandler = event => {
        setShirtSize(event.target.value)
    }
    const getMaleGender = event => {
        setGender(event.target.value === 'on' ? 'M' : '')
    }
    const getFemaleGender = event => {
        setGender(event.target.value === 'on' ? 'F' : '')
    }
    const numAdultHandler = event => {
        event.preventDefault()
        setNumAdult(event.target.value)
    }
    const numChildrenHandler = event => {
        event.preventDefault()
        setNumChildren(event.target.value)
    }
    const dateInPast = (firstDate, secondDate) => firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0);

    const feeHandler = event => {
        event.preventDefault()
        const feeOpt = event.target.value
        setFeeOpt(feeOpt)

    }
    const updateDependList = (numDependents) => {
        console.log('**** i call func to update depend list ')
        let perVal = ''
        for (let i = 0; i++; i < numDependents) {
            perVal = localStorage.getItem('person' + i.toString)
            console.log(' here i got person value  ' + i.toString + '=' + perVal)
        }
    }
    const getCamperData = (event) => {
        event.preventDefault()
        //setDbResponse('')
        console.log('im click to submit with some value bnew camper=' + cbNewCamper + ' &  bReturn= ' + cbReturnCamper + '\n full name =' + fullName +
            '\n age=' + age + ' Gender=' + gender + '\n address ' + address + ' city= ' + city + ' zipcode= ' + zipcode + '\n church attend=' + churchName + '\n pastor name ' + pastorName +
            '\n no. of dependent=' + numDependents + '\n' + JSON.stringify(dependentDataList) + '\n feeOpt ' + feeOpt + '\n total adult ' + numAdult + '\n num children ' + numChildren + '\n' +
            'dependant value= ' + JSON.stringify(dependValue)

        )//' + bNewCamper + ' bRetCamper:  ' + bRetCamper)
        const now = new Date()//.toLocaleDateString('en-US')
        console.log('time now ' + now)
        //updateDependList(numDependents)
        let numChildrenPay = 0
        let total_payable = 0.00
        const future = new Date('2024-05-06')
        const past = new Date('2024-01-01')
        console.log('time now ' + now)
        console.log(dateInPast(now, future)) //still not there yet true
        console.log(dateInPast(now, past)) // false 
        //if (dateInPast(now, future)) {
        console.log(' im still not there in future date 5/1/2023 yet')
        const motelFeeAdult = 195.00
        const motelFeeChild = 115.00
        const domFeeAdult = 145.00
        const domFeeChild = 85.00
        const noSleepOverFeeChild = 20.00
        const noSleepOverFeeAdult = 40.00
        if (numChildren >= 4) {
            numChildrenPay = numChildren - 2

        }
        else {
            numChildrenPay = numChildren
        }
        if (feeOpt === 'Dorm') {
            total_payable = numChildrenPay * domFeeChild + numAdult * domFeeAdult
            console.log(' total payable before 1/5/2023 ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)

        }
        else if (feeOpt === 'Motel') {
            total_payable = numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
            console.log(' total payable before 1/5/2023 ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        }
        else {
            total_payable = numChildrenPay * noSleepOverFeeChild + numAdult * noSleepOverFeeAdult
            console.log(' total payable before 1/5/2023 ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        }
        setTotalPayAble(total_payable)
        localStorage.setItem('totalPayAble', total_payable)
        // updateDependList(numDependents)
        //}
        /*
        else { //after 5/1/2023
            const domFeeAdult = 165.00
            const domFeeChild = 100.00
            const NoSleepOver = 30.00
            const motelFeeAdult = 200.00
            const motelFeeChild = 120.00
            const noSleepOverFeeChild = 30.00
            const noSleepOverFeeAdult = 40.00
            if (numChildren >= 4) {
                numChildrenPay = numChildren - 2

            }
            else {
                numChildrenPay = numChildren
            }
            if (feeOpt === 'Dorm') {
                 total_payable = numChildrenPay * domFeeChild + numAdult * domFeeAdult
                console.log(' total payable after 1/5/2023 ' + total_payable.toFixed(2))
                setTotalPayAble(total_payable)
            }
            
            else if (feeOpt === 'Motel') {
                const total_payable = numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
                console.log(' total payable before 1/5/2023 ' + total_payable.toFixed(2))
                setTotalPayAble(total_payable)
            } 
            else {
                 total_payable = numChildrenPay * noSleepOverFeeChild + numAdult * noSleepOverFeeAdult
                console.log(' total payable after 1/5/2023 ' + total_payable.toFixed(2))
                setTotalPayAble(total_payable)
            }
            setTotalPayAble(total_payable)
            localStorage.setItem('totalPayAble', total_payable)
            //setTotalPayAble(total_payable)

        }//end after 5/1/2023
        */
        /**
         *  Submit backend for further processing
        */
        /*
        console.log('im click to submit with some value bnew camper=' + cbNewCamper + ' &  bReturn= ' + cbReturnCamper + '\n full name =' + fullName +
            '\n age=' + age + ' Gender=' + gender + '\n address ' + address + ' city= ' + city + ' zipcode= ' + zipcode + '\n church attend=' + churchName + '\n pastor name ' + pastorName +
            '\n no. of dependent=' + numDependents + '\n' + JSON.stringify(dependentDataList) + '\n feeOpt ' + feeOpt + '\n total adult ' + numAdult + '\n num children ' + numChildren
        )//' + bNewCamper + ' bRetCamper:  ' + bRetCamper)
        */


        const submitData = {
            cbNewCamper: cbNewCamper,
            cbReturnCamper: cbReturnCamper,
            fullName: fullName,
            age: age,
            gender: gender,
            phone: phone,
            email: email,
            address: address,
            city: city,
            zipcode: zipcode,
            churchName: churchName,
            pastorName: pastorName,
            numDependents: numDependents,
            dependantDataList: dependentDataList,
            feeOpt: feeOpt,
            numAdult: numAdult,
            numChildren: numChildren,
            numSmall: numSmall,
            numMed: numMed,
            numLarge: numLarge,
            numXLarge: numXLarge,
            totalPayAble: total_payable //localStorage.getItem('totalPayAble'), //totalPayAble

        }
        axios.post(useCtx.sendCampData, submitData).then(
            response => {
                console.log(response.data.status, response.data.message)
                console.log(' i got ' + response.status)
                setDbResponse('')
                if (response.status === 200) {
                    if (!response.data.status) {
                        console.log(' error in data saving: ' + response.data.message)
                        setDbResponse(response.data.message)

                    }
                    else {
                        setDbResponse(response.data.message)


                    }
                }
            }
        ).catch(error => {
            console.log(' got error ' + error)
        })

    }
    return (
        <div className='container'>

            <NavBarSummerCamp />
             <MyModal />
            <div style={{ background: "linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(76,101,221,0.9290091036414566) 100%)" }}>

                  
                <div className="mb-3">
                    <Form onSubmit={getCamperData}>
                    <br/>
                        <h6 style={{ color: 'whitesmoke', fontWeight: "bold" }}> <InfoCircle/> 1. Family Information</h6>
                        {['checkbox'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                    inline

                                    name="cbNewCamper"
                                    onChange={newCamperStatusHandler}
                                    type={type}

                                /><span style={{ color: 'whitesmoke' }}>New Camper &nbsp;</span>
                                <Form.Check
                                    inline

                                    name="cbReturnCamper"
                                    onChange={returnCamperStatusHandler}
                                    type={type}

                                /><span style={{ color: 'whitesmoke' }}>Return Camper</span><br />

                                <Row>{/* Primary Camper */}
                                    <Col xs={5}>
                                        <Form.Group controlId="form.fullName">
                                            <Form.Control type="text" value={fullName} onChange={nameChangeHandler} placeholder="Enter Full Name" required />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="form.age">
                                            <Form.Control type="text" value={age} onChange={ageChangeHandler} placeholder="Age" pattern="[0-9]+" required />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="checkbox"
                                            id="autoSizingCheck2"
                                            label="Male"
                                            name='maleGender'
                                            onChange={getMaleGender}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="checkbox"
                                            id="autoSizingCheck2"
                                            label="Female"
                                            name='femaleGender'
                                            onChange={getFemaleGender}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={7}>
                                        <Form.Control as="select" className="rounded-0 shadow" onChange={shirtSizeHandler}>
                                            <option className="d-none" value="{shirtSize}">
                                                Select your T Shirt Size
																</option>
                                            {["S", "M", "L", "XL", "XXL"].map(option => (
                                                <option key={option}>{option}</option>
                                            ))}
                                        </Form.Control>

                                    </Col>
                                </Row>
                                <Row>&nbsp;</Row>
                                <Row>
                                    <Col>
                                        <Form.Control type='text' value={address} onChange={addressHandler} placeholder='Address' maxLength='256' />
                                        
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col>
                                        <Form.Control type='text' value={city} onChange={cityHandler} placeholder="City"  maxLength='50'/>
                                    </Col>
                                    <Col>
                                        <Form.Control type='text' value={state} onChange={stateHandler} placeholder="State"  maxLength='2'/>
                                    </Col>
                                    <Col>
                                        <Form.Control type='text' value={zipcode} onChange={zipcodeHandler} placeholder="Zip"  maxLength='5'/>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col xs={6}>
                                        <Form.Control type='text' value={phone} onChange={phoneHandler} placeholder='Your phone #, 10 digits only' pattern="[0-9]*" maxLength='10' />
                                    </Col>
                                    <Col>

                                        <Form.Control type='email' value={email} onChange={emailHandler} placeholder='i.e YourEmail@any.com ' maxLength='65' required/>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label><strong>Your Church:</strong></Form.Label>
                                        <Form.Control as="select" className="rounded-0 shadow" onChange={churchNameHandler}>
                                            <option className="d-none" value="{churchName}">
                                                Select Your Church From List
																</option>
                                            {["HT Loi Chua Houston-TX", "HT Austin-TX", "HT Kileen-TX", "HT Praire Dallas-TX", "HT Thi Thien Dallas-TX", "HT Cuu An Houston-TX", "HT Alief", "HT An Dien Houston-TX", "HT Amarillo TX","HT NewsOrlean-LA","HT Iberia-LA"  ,"Other"].map(option => (
                                                <option key={option}>{option}</option>
                                            ))}
                                        </Form.Control>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label><strong>Senior Pastor:</strong></Form.Label>
                                        <Form.Control as="select" className="rounded-0 shadow" onChange={pastorNameHandler}>
                                            <option className="d-none" value="{pastorName}">
                                                Select Pastor
																</option>
                                            {["MS Tran Ngoc Hoa", "MS Nguyen Le Huu Thuan", "MS Pham Van Huyen", "MS Phan Phung Hung", "MS Nguyen Thanh Bach", "MSNC Milton Muoi Le", "MSNC Le Hoang Tho", "MS Nguyen Phuc Tan","MS Huynh Linh Y" ,"MS Le Hong Lac" , "Other"].map(option => (
                                                <option key={option}>{option}</option>
                                            ))}
                                        </Form.Control>
                                        <br/>
                                    </Col>

                                </Row>
                                <Row><br /><h6 style={{ color: 'whitesmoke', fontWeight: "bold" }}><FileBarGraph/> 2. Family Members / Friends </h6></Row>


                                <Row>
                                    <Col>
                                        <Form.Label><strong>Fee Schedules: <span style={{color:'lightyellow'}}>(NO MORE MOTEL ROOMS AVAILALBLE)</span></strong></Form.Label>
                                        <Form.Control as="select" className="rounded-0 shadow" onChange={feeHandler}>
                                            <option className="d-none" value="{feeOpt}">
                                                Select Fee Schedule
																</option>
                                            {["Dorm","NoSleepOver"].map(option => (
                                                <option key={option}>{option}</option>
                                            ))}
                                        </Form.Control>
                                          <br/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Control type='text' value={numAdult} onChange={numAdultHandler} placeholder="Number of Adult >=11 years old" />

                                    </Col>
                                    <Col>
                                        <Form.Control type='text' value={numChildren} onChange={numChildrenHandler} placeholder="Number of Children <=10 years old" />
                                         <br/>
                                    </Col>

                                </Row>

                                <Row>
                                    <h6 style={{ color: 'whitesmoke', fontWeight: "bold" }}>3. T-Shirt (Match total number of your family)</h6>
                                    <Col xs={6}>
                                        <Form.Control type='text' value={numSmall} onChange={(e) => setNumSmall(e.target.value)} placeholder="number of S Shirt, i.e 1" />

                                    </Col>
                                    <Col>
                                        <Form.Control type='text' value={numMed} onChange={(e) => setNumMed(e.target.value)} placeholder="Medium Shirt" />


                                    </Col>
                                    <Col xs={6}>
                                        <Form.Control type='text' value={numLarge} onChange={(e) => setNumLarge(e.target.value)} placeholder="Large Shirt" />

                                    </Col>
                                    <Col>
                                        <Form.Control type='text' value={numXLarge} onChange={(e) => setNumXLarge(e.target.value)} placeholder="XLarge" /><br/>

                                    </Col>

                                </Row>

                                <Row>
                                    <h6 style={{ color: 'whitesmoke', fontWeight: "bold" }}><PersonStanding /><PersonStandingDress />  4. Your dependants (family members/ friends)</h6>

                                    <Col xs={7}>
                                        <Form.Control type='text' value={numDependents} onChange={numDependentHandler} placeholder="No. of dependents" pattern="[0-9]*" ref={numDepInput} maxLength='1' />


                                    </Col>
                                </Row>
                                <Row>
                                    {numDependents ? <PersonSubForm personTotal={numDependents} /> : ''}
                                </Row>
                                <br />

                            </div>
                        ))}
                        <br />
                        <Button variant="primary" type="submit" size='sm'>Submit</Button>
                        <Row>
                            <div style={{ color: 'red', paddingTop: '20px' }}>{dbResponse ? dbResponse : ''}</div>
                            <div style={{ color: 'white' }}>
                                <p>&nbsp;</p>
                                <p>Your option is {feeOpt ? feeOpt : 'N/A'} <br />
                                          dependantDataList: .............
                                <br />
                                with Adult={numAdult ? numAdult : 0}  and children={numChildren ? numChildren : 0} ,<br />
                                </p>
                                <div style={{ background: '#fff', color: '#487FDB' }}>
                                    <h6>Total Payable ${totalPayAble ? totalPayAble : 0}</h6>

                                    <p>Please make a Check / Money Order payable to <br /> "Austin Vietnamese Alliance Church" , memo "Camp 2024"</p>
                                    <p>
                                        Address: MS Tran Ngoc Hoa
                                    <br />12708 Serafy Ct.
                                    <br />Austin, TX 78753
                                 </p>
                                    <p>Or Zelle to: <strong>billing@tinlanhaustin.church</strong></p>
                                    <p>No Credit Card Accepted</p>
                                </div>

                            </div>

                        </Row>
                    </Form>

                    <p className='text-primary'><a href='/login' className='text-white'>Camp User Listing</a></p>
                </div>
            </div>
        </div >
    )
}
export default TraiHeRegoForm