import React, { useContext } from 'react'
//import FooterSimple from '../FooterSimple'
import Navbar from './navbar'
import BioCardTopPic from './biocardtopic'
//import SlideComp from './../slideshow/slidecomp'
//import SlideListImages from '../slides/SlideComp' 
import GetLoiSongHangNgay from '../GetLoiSongHangNgay'
import AuthContext from '../store/auth'

import './modal.css'

import Popup from "reactjs-popup";
//import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "reactjs-popup/dist/index.css";
import CommonFooter from './common_footer'
//import VideoCarousel from '../videos/CarouselVideos'
import CarouselVideoComp from '../videos/CarouselVideoComp'
import ShowBible from '../bible'


const MyModal = () => {

    return (
        <Popup trigger={<button className="button lead"><span style={{ color: 'darkblue', fontSize: '1.2rem', fontWeight: 'bold' }}> Giờ Nhóm Hằng Tuần </span></button>} modal closeOnDocumentClick >
            {close => (<>

                <div className='d-flex justify-content-end'><a className='close' onClick={close}>[&times;]</a></div>
                <div className="card">
                    <div className="card-header text-primary">
                        <h2>Chương Trình nhóm lại hằng tuần:</h2>
                    </div>
                    <div className="card-body">
                        <h2 className="card-title text-primary">&nbsp;</h2>
                        <div className="d-flex justify-content-center">
                            <ul className='list-group list-group-horizontal text-center'>
                                <li className='list-group-item'><a href='/indexVN'>Thờ phượng chung</a>: 10:30am-12:00pm</li>
                                <li className='list-group-item'><a href='/yem'>Thanh thiếu niên</a>: 10:45am-12:00pm</li>
                                <li className='list-group-item'><a href='/cm'>Nhi đồng</a>: 11:45am-12:45pm</li>
                            </ul>
                        </div>
                        <div className="d-flex justify-content-center">
                            <p>&nbsp;</p>
                            <address className='text-primary lead'>Địa chỉ nhà thờ:<br />
                            Hội Thánh Lời Chúa<br />
                            3134 Frick Rd<br />
                            Houston , TX 77038<br />
                            Tel: (713) 239-1466<br />
                            Quản nhiệm: MS Phạm Văn Huyên<br />
                            Email: quannhiem@loichua.church
                        </address>
                        </div>
                    </div>
                </div>
            </>
            )}

        </Popup>
    )
};




const ChristmasComp = (props) => {
    //define list of data images here as js object below to use SlideListImages component
    const authCtx = useContext(AuthContext);
    /*
     const dataImg = [
         {"imgUrl":"assets/images-cus/yem-front-of-retreat-home.JPG","headerImg":"YEM Retreat 2022 - Camping","descriptionImg":"All YEM attendees 2022"},
         {"imgUrl":"assets/images-cus/yem-beauty-2022.jpg","headerImg":"YEM Retreat 2022 - On the beach","descriptionImg":"Few of our YEM young ladies on the beach"},
         
         {"imgUrl":"assets/images-cus/Picnic-yem-loichua.jpeg","headerImg":"YEM Picnic","descriptionImg":"Outdoor fun for YEM"},
 
         {"imgUrl":"assets/images-cus/yem-worship-at-retreat-2022.JPG","headerImg":"YEM Retreat 2022","descriptionImg":"Worship in the morning at 2022 Retreat Camping"},
         {"imgUrl":"assets/images-cus/yem-aniver-2nd-year.JPG","headerImg":"YEM","descriptionImg":"Group discussion"},
         
     ]
     */
    const fontBold = {
        color: 'darkblue', fontSize: '1.2rem', fontWeight: 'bold'
    }
    const kdnVideoList = [
        { //keep adding in the format below 
            id: 1,
            title: "",//"Dem Thanh Bet Le Hem",
            src: "https://www.youtube.com/watch?v=vUkMeECsoPI",
            credit: ""
        },
        { //keep adding in the format below 
            id: 2,
            title: "", //"Bon Chan Chien",
            src: "https://www.youtube.com/watch?v=WE-5iZQ8t1k",
            credit: ""
        },
        { //keep adding in the format below 
            id: 3,
            title: "", //"Den Voi Vui Mung",
            src: "https://www.youtube.com/watch?v=ZpaYC7Tz3mg",
            credit: ""
        },
        //https://youtu.be/
    ]
    const yemVideoList = [
        { //keep adding in the format below 
            id: 1,
            title: "YEM Anniversary",
            src: "https://www.youtube.com/watch?v=OjQVqPA4kLs",
            credit: "Video by Social Media Team HTLC"
        },
        {
            id: 2,
            title: "",
            src: "https://www.youtube.com/watch?v=ihJvYOTCVZ0",
            credit: "Video by Social Media Team HTLC",
        },
    ]
    const sermonVideoList = [
        { //keep adding in the format below 
            id: 1,
            title: "Le Ta On cac HTTL vung Houston",
            src: "https://youtu.be/0fWc6hcV6G0",
            credit: "Video by Social Media Team HTLC"
        },
        { //keep adding in the format below 
            id: 2,
            title: "Chua Nhat Truyen Giao HTLC",
            src: "https://youtu.be/LIfhZz3Wqj4",
            credit: "Video by Social Media Team HTLC"
        },
        { //keep adding in the format below 
            id: 3,
            title: "Le Ta On 2022",
            src: "https://youtu.be/kOwl9QOIn48",
            credit: "Video by Social Media Team HTLC"
        },
    ]
    return (
        <>
            <header>
                <div className="overlay">
                    <div className='container-fluid'>
                        <Navbar />

                    </div>
                </div>

                <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop" width='1200' height='400'>
                    <source src="/assets/media/xmas-banner-2023.mp4" type="video/mp4" />
                </video>


            </header>
            <div className='container'>

                <div className='row'>
                    <div className='col'>
                        <br />
                        <img src='/assets/images-cus/htlc-gs-2023.png' className='img-fluid' alt='...' />
                        <br />
                        <br /><a href='https://youtu.be/PQ6wKnkm5vU?t=11' target='new'><img src='/assets/images-cus/lienkhuc-gs.png' className='img-fluid' alt='ht loi chua - ca khuc gs vui tuoi tin lanh' /></a>
                        <a href='https://thuvien.loichua.church/khi-cay-vi-cam-len-tieng/' target='new'><img src='/assets/images-cus/when-violin-speaks-up.png' className='img-fluid' alt='khi cay vi cam len tieng' /></a>
                        <br />
                        <img src='/assets/images-cus/daily-bread.jpg' className='img-fluid' alt='loi song hang ngay' />
                        <GetLoiSongHangNgay />
                        <br />
                        <a href='https://thuvien.loichua.church/niem-vui-giang-sinh/' target='new'><img src='/assets/images-cus/JoyMerryChristmas.png' className='img-fluid' alt='niem vui giang sinh - hoi thánh lời Chúa houston texas' /></a>
                        <br /><ShowBible />

                    </div>
                    <div className='col-7'>
                        <br />
                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'>
                                <span style={fontBold}>Thân mời quí tín hữu và thân hữu tham dự Lễ Giáng Sinh với chúng tôi</span>
                                <span style={fontBold}>Emmanuel - Đức Chúa Trời Ở Cùng Chúng Ta</span>
                                
                            </p>
                            <p className='left-align-text lead'>
                                <br />6:00pm tối Chúa nhật  24/12/2023
                                <br />
                                Địa điểm tại nhà thờ Tin lành

                                HTLC <br />3134 Frick Rd, Houston , TX 77038
                                <br />
                                <span style={{ color: 'darkblue', fontSize: '1.2rem', fontWeight: 'bold' }}>Mọi thông tin chi tiết, xin liên lạc:</span>
                                <br />
                                MS Phạm Văn Huyên  (713) 239-1466
                                <br />
                                Mong được đón tiếp quí vị.
                            </p>
                            <MyModal />

                        </div>
                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><span style={fontBold}>Giờ thờ phượng mỗi Chúa nhật:</span>
                                <br />
                                Trường Chúa Nhật: 9:30AM
                                <br />
                                Thờ phượng chung: 10:30AM
                                <br />
                                <a href='/yem' style={fontBold}>Thanh Thiếu Niên</a>: 10:30AM-12:00PM
                                <br />
                                <a href='/children-ministry' style={fontBold}>Nhi đồng</a>: 11:45AM-12:45PM
                                Mong được đón tiếp quí vị.
                            </p>
                        </div>
                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><a href='/DangHien' style={fontBold}>Dâng hiến</a></p>
                            <p className='left-align-text lead'>
                                Mùa Giáng Sinh lại đến, Hội thánh chúng ta có nhiều điều để tạ ơn trong năm vừa qua, trong tinh thần tạ ơn, kính xin quí con dân Chúa
                                sẻ hổ trợ giúp đỡ trong truyền giáo, các mục vụ và các dự án sửa chửa cần thiết trong cơ sở HT sắp đến.
                            </p>
                            <p className='left-align-text lead'>
                                Với tháng còn lại trong năm nay, chúng tôi kêu gọi sự dâng hiến nhiệt thành của con dân Chúa cho hội thánh và công tác truyền giáo
                                toàn cầu.
                                <br />
                                Mọi dâng hiến đều đươc khấu trừ thuế ( tax-deductible).  Xin cảm ơn tấm lòng rời rộng của quí vị góp phần tiếp tục xây dựng Vương Quốc của Đức Chúa Trời và cho vinh hiển Danh Ngài.
                            </p>
                            <p className='center-text lead'>
                                <a href='/DangHien'><img src='/assets/images-cus/giveback-donate.png' width='100' height='100' alt='' /> Dâng hôm nay</a>
                            </p>
                        </div>
                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><a href='/yem' style={fontBold}>Special Video Giáng Sinh Ca Đoàn HT Lời Chúa </a></p>
                            {/*
                                <VideoCarousel />
                                */}
                            <CarouselVideoComp videoArray={kdnVideoList} />

                            <br />

                        </div>
                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><a href='/yem' style={fontBold}>Youth English Ministry (YEM) Activities</a></p>
                            {/*
                            <SlideListImages data={dataImg}/>
                            */}
                            <CarouselVideoComp videoArray={yemVideoList} />
                            <br />

                        </div>

                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><a href='/' style={fontBold}>Ban Nam Giới & Phụ Nữ</a></p>

                            <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vSSkOXoCCc0Dug5IUKzaTpz5sYwQGemA9lvSZ4kYardaC0etpv17RfdjKwCV6LHYXpHaSRGGRECrAY9/embed?start=true&loop=true&delayms=2000" frameborder="0" width="480" height="299" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"
                                title='HT Loi Chua - sinh hoat nam gioi phu nu'></iframe>
                        </div>
                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><a href='/' style={fontBold}>Sứ Điệp </a></p>
                            <CarouselVideoComp videoArray={sermonVideoList} />

                        </div>
                    </div>

                </div>
                {/* google slides about service times so on...but dont want it for now
                <div className='row'>
                    <div className='col'>
                        <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTkUKGILzmOrkV_6Ni8c3jbs87E-qpWiUK5uU166aKs4OAFKhbWuvLZsSwLLh4-wWtCyTahYaUI0o_z/embed?start=true&loop=true&delayms=3000" frameborder="0" width="600" height="350" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"  title='Invitation'></iframe>
                        
                    </div>
                </div>
                */}
                <div className='row'>
                    <h2 className='text-primary'>Ban Linh vụ</h2>
                    <div className='col'><BioCardTopPic imgUrl='https://loichua.church/assets/images-cus/ms-huyen.jpeg' name='Pastor Phạm Văn Huyên, MDiv' position='Assistant Pastor' />
                    </div>
                    
                    <div className='col'><BioCardTopPic imgUrl='https://loichua.church/assets/images-cus/pastor-ngon-bui.jpg' name='Rev Bùi Trung Ngôn, BA' position='Assistant Pastor' />
                    </div>
                    <div className='col'><BioCardTopPic imgUrl='https://loichua.church/assets/images-cus/pastor-sang.jpg' name='Pastor Trần Sang' position='Youth Pastor' />
                    </div>
                    <div className='col'><BioCardTopPic imgUrl='https://loichua.church/assets/images-cus/ms-le-thuan-3.jpg' name='Rev Lê Phước Thuận, MDiv' position='Senior Pastor' />
                    </div>
                    
                </div>
                <div className='row'>
                    <div className='col'></div>
                </div>
            </div>
            <CommonFooter authConfig={authCtx} />

        </>
    )
}

export default ChristmasComp