import React from 'react';

const Welcome = (props) => {
    const welcomeStyle = {

        fontFamily: "Arial",
        fontSize: '12px',
        textAlign: 'left'
    };
    return (

        <React.Fragment>

            <section class="welcome welcome--2 welcome--about pad-t-sm-2 pad-b-sm-2 pad-t-lg-4 pad-b-lg-4">

                <div class="welcome__content">

                    <div class="container">

                        <div class="row align-items-center default-section-spacing pad-b-sm-0 mar-b-lg-4">

                            <div class="flex-lg-6">

                                <div class="section-heading">
                                    <span class="section-intro">Welcome</span>
                                    <h2>A warm welcome from us</h2>
                                </div>
                                <p style={welcomeStyle}>
                                    CHÀO MỪNG BẠN ĐẾN VỚI WEBSITE HỘI THÁNH LỜI CHÚA. NƠI ĐÂY BẠN SẼ TÌM THẤY NIỀM VUI TRONG CUỘC SỐNG  KHÓ KHĂN, BÌNH AN GIỮA CUỘC ĐỜI NHIỀU BẤT TRẮC, HY VỌNG TRONG NHỮNG HOÀN CẢNH BẾ TẮC, VÀ NHIỀU ĐIỀU BỔ ÍCH KHÁC.

                                     <a href='/doc-them' > MỜI BẠN XEM THEM...</a>
                                </p>
                                <h3><a href='/doc-them' >Read more...</a></h3>


                            </div>
                            <div class="flex-lg-6">

                                <div class="post-media">

                                    <div id="sermon-video" class="sermon-video">

                                        <video width="580" height="480" controls poster='assets/images-cus/welcome-to-htlc.png' disablePictureInPicture >
                                            <source src="assets/media/htloichua-promo.mp4" type="video/mp4" />


                                        </video>
                                    </div>

                                </div>

                                {/*
                                <div class="image-box mar-t-sm-8 mar-t-lg-0 mar-b-sm-8 mar-b-lg-0">

                                    <div class="box box--center">
                                        <img src="assets/images-cus/andrew-yem-standing.png" alt="" />
                                    </div>
                                    <div class="box box--top-right">
                                        <img src="assets/images/happy-kid.jpg" alt="" />
                                    </div>
                                    <div class="box box--bottom-right">
                                        <img src="assets/images/singing-girl.jpg" alt="" />
                                    </div>
                                    <div class="box box--top-left">
                                        <img src="assets/images/church-bldg-sm.jpg" alt="" />
                                    </div>
                                    <div class="box box--bottom-left">
                                        <img src="assets/images/bible-sm.jpg" alt="" />
                                    </div>
                                </div> 
                                */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment >
    )
}
export default Welcome;