import { useEffect, useState } from 'react';
    
export default function useFetch(url) {
    const [data, setData] = useState(null);
    useEffect(() => {
        async function loadData() {
            const response = await fetch(url);
            if(!response.ok) {
                // oups! something went wrong
                console.log('can not get response from thu vien...')
                return;
            }
    
            const posts = await response.json();
            console.log('here posts is set to state...from useFetch')
            setData(posts);
        }
    
        loadData();
    }, [url]);
    return data;
}