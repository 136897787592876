import React, { useContext } from 'react'
import AuthContext from '../store/auth'
import Navbar from '../christmas/navbar'
import CommonFooter from '../christmas/common_footer'
import CalendarComp from '../calendar'
import UserListing from '../traihe2024/UserList'
import NavBarSummerCamp from '../traihe2024/NavBarSummerCamp'
import TraiHeDataExport from '../traihe2024/TraiHeDataExport'
import TraiHeRoomList from '../traihe2024/TraiHeRoomList'

const ChurchDashboard = (props) => {
    const authCtx = useContext(AuthContext)
    /*const RoomListing = (props) => {
        if (props.bLogin) {
            document.location = 'http://loichua.church:3001/traihe/roomlisting/tomyyum'
        }
        else {
            return (
                <TraiHeRoomList bLogin={props.bLogin} />
            )

        }
    }
    */
    //let bLogin=localStorage.getItem('bLogin')
    //console.log('ChurchDashboard: found cookie login='+bLogin)
    console.log(' at  church dashboard found login state=' + props.bLogin + ' and cookie ' + localStorage.getItem('bLoginCookie') + typeof (localStorage.getItem('bLoginCookie')))
    function logout() {
        if (localStorage.getItem('bLoginCookie')) {
            localStorage.setItem('bLoginCookie', false)
            //console.log('im logout and now bLogin=' + localStorage.getItem('bLogin'))	
            document.location = authCtx.loginUrl //'http://loichua.church:3002/login'
        }


    }
    if (props.bLogin === true || localStorage.getItem('bLoginCookie') === 'true') {
        console.log(' here props.blogin==' + props.bLogin + ' and cookie login ' + localStorage.getItem('bLoginCookie'))
        return (

            <div className='container'>
                <NavBarSummerCamp />
                <br />
                <h6>You are in protected page &nbsp;|&nbsp; <span><button onClick={logout}>Logout</button></span></h6>
                <div className='row'>
                    {/**
                     *  <CalendarComp showNavBar={false} />
 
                     */}

                    <UserListing />
                    <TraiHeDataExport />
                    <TraiHeRoomList />

                </div>
                {/**
                 *  <CommonFooter authConfig={authCtx} />
                
                 */}

            </div>

        )
    }
    else {
        console.log(' Try into ChurchDashboard : i got bLogin ' + props.bLogin + ' and cookie=' + localStorage.getItem('bLoginCookie'))
        return <p className='lead'>You are not authorized! Please <a href='/login'>login</a></p>
    }

}
export default ChurchDashboard
