import React from 'react'

const NavBarHoiDongRego = ()=>{

	return(
		<>
			<nav className="navbar navbar-light bg-light">
                <div className="container-fluid justify-content-center">
                    <a className="navbar-brand" href="#">
                        {/*<img src="../../assets/images-cus/CMA-Conference.jpeg" alt="" height='170' className="align-text-top" />*/}
                        <img src="../../assets/images-cus/hdgh_2024_logo.png"  height='500' alt="hoi dong giao hat vietnam hoa ky lan thu 49"  className="align-text-top" />
                    </a>
                      <h3 className='align-text-center'></h3>
                      
                    <div className='row'>
                          <p className='align-text-center'>Venue: Sheraton Fairplex Hotel & Conference Center
						 601W. McKinley Avenue, Pomona, CA 91768, USA</p>  
                    </div>

                </div>
            </nav>

		</>
	)
}
export default NavBarHoiDongRego