import React from 'react';
import CountDownTimer from './EventCountdown';

const VideoBackground = (props) => {

    return (
        <>

            <section class="hero hero--video-bg full-width display-flex justify-align-center">

                <div id="video-bg" class="hero__video--bg overlay">
                    <video autoPlay muted loop playsinline>
                        <source src="assets/media/the-real-deal.mp4" type="video/mp4"></source>

                    </video>
                </div>
                <div class="hero__content full-width text-center-sm">

                    <div class="container">

                        <div class="heading mar-b-sm-2">
                            <span class="section-intro mar-b-sm-2">{props.intro}</span>
                            <h1 class="color-off-white" id='greetings'>{props.greeting}</h1>
                        </div>

                        <div class="hero__cta"><a href="about" class="button button--primary button--fill">{props.learnmore}</a></div>
                        <div className='section-heading'><CountDownTimer date="12/24/2022"/></div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default VideoBackground;