import React from 'react';
//import FooterSimple from './FooterSimple';
import SiteHeader from './SiteHeader';
import ScrollBack from './ScrollBack';
import BannerEventSingle from './BannerEventSingle';

import SinglePageEventMain from './SinglePageEventMain';
import SmallTeamFooter from './SmallTeamFooter';
const EventSingle = () => {
    return (
        <>
            <SiteHeader />
            <main>
                <BannerEventSingle />
                <SinglePageEventMain />
            </main>
            <ScrollBack />
            <SmallTeamFooter />
        </>

    )
}
export default EventSingle;