import React, { useEffect, useState, useContext } from 'react';
import AuthContext from './store/auth';
import axios from 'axios';
//import ReactHowler from 'react-howler';


const PhatThanhTinLanh = (props) => {

    const [mp3Url, setMp3Url] = useState([]);
    const useCtx = useContext(AuthContext);
    async function fetchData(){
        try{
             axios.post("https://api.loichua.church:5001/main/phat-thanh-tin-lanh-houston" ,{'urlPTTL': useCtx.urlPTTL }).then(res=>{
                console.log(' get resp data ' + res.data.message)
               console.log('got resp data ' + res.data.mp3Url); //give videos id here as list 
               setMp3Url(res.data.mp3Url);
             }).catch(error=>{
                 console.log(error)
             })
             

        }
        catch(error){
            console.log(error)
        }
    }
    useEffect(()=>{
        fetchData()
    },[])
   
    //must use iframe to get around with blocking cors problem https://developer.chrome.com/blog/autoplay/#webaudio
    // worked on safari , chrome n firefox ok
    // use html5 audio tag
    return (
        <>
            {mp3Url && mp3Url.map((m) => {
                return (
                    <div key={m.id}>
                        {/*
                            <ReactHowler src={m} playing={true}  html5={true} preload={true} />
                           */}
                        <audio controls>
                            <source src={m} type='audio/mp3' />
                        </audio>
                    </div>

                )
            }
            )
            }
        </>
    )
}



export default PhatThanhTinLanh;