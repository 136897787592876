//import logo from './loichua-brand-bird.png';
import React from 'react'
import {
    // BrowserRouter as Router,
    Switch,
    Route,
    //Link, withRouter
} from 'react-router-dom';
import { Helmet } from "react-helmet";
import './App.css';
//import dotenv from 'dotenv';
//dotenv.config()
import IndexOne from './components/IndexOne';
import AboutUs from './components/AboutUs';
import AboutUsVN from './components/AboutUsVN';
import Contact from './components/Contact';
//import HeaderNavigator from './components/HeaderNavigator';
import YemMinistry from './components/YemMinistry';
//import Example from './components/Example';
import ComingSoon from './components/ComingSoon';
import ChildrenMinistry from './components/ChildrenMinistry';
import ManMinistry from './components/ManMinistry';
import EventSingle from './components/EventSingle';
import EmailSuccess from './components/EmailSuccess';
import Donations from './components/Donations';
//import VideoList from './components/VideoList';
import VideoContainer from './components/VideoContainer';
import Testimonies from './components/Testimonies';
import EvangelicalExpansion from './components/EvangelicalExpansion';
import DevotionalListContainer from './components/DevotionalListContainer';
//import Magazine from './components/Magazine';
import MagazineContainer from './components/MagazineContainer';
import DocThem from './components/DocThem';
import PhatThanhTinLanhContainer from './components/PhatThanhTinLanhContainer';
//import TemplateFile from './components/TemplateFile';
//import TaiLieuSuuTam from './components/TaiLieuSuuTam';
import TaiLieuSuuTamContainer from './components/TaiLieuSuuTamContainer';
import ShortStoriesContainer from './components/ShortStoriesContainer'
import IndexOneVN from './components/IndexOneVN';
import Facebook from './components/Facebook';
import Privacy from './components/Privacy';
import Admin from './components/admin/Admin';
import SiteMap from './components/sitemap/SiteMap';
import AudioDevoRWContainer from './components/AudioDevoRWContainer';
import GeneralContainer from './components/GeneralContainer';
import ChurchHistory from './components/ChurchHistory';
//import './components/home/Home.css';
//import Home from './components/home/Home';
//import Christmas from './components/events/Christmas';
//import ShortStoriesContainerNew from './components/shortstories/ShortStoriesContainerNew';
import ShortStoriesListing from './components/shortstories/ShortStoriesListing';
import SelectedArticleListing from './components/selectedarticles/SelectedArticleListing';
import SpecialAudioPlay from './components/events/SpecialAudioPlay';
import GetLoiSongHangNgay from './components/GetLoiSongHangNgay';
import ThoiBieuSinhHoat from './components/ThoiBieuSinhHoat';
//import Rental from './components/Rental';
import Rental2 from './components/Rental2';
import JobSubmission from './components/JobSubmission';
///import TraiHe2022 from './components/TraiHe2022';
import EventRegistrationForm from './components/EventRegistrationForm';
//import TraiHeGhiDanh from './components/TraiHeGhiDanh';
import Reimbursement from './components/Reimbursement';
//import GivingForm from './components/Giving'
import MissionaryPage from './components/MissionaryPage';
import ChristmasComp from './components/christmas/Christmas';
import DangHien from './components/DangHien'

//import CardForAudio from './components/CardForAudio';
//import ChristmasSpecialRun2 from './components/events/ChristmasSpecialRun2';
//import ChristmasSpecialRun from './components/events/ChristmasSpecialRun';
import GrabPosts from './components/thuvien/index';
import LoginForm from './components/login/index'
import ChurchDashboard from './components/protected/ChurchDashBoard';
import FollowMeBlock from './components/followme';
import VideoCarousel from './components/videos/CarouselVideos';
import ShowBible from './components/bible'
import CalendarComp from './components/calendar/index'
import PDFViewerComp from './components/thuvien/ViewBooks';
//import TestComp from './components/testcomp/testcomp';
//import XuanComp from './components/xuan2023/Xuan';
import RandomVerse from './components/xuan2023/RandomVerse';
import MoviePlayer from './components/movies/Movie'
import TraiHeRegoForm from './components/traihe2024/TraiHeRegistration'

//import BudgetChart from './components/budget/BudgetChart';
import UserListing from './components/traihe2024/UserList'
import UserConferenceListing from './components/hoidong2024/UserList'
import UserListWithLogin from './components/hoidong2024/UserListWithLogin'

import TraiHeDataExport from './components/traihe2024/TraiHeDataExport' 
import HoiDongDataExport from './components/hoidong2024/HoiDongDataExport'
import EasterComp from './components/easter2023/Easter2024' 
import MotherDay from './components/motherday2024/MotherDay'
import TraiHeRoomList from './components/traihe2024/TraiHeRoomList'
import DefaultHomeComp from './components/defaulthome/DefaultHome'
import Thanksgiving from './components/thanksgiving2023/Thanksgiving'
import HoiDongRegoForm from './components/hoidong2024/HoiDongRegistration'
import RoomStat from './components/hoidong2024/RoomStat'
//import PaymentForm from './components/payment/PaymentForm'
//import PackagePrice from './components/hoidong2024/PackagePrice'
import HoiDongRoomList from './components/hoidong2024/HoiDongRoomList'
import EmailBot from './components/hoidong2024/EmailBot'
//import TestComp from './components/testcomp/testcomp';
import JotFormTest from './components/testcomp/JotFormTest';
import ClosedRego from './components/hoidong2024/ClosedRego';
function App() {
    return (

        <div className="App">

            <Helmet>
                <title>Hội Thánh Tin Lành Lời Chúa, Houston Texas</title>
                <meta name='description' content='Tin lành cho người Việt Houston, Texas' />
                <meta name='keywords' content='tin lanh, nha tho, nguoi viet, co doc nhan, hoi thanh, song voi loi chua' />
            </Helmet>

            <Switch>

                <Route path='/contact' exact ><Contact /></Route>
                <Route path='/about' exact ><AboutUs /></Route>
                <Route path='/about-httl-loichua' exact ><AboutUsVN /></Route>
                {/*<Route path='/' exact><ChristmasComp /></Route>*/}
                {/** running special so changed default landing to giang-sinh-2021-run */}
                <Route path='/indexvn' exact><IndexOneVN /></Route>

                <Route path='/yem' exact><YemMinistry /></Route>
                <Route path='/children-ministry' exact><ChildrenMinistry /></Route>
                <Route path='/man-ministry' exact><ManMinistry /></Route>

                <Route path='/event' exact><EventSingle /></Route>

                {/*<Route path='/photo-cua-ban-tn' exact> <Example /></Route> */}
                <Route path='/coming-soon' exact component={ComingSoon} />
                <Route path='/success' exact component={EmailSuccess} />
                <Route path='/donations' exact component={Donations} />
                <Route path='/video-list' exact component={VideoContainer} />
                <Route path='/testimonies' exact component={Testimonies} />
                <Route path='/lamchung' exact component={EvangelicalExpansion} />
                <Route path='/devotions' exact component={DevotionalListContainer} />
                <Route path='/read-dailyhope' exact component={MagazineContainer} />
                <Route path='/doc-them' exact component={DocThem} />
                <Route path='/phat-thanh-tin-lanh-houston-texas' exact component={PhatThanhTinLanhContainer} />
                {/*  <Route path='/template' exact component={TemplateFile} /> */}
                <Route path='/tai-lieu-suu-tam' exact component={TaiLieuSuuTamContainer} />
                <Route path='/chuyen-ngan' exact component={ShortStoriesContainer} />

                <Route path='/en' exact component={IndexOne} />
                <Route path='/privacy' exact component={Privacy} />

                <Route path='/facebook-page' exact component={Facebook} />

                <Route path='/admin-htlc/' exact component={Admin} />
                <Route path='/song-dung-muc-dich' exact component={AudioDevoRWContainer} />
                <Route path='/sitemap' exact component={SiteMap} />

                <Route path='/phat-thanh-lien-huu-tin-lanh' exact component={GeneralContainer} />
                <Route path='/church-history' exact component={ChurchHistory} />
                {/*
                <Route path='/giang-sinh-2021' exact component={Christmas} />
                <Route path='/giang-sinh-2021-run' exact component={ChristmasSpecialRun} />

                
                <Route path='/truyen-ngan' exact component={ShortStoriesContainerNew} />
                 */}
                <Route path='/truyen-ngan' exact component={ShortStoriesListing} />
                <Route path='/bai-chon-loc' exact component={SelectedArticleListing} />
                <Route path='/nhac-tuyen-chon' exact component={SpecialAudioPlay} />
                <Route path='/get-loi-song-hang-ngay' exact component={GetLoiSongHangNgay} />
                <Route path='/thoi-bieu-sinh-hoat' exact component={ThoiBieuSinhHoat} />
                <Route path='/worksheet' exact component={JobSubmission} />
                <Route path='/rental' exact component={Rental2} />
                <Route path='/event-register' exact component={EventRegistrationForm} />
                {/*
                    <Route path='/trai-he-2022' exact component={TraiHe2022} />
                    <Route path='/trai-he-2022-p2' exact component={TraiHeGhiDanh} />
                */}

                <Route path='/reimbursement' exact component={Reimbursement} />
                {/*
                    <Route path='/giving' exact component={GivingForm} />
                */}
                <Route path='/thanksgiving' exact component={Thanksgiving} />
                <Route path='/laos-mission' exact component={MissionaryPage} />
                <Route path='/Christmas2022' exact component={ChristmasComp} />
                <Route path='/DangHien' exact component={DangHien} />
                <Route path='/thuvien' exact component={GrabPosts} />
                <Route path='/login' exact component={LoginForm} />
                <Route path='/dashboard' exact component={ChurchDashboard} />
                <Route path='/followme' exact component={FollowMeBlock} />
                <Route path='/videos' exact component={VideoCarousel} />
                <Route path='/bible' exact component={ShowBible} />
                <Route path='/lich-sinh-hoat-hoi-thanh' component={CalendarComp} />
                <Route path='/pdfview' component={PDFViewerComp} />
                <Route path='/movies' component={MoviePlayer} />
                <Route path='/caugoc' exact component={RandomVerse} />
                <Route path='/traihe2024/register' component={TraiHeRegoForm} />
                {/** the listing now go into protected pages... i should not let them know 2/21/2023*/}
                 <Route path='/traihe2024/listing/tomiyum' exact component={UserListing} />
                 <Route path='/traihe2024/export' exact component={TraiHeDataExport} />
                 <Route path='/traihe2024/roomlisting/tomiyum' exact component={TraiHeRoomList} />
                 <Route path='/hoidong2024/register' exact component={ClosedRego} />
                 <Route path='/hoidong2024/register-view-again' exact component={HoiDongRegoForm} />
                 
                 <Route path='/hoidong2024/listing/t0mig0ing0n2024' exact component={UserConferenceListing} />
                 
                 <Route path='/hoidong2024/listing/login' exact component={UserListWithLogin} />
                 
                 <Route path='/hoidong2024/roomstat' exact component={RoomStat}/>
                 <Route path='/hoidong2024/export' exact component={HoiDongDataExport} />
                 <Route path='/hoidong2024/roomlist' exact component={HoiDongRoomList} />
                 {/* Jotform test works in dev env with intergrated authorize.NET form */}
                 <Route path='/testing/jotform' exact component={JotFormTest} />
                 
                
                 <Route path='/hoidong2024/emailbot' exact component={EmailBot} />
                   
                {/**
                  * <Route index component={XuanComp} />
                */}
                
                <Route index component={DefaultHomeComp} />
                <Route path='/motherday2024' component={MotherDay} />
                {/*
                <Route index component={EasterComp} />
                    */}
                {/*
                 <Route index component={ChristmasComp} />
                */}


            </Switch>



        </div>

    );
}

export default App;
