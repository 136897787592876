import React from 'react';
import './BannerDevoListContainer.css'

const BannerDevoListContainer = (props) => {

    return (
        <React.Fragment>
            <section class="banner single-banner-event full-width">

                <div class="container">

                    <div class="banner__content">

                        <div class="section-heading">
                            <span class="section-intro">Tĩnh Nguyện Hằng Ngày</span>
                            <h1>Dr.Rick Warren</h1>
                        </div>
                        <div class="breadcrumb">
                            <div class="breadcrumb__home--link"><a href="/">Home</a></div>
                            <span>/</span>
                            <div class="breadcrumb__home--link"><a href="/">Media</a></div>
                            <span>/</span>
                            <div class="breadcrumb__current--page-link">Devotions</div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default BannerDevoListContainer;