import React from 'react';
import { Helmet } from 'react-helmet';
const VideoYEMBackground = () => {

    return (
        <>
            <Helmet>
                <title>Hội Thánh Tin Lành Lời Chúa - YEM Youth Ministry </title>
                <meta name='description' content='Mời bạn cùng tham gia sinh hoạt ban thanh thiếu niên Hội Thánh Tin Lành Lời Chúa' />
                <meta name='keywords' content='tin lanh, young christian, Jesus, Lord, CMA , Houston, loi chua, nguoi viet' />


            </Helmet>
            <section class="hero hero--video-bg full-width display-flex justify-align-center">

                <div id="video-bg" class="hero__video--bg">
                    <video autoPlay muted loop>
                        <source src="assets/media/yem-music-team.mp4" type="video/mp4"></source>

                    </video>
                </div>
                <div class="hero__content full-width text-center-sm">

                    <div class="container">

                        <div class="heading mar-b-sm-2">
                            <span class="section-intro mar-b-sm-2">Jesus is Lord</span>
                            <h1 class="color-off-white">Come and join us on our YEM's Service on Sunday<br/></h1>
                        </div>

                        <div class="hero__cta"><a href="https://www.facebook.com/HTLoiChua/posts/pfbid0ZXj754j22e4EYhqDbuHr3wFKD335hWAaVJqegwvgyMBE3dA8VtjvMXwrWoqs6JjKl" class="button button--primary button--fill">Learn more</a></div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default VideoYEMBackground;