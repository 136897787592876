import React ,{} from 'react'
import useFetch from'./useFetch'
//import Navbar from '../christmas/navbar'
//import AuthContext from '../store/auth'
//import CommonFooter from '../christmas/common_footer'
const PostHolderComp =(props)=>{
    console.log(' @beginning of post holder got props = ' + JSON.stringify(props.data)) 
    return(
        
       <div className="clearfix">
       {/*
       <img src={props.data.yoast_head_json.og_image[0].url} className="col-md-6 float-md-end mb-3 ms-md-3 img-fluid" alt={props.data.yoast_head_json.title} />
       */}
       <h3><a href={props.data.link} target='new'>{props.data.title.rendered}</a></h3>
       <div className='lead text-right' dangerouslySetInnerHTML={{__html: props.data.content.rendered}}></div>
       <p><a href={props.data.link} target='new' className='lead'><b>...đọc thêm</b></a></p>

      </div>
    
   
    )

}

const GrabPostsComp=(props)=>{
   //const authConfig = useContext(AuthContext); 
    const posts = useFetch(props.url)
    
    console.log(' *** got posts from grabpost comp ' + JSON.stringify(posts))
    //dangerouslySetInnerHTML={{__html: this.props.post.title.rendered}}
    //const posts= JSON.parse(JSON.stringify(data))
    //console.log(' here i got after json parse ' + posts)
   return(
       <>
      
      
       <div className='container'>
        { posts && posts.map((post,index)=>(
             <div key={index}>
                 <PostHolderComp data={post} />
             </div>
             

         ))}
   	  
        </div>
       
        </>
   	)
}

export default GrabPostsComp
