/**
 * Page as container for all latest devotions list from blogspot of htloichua
 */

import React from 'react';
import SiteHeader from './SiteHeader';
import ScrollBack from './ScrollBack';
import SmallTeamFooter from './SmallTeamFooter';
import BannerDevoListContainer from './BannerDevoListContainer';
import DevotionalLists from './DevotionalLists';
//import LikeComponent from './fb/LikeComponent';

const DevotionalListContainer = (props) => {

    return (
        <div>
            <SiteHeader />
            <main>
                <BannerDevoListContainer />
                <DevotionalLists />
            </main>

            <ScrollBack />
            <SmallTeamFooter />
        </div>
    )

}

export default DevotionalListContainer;