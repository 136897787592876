import React, { useEffect, useState, useContext } from 'react';
import AuthContext from './store/auth';
//import axios from 'axios';

const TaiLieuSuuTam = (props) => {
    const useCtx = useContext(AuthContext);
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        callback2();
    })

    const callback2 = () => {
        const fetchUrl = useCtx.apiBaseURL + '/main/get-articles';
        fetch(fetchUrl).then(res => res.json()).then(data => {
            console.log(data.data)
            setArticles(data.data);

        }).catch(error => {
            console.log(error)
        })
    }
    return (
        <>

            {
                articles && articles.map((art) => {
                    return (

                        <iframe key={art.id} src={art} height='600' width='800' title='Tài Liệu Sưu Tầm' />
                    )

                })

            }







        </>
    )
}
export default TaiLieuSuuTam;