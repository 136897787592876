import React from 'react';

import VerseOfDay from './VerseOfDay';
//import AuthContext from './store/auth';
import HeaderNavigatorVN from './HeaderNavigatorVN';
//import AboutUs from './AboutUs';

const SiteHeaderVN = (props) => {
    //const useCtx = useContext(AuthContext);
    // const [quote, setQuote] = useState('Be very careful, then, how you live—not as unwise but as wise, making the most of every opportunity, because the days are evil. Ephesians 5:15-16');
    //const [loading, setLoading] = useState(true);
    //const [author,setAuthor]= useState('');
    //const [imgSrc,setImgSrc]= useState('');
    /*
     useEffect(() => {
         //getQuote(); //turn off so we can see the padlock on loichua.org for now
         const intervalID = setInterval(() => {
             getQuote()
         }, 24 * 60 * 60 * 1000);
         return () => {
             clearInterval(intervalID);
         }
     }, []);
 
     function getQuote() {
         fetch(useCtx.quoteURL)
             .then(res => res.json())
             .then(data => {
                 console.log(data);
                 setQuote(data.verse);
                 //setAuthor(data.contents.quotes[0].author);
                 //setImgSrc(data.contents.quotes[0].background);
             }).catch(error => {
                 //Here is still promise
                 console.log(error);
                 //error.json().then((body) => {
                 //Here is already the payload from API
                 //console.log(body);
             })
     }
    */
    return (

        <React.Fragment>

            <header class="header light-text" data-onscroll-classes="fixed-header animate-header">

                <div class="header__top-nav background-off-white">

                    <div class="container">

                        <div class="verse-of-the-day hide-on-md">

                            <button class="button button--secondary button--fill button-block-sm no-shadow modal-button"
                                data-modal-target="verse-of-the-day">Click to see the verse of the day</button>

                        </div>


                        <VerseOfDay verse='Hãy xem xét cẩn thận về cách sống của anh em, đừng sống như người dại nhưng như người khôn ngoan.  Hãy tận dụng thì giờ, vì những ngày là xấu. Vì vậy, đừng trở nên như người dại nhưng phải hiểu rõ thế nào là ý muốn của Chúa (Thư Ê-phê-sô 5:15-17).' frombib={''} language='English version' />
                    </div>
                </div>

                <HeaderNavigatorVN />



            </header>


        </React.Fragment>
    )


}

export default SiteHeaderVN;
