import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './DevotionalLists.css'
import LikeComponent from './fb/LikeComponent';

const DevotionalLists = (props) => {
    const [devotionalLinkItems, setDevotionalLinkItems] = useState([]);

    useEffect(() => {
        extractHandler();
    }, [])

    const extractHandler = () => {

        axios.post('https://api.loichua.church:5001/main/get-devotional-list', {})
            .then(
                response => {
                    console.log(response.data)
                    setDevotionalLinkItems(response.data.data)
                }
            ).catch(error => {
                console.log('got error ' + error)
            })
    }

    const renderURL = () => {

        return (
            <div class="descript-font">{
                devotionalLinkItems && devotionalLinkItems.map((d) => {

                    if (d.level === 'h')
                        return (<h2 key={d.id}><p>{d.title}</p></h2>)

                    if (d.level === 'div')
                        return (<span key={d.id}></span>)

                    if (d.level === 'a')
                        return (<p key={d.id} ><a href={d.url} style={{ color: 'black' }}>{d.title}</a></p>)
                })
            }</div>
        )
    }

    return (
        <div class="row default-section-spacing">
            <div class="flex-md-1"></div>
            <div class="flex-md-10">
                <div class="card sermon">
                    <LikeComponent appId='287434946725013' urllike='https://loichua.church/devotions' />

                    {renderURL()}
                </div>
            </div>
            <div class="flex-md-1"></div>
        </div>
    )
}

export default DevotionalLists;