import React, { useState, useEffect ,useRef, useContext, useMemo } from 'react'
//import Form from 'react-bootstrap/Form'
import { Form, Button, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import AuthContext from '../store/auth'
//for payment schedule prices
import PaymentContext from '../paymentsched/paymentshed'
import NavBarHoiDongRego from './NavBarHoiDongRego'

import MyPopUp from '../commoncomps/MyPopUp'

//import dotenv from 'dotenv'
//dotenv.config()

import Popup from "reactjs-popup";
import {InfoCircle, PencilSquare, CreditCard, EnvelopeAt, TruckFront, FileBarGraph, PersonStanding, PersonStandingDress} from 'react-bootstrap-icons'
import RoomStat from './RoomStat'
//import {HostedForm} from 'react-acceptjs'
//import RoomStat from './RoomStat'


//Buid an app for HDGH 2024 Registration 3/18/2024 starting project
//Return pay now button form with prepriced
const PayPackageComp = ({feeID, bMealDiscount, numAdult, numChildren, totalPayAble})=>{ //must pass in an obj {}
	//const payCtx = useContext(PaymentContext)
	if (!bMealDiscount){
		
		console.log(' i got call paypack comp with fee id='+ feeID + ' bMealDiscount='+bMealDiscount)
		if(feeID==='KingSuite_1A'){
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="618c8230-42c9-4516-af0b-362105304557" /> <input type = "submit" value = "Pay Now!" /> </form>
		}
		else if(feeID==='KingSuite_2A') {
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="63089892-d955-4621-beb9-917afd6af882" /> <input type = "submit" value = "Pay Now!" /> </form> 
			
		}
		else if(feeID==='KingSuite_3A') {
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="b501d236-e00d-4e8b-bbf2-65d6d89c320a" /> <input type = "submit" value = "Pay Now!" /> </form>
			
		}
		else if(feeID==='KingSuite_4A') {
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="8f986676-8d79-497f-b9cd-e9abbb1e7873" /> <input type = "submit" value = "Pay Now!" /> </form>
		}
		else if(feeID==='KingSuite_2A2K') {
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="a439a415-5771-43a3-b725-d12a94a2f521" /> <input type = "submit" value = "Pay Now!" /> </form>
		}
		else if(feeID==='DoubleSuite_2A') {
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="67244eb3-05e9-4dee-8a8f-ee746242abe9" /> <input type = "submit" value = "Pay Now!" /> </form> 
		}
		else if(feeID==='DoubleSuite_2A1K') {
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="38ab78a1-73cf-436f-b840-c2199b200aa6" /> <input type = "submit" value = "Pay Now!" /> </form>
		}
		else if(feeID==='DoubleSuite_4A') {
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="9f24aec8-0796-4f59-a2cc-5dd9387fc4c3" /> <input type = "submit" value = "Pay Now!" /> </form>
		}
		else if(feeID==='DoubleSuite_2A2K') {
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="80e4db68-a225-4cd8-a2e4-59e555bd601b" /> <input type = "submit" value = "Pay Now!" /> </form>
		}
		else if(feeID==='DoubleSuite_4A2K') {
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="c2fc2547-0202-4852-93e1-c13f70949f95" /> <input type = "submit" value = "Pay Now!" /> </form>
		}
		else if(feeID==='DoubleSuite_2A4K') {
		   return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="2a4cdead-43a8-4109-b8d0-d87cea0a8e8f" /> <input type = "submit" value = "Pay Now!" /> </form> 
		}
		/*
		else if (feeID==='MealsOnly' && numAdult===1 && numChildren==='0'){
			   
				    console.log('i got numAdult=' +numAdult + 'pointing to cc pay for 1 adult meal')
				    
				    return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="b8057e8d-1303-4b00-958a-ae08c6fbc7fa" /> <input type = "submit" value = "Pay Now!" /> </form>
				    
				    
			  
		}
		else if (feeID==='MealsOnly' && numAdult==2 && numChildren===0){
			   
				    console.log('i got numAdult=' +numAdult + 'pointing to cc pay for 2 adult meal')
				    return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="b94997d3-dc7c-4d83-bd67-39ced13603d7" /> <input type = "submit" value = "Pay Now!" /> </form>
			       
			  
		}
		else if (feeID==='MealsOnly' && numAdult==3 && numChildren==0){
			   
				    console.log('i got numAdult=' +numAdult + 'pointing to cc pay for 3 adult meal')
			       return <p style={{color:'red' , fontSize:'1.1rem', backgroundColor:'navajowhite'}}>Thank you for your completed registration.  
			                             We will send you invoice shortly for {numAdult} {numAdult?'Adult':''}, {numChildren} kid(s)   meals @${totalPayAble} </p>
			   
			  
		}
		else if (feeID==='MealsOnly' && numAdult==1 && numChildren===1){
			   
				    console.log('i got numAdult=' +numAdult + ' and num children '+ numChildren)
				    return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="19221bbe-b92f-4683-8cac-d0aaa37ef17e" /> <input type = "submit" value = "Pay Now!" /> </form>
			        
			     
			  
		}
		else if (feeID==='MealsOnly' && numAdult==1 && numChildren===2){
			   
				    
			        return <p style={{color:'red' , fontSize:'1.1rem', backgroundColor:'navajowhite'}}>Thank you for your completed registration.  We will send you invoice shortly for 'Meals Only'
			                                 {numAdult} {numAdult?'Adult(s)':''},  {numChildren} kid(s)  meals @${totalPayAble} </p>
			   
			  
		}
		else if (feeID==='MealsOnly' && numAdult==2 && numChildren===1){
			   
				    console.log('i got numAdult=' +numAdult + ' and num children '+ numChildren)
			     return <p style={{color:'red' , fontSize:'1.1rem', backgroundColor:'navajowhite'}}>Thank you for your completed registration.  We will send you invoice shortly for 'Meals Only'
			                                 {numAdult} {numAdult?'Adult':''},  {numChildren} kid(s)  meals @${totalPayAble} </p>
			   
			  
		}
		else if (feeID==='MealsOnly' && numAdult==2 && numChildren===2){
			   
				    console.log('i got numAdult=' +numAdult + ' and num children '+ numChildren)
			     return <p style={{color:'red' , fontSize:'1.1rem', backgroundColor:'navajowhite'}}>Thank you for your completed registration.  We will send you invoice shortly for 'Meals Only'
			                                 {numAdult} {numAdult?'Adult':''},  {numChildren} kid(s)  meals @${totalPayAble} </p>
			   
			  
		}
		else if (feeID==='MealsOnly' && numAdult==0 && numChildren==1){
			   
				    console.log('i got numAdult=' +numAdult + ' and num children '+ numChildren)
				    
				    return  <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="b1d07462-00bc-43a4-a434-6d9fb768cecf" /> <input type = "submit" value = "Pay Now!" /> </form>
				    
				   
						  
		}
		else if (feeID==='MealsOnly' && numAdult==0 && numChildren===2){
			   
				    console.log('i got numAdult=' +numAdult + ' and num children '+ numChildren)
			     return <p style={{color:'red' , fontSize:'1.1rem', backgroundColor:'navajowhite'}}>Thank you for your completed registration.  We will send you invoice shortly for 'Meals Only'
			                                 {numAdult} {numAdult?'Adult':''},  {numChildren} kid(s)  meals @${totalPayAble} </p>
			   
			  
		} 
		*/
		else if(feeID==='MealsOnly'){
			 return <p style={{color:'red' , fontSize:'1.1rem', backgroundColor:'navajowhite'}}>Thank you for your completed registration...  
			                             We will send you invoice shortly for {numAdult} {numAdult?'Adult':''},  {numChildren} kid(s)  meals @${totalPayAble} </p>
			   
		}
		
	}//not discount 
	else{ //with discount for retired pastor
	
	    if(feeID==='KingSuite_1A'){
		      return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="618c8230-42c9-4516-af0b-362105304557" /> <input type = "submit" value = "Pay Now!" /> </form>
		}
		else if(feeID=='KingSuite_2A'){
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="63089892-d955-4621-beb9-917afd6af882" /> <input type = "submit" value = "Pay Now!" /> </form> 
				
		}
		else if(feeID==='KingSuite_4A'){
			 return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="8f986676-8d79-497f-b9cd-e9abbb1e7873" /> <input type = "submit" value = "Pay Now!" /> </form>
			 
		}
		else if(feeID==='KingSuite_2A2K'){
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="a439a415-5771-43a3-b725-d12a94a2f521" /> <input type = "submit" value = "Pay Now!" /> </form>
		}
		else if(feeID==='DoubleSuite_2A'){
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="67244eb3-05e9-4dee-8a8f-ee746242abe9" /> <input type = "submit" value = "Pay Now!" /> </form> 
		}
		else if(feeID==='DoubleSuite_4A'){
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="9f24aec8-0796-4f59-a2cc-5dd9387fc4c3" /> <input type = "submit" value = "Pay Now!" /> </form>
		}
		else if(feeID==='DoubleSuite_2A2K'){
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="80e4db68-a225-4cd8-a2e4-59e555bd601b" /> <input type = "submit" value = "Pay Now!" /> </form>
		}
		else if(feeID==='DoubleSuite_4A2K'){
			return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="c2fc2547-0202-4852-93e1-c13f70949f95" /> <input type = "submit" value = "Pay Now!" /> </form>
		}
		else if(feeID==='DoubleSuite_2A4K'){
		    return <form name="PrePage" method = "post" action = "https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"> <input type = "hidden" name = "LinkId" value ="2a4cdead-43a8-4109-b8d0-d87cea0a8e8f" /> <input type = "submit" value = "Pay Now!" /> </form> 
		}
		    
	}
	
}


const sendPaymentNonceToAnet=async (paymentNonce, amountToPay, feeOpt,email,phone,fullName)=>{
	try{ 
	    const sendPaymentAnetUrl='https://api.loichua.church:5001/main/process-payment01' 
	    const dataSend={'paymentNonce':paymentNonce, 'amount':amountToPay ,'feeOpt':feeOpt,'email':email,'phone':phone, 'fullName':fullName }
	    
		const response = await fetch (sendPaymentAnetUrl,{
			method:'POST',
			headers: {'Content-Type':'application/json'},
			body: JSON.stringify(dataSend)
		})
		const data = await response.json()
		
		console.log('got data from backend' + data.status + ' message ' + data.message)
		if(data.status=='OK'){
			console.log('some action to let them know payment is successfully done with option'+data.desc)
			updateDataBase(data)
			alert('You successfully paid for the booking' +data.desc)
		    //updateDataBase(data)
		}
		else{
			alert(data.message)
		}
		
	}
	catch (error){
		console.error('Error sending payment nonce ', error)
		if(error.response && error.response.status===500){
			alert('Failed to process credit card')
		}
	}
} //end sendPaymentNonceToAnet 
const updateDataBase = async (data)=>{
	try{
		const updateHDDB = 'https://api.loichua.church:5001/main/update-hd-db'
		//const dataSend={'data':data}
		const response=await fetch(updateHDDB , {
			method:'POST',
			headers:{'Content-Type':'application/json'},
			body:JSON.stringify(data)
		})
		const databack=await response.json()
		console.log('i got data back from update db ' +JSON.stringify(databack))
	}
	catch(error){
		console.error('update db error', error)
	}
	
}

const HoiDongRegoForm = () => {
	const payCtx = useContext(PaymentContext)
    const useCtx = useContext(AuthContext)
    const [cbNewCamper, setCbNewCamper] = useState(false)
    const [cbReturnCamper, setCbReturnCamper] = useState(false)
    const [fullName, setFullName] = useState('')
    const [age, setAge] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [statecode, setStateCode] = useState('')
    const [zipcode, setZipCode] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    //const [emailError, setEmailError] = useState('')
    const [churchName, setChurchName] = useState('')
   // const [pastorName, setPastorName] = useState('')
    const [numDependents, setNumDependents] = useState('')
 //   const [shirtSize, setShirtSize] = useState('')
    const numDepInput = useRef('')
   // const [personArray, setPersonArray] = useState([])
    const [gender, setGender] = useState('')
    const [numAdult, setNumAdult] = useState('')
    const [numChildren, setNumChildren] = useState('')
    const [feeOpt, setFeeOpt] = useState('')
    const [totalPayAble, setTotalPayAble] = useState('')
    const [dbResponse, setDbResponse] = useState('')
    //const [dependList, setDependList] = useState([])
    //const [dependValue, setDependValue] = useState([])
    const [ccForm, setCCForm]=useState(false)
    const [showSubmit, setShowSubmit]= useState(true)
    const [feeID, setFeeID]=useState('')
    const [regTitle, setRegTitle]=useState('TH') // default to TH church member
    const [bMealDiscount, setBMealDiscount] = useState(0)
    const [totalCharges, setTotalCharges]=useState(0.00)
    const [churchList, setChurchList]=useState([])
    //const [successMessage, setSuccessMessage]=useState(null)
    //for mealOnly option
    const [totalMealAdult,setTotalMealAdult]=useState(0.00)
    const [totalKidMeal,setTotalKidMeal]=useState(0.00)
    const [airport, setAirport]=useState('')
    const [timearrival, setTimeArrival]=useState('')
    
    
    localStorage.clear()
    //get church list from excel backend
    useEffect(()=>{ 
	    fetch('https://api.loichua.church:5001/main/get-hoithanh-options').then(response=>response.json())
	    .then(data=>setChurchList(data))
	    .catch(error=>console.error(error))
    },[])
    
    const DataContent=()=>{
		return <p className='text-danger' style={{fontSize:'1.3rem'}}>ALL ROOMS ARE SOLD OUT AT SHERATON.  ONLY MEALS OPTION AVAILABLE FOR REGISTRATION  
		                                   <br/>If you still need accomodations
		                                       ,please <a href='https://www.booking.com/city/us/pomona.html'>Follow This Link</a>
		                                      <br/>to book room directly with these nearby hotels.  
		                                     <br/>
		                                     <br/>For other enquiries, email to hdgh49@ghvnhk.org or MS LÊ NGỌC TRAI lengoctrai@yahoo.com
		                                     <br/>
		                                     Thank you and looking forward to seeing you at HDGH 49!
		                                     <br/>
		                                     Ban Ghi Danh HDGH 49
		                                     </p>
		                                     
		          
	  }
     const DataContentReadMe=()=>{
		return (
			    <div className="d-flex justify-content-center">
                             
                                 <h5 className='Display-4'>This is a two-step process</h5>
                                 
                            <ul>
                                  <li style={{color:'mediumvioletred', fontSize:'1.5rem'}}>ALL ROOMS SOLD OUT! </li>
                                  <li> <PencilSquare /> Step 1:  when you complete the form and click on Register / Đăng ký - This is only REGISTRATION and NO PAYMENT yet </li> 
                                   <li>&nbsp;</li>
                                  <li> <CreditCard /> Step 2:  when you are successfully done 'registration'  then there is a 'Pay Now!' button showing above the  'Register/Đăng ký' 
                                                    Either Click on 'Pay Now!'  will direct you to our merchant processor secured page to accept your payment, </li> 
                                   <li>OR if you choose to pay by Zelle, then zelle payment to this email address: treasurer@ghvnhk.org</li>
                                  <li> <EnvelopeAt /> All steps are responded with email confirmation</li>
                                  <li>Thank you and looking forward to seeing you at conference </li>
                                  <li>God Bless!</li>
                                  <li>&nbsp;</li>
                                  <li style={{color:'mediumvioletred', fontSize:'1rem'}}>Please note that every registration is unique so please use different name, email and phone number for every registration to avoid 'duplication' errors. Thank you!</li> 
                                  <li style={{color:'mediumvioletred', fontSize:'1rem'}}>If someone else pays on your behalf, PLEASE email us the TRANSACTION ID so our invoicer can reconcile your registration. Thanks! </li> 
                                   <li>&nbsp;</li>
                                   <li style={{color:'mediumvioletred', fontSize:'1rem'}}> <TruckFront/> VERY IMPORTANT: Please call Transport Team - MS Trần Thiện Đức (626) 476 0608 to confirm your arrival and pickup directly with Transport Team</li>
                                   <li style={{color:'mediumvioletred', fontSize:'1rem'}}>Please PAY your transport payment to MS Trần Thiện Đức or MS Nguyễn Kỳ Phú </li>                   
                            </ul>
                              
                             <blockquote>
                                 <h6 className='Display-4'>Need help or question regarding registration:</h6> 
                                 <h6 className='Display-4'>Contact: <br/> MS Le Ngoc Trai (714) 657 4961</h6>
                                 <h6 className='Display-4'>Email to: lengoctrai@yahoo.com</h6>
                             </blockquote>  
                        </div>
		)
		          
	  }
    
    const dependentDataList = []
    //customized for hoidong giao hat lan 49 2924
    const PersonSubForm = (props) => {
        //const [dependValue, setDependValue] = useState([])
        //const dependentDataList = []
        const memberHandler = event => {
            event.preventDefault()
            let dependentDataObj = {}
            //console.log('to do with total dependents ' + numDependents)
            const strData = event.target.value
            const inputName = event.target.name
            console.log(' here data from form ' + strData)
            console.log(' here name of input = ' + inputName)
            dependentDataObj['value'] = strData
            //dependentDataObj['index']=i
            dependentDataList.push(dependentDataObj)
            //setDependValue(dependValue=>[dependValue,...dependentDataObj])
            //localStorage.setItem(inputName,strData)    
            console.log(' here i set dependvalue ...')
            //setDependValue(dependentDataList)


        }
        const personTotal = props.personTotal
        const arrayRange = (start, stop, step) =>
            Array.from(
                { length: (stop - start) / step + 1 },
                (value, index) => start + index * step
            );
        console.log(' got person total forms required = ' + personTotal)
        const personArr = arrayRange(1, parseInt(personTotal), 1)
        //const personArray= [...personArr]
        console.log(' got person array 1 ' + personArr[1] + ' type of ' + typeof (personArr))
        // get a list of object based on number of dependents
        const listOfObjects = [];
        personArr.forEach(function (entry) {
            var singleObj = {}
            singleObj['index'] = entry;
            singleObj['value'] = 'person' + entry;
            listOfObjects.push(singleObj);
        });
        //setPersonArray(listOfObjects)
        console.log(' now i got list of ' + JSON.stringify(listOfObjects))
        for (let i = 0; i < listOfObjects.length; i++) {
            console.log('i got list obj index ' + listOfObjects[i]['value'])
            return (
                listOfObjects.map((obj) => (
                    <Row key={obj.index}>
                        <Col xs={7}>
                            <Form.Control type='text' onBlur={memberHandler} placeholder='john doe, husband, 35, male' name={obj.value} />
                        </Col>
                        <li key={obj.index}>{obj.value}, relationship,age, gender</li>
                    </Row>
                )
                ))
        }
    }//end personsubfrom
    const memberHandler = event => {
        event.preventDefault()
        let dependentDataObj = {}
        //console.log('to do with total dependents ' + numDependents)
        const strData = event.target.value
        const inputName = event.target.name
        console.log(' here data from form ' + strData)
        console.log(' here name of input = ' + inputName)
        dependentDataObj['value'] = strData
        //dependentDataObj['index']=i
        dependentDataList.push(dependentDataObj)
       

    }
    //console('***  got dep data list ' + dependentDataList)
    //const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    /*const newCamperStatusHandler = (event) => {
        setCbNewCamper(event.target.value === 'on' ? true : false)
    }
    const returnCamperStatusHandler = (event) => {
        setCbReturnCamper(event.target.value === 'on' ? true : false)
    }*/
    const nameChangeHandler = event => {
        setFullName(event.target.value)
    }
    const timeArrivalHandler = event => {
        setTimeArrival(event.target.value)
    }
    
    const ageChangeHandler = event => {
        setAge(event.target.value)
    }
    const addressHandler = event => {
        setAddress(event.target.value)
    }
    const cityHandler = event => {
        setCity(event.target.value)
    }
    const zipcodeHandler = event => {
        setZipCode(event.target.value)
    }
    const stateHandler = event => {
        setStateCode(event.target.value)
    }
    const phoneHandler = event => {
        setPhone(event.target.value)
    }
    const isEmail = (email) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    const emailHandler = event => {
        setEmail(event.target.value)

    }
    const churchNameHandler = event => {
        setChurchName(event.target.value)
    }
    /*
    const pastorNameHandler = event => {
        setPastorName(event.target.value)
    }
    */
    const regTitleHandler = event => {
        setRegTitle(event.target.value)
        const discountCode=["RMS","RMSB","WMS"]
        let regTit=event.target.value
        if(discountCode.includes(regTit)){
			setBMealDiscount(1)
        }
    }
    const numDependentHandler = event => {
        let value = event.target.value
        setNumDependents(event.target.value)
        // let value = numDepInput
        console.log('** here i have num dep input ref= ' + value)
        numDepInput.current = value
        console.log(" +++ have ref = " + numDepInput.current)
        if (numDependents) {
            console.log(' i need to return a ccomp here')
        }
    }
    const airportHandler = event => {
        setAirport(event.target.value)
    }
    const getMaleGender = event => {
		
        setGender(event.target.value === 'on' ? 'M' : '')
    }
    const getFemaleGender = event => {
		
        setGender(event.target.value === 'on' ? 'F' : '')
    }
    const numAdultHandler = event => {
        event.preventDefault()
        const mealPerAdult=281.25
        let numAdults=event.target.value
        let totalMealAdult=0.00
        if(feeOpt==='MealsOnly'){
			setNumAdult(numAdults)
			totalMealAdult=mealPerAdult* numAdults
			console.log('adult count= '+ numAdults )// + '='+totalMealAdult)
			console.log('total cost for adult meals ' + totalMealAdult)
			
			setTotalMealAdult(totalMealAdult)
			setTotalPayAble(totalMealAdult+totalKidMeal)
        }
        
       
        setNumAdult(event.target.value)
    } 
    
    const numChildrenHandler = event => {
        event.preventDefault()
        const mealPerKid=141.75 
        let numChildren=event.target.value
        let totalKidMeal=0.00
        let totalMealCostAll=0.00
        if(feeOpt==='MealsOnly'){
			setNumChildren(numChildren)
        	
			totalKidMeal=mealPerKid* numChildren
			console.log('children count= '+ numChildren )// + '='+totalMealAdult)
			console.log('total cost for kid meals ' + totalKidMeal)
			setTotalKidMeal(totalKidMeal)
			totalMealCostAll=totalMealAdult+totalKidMeal 
        	console.log('For meal option total cost '+ totalMealCostAll)
        	setTotalPayAble(totalMealCostAll)
        }
        else{
	        setNumChildren(numChildren)
        	  
        }
        
    }
   // const dateInPast = (firstDate, secondDate) => firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0);

    const feeHandler = event => {
        event.preventDefault()
        const feeOpt = event.target.value
       // const mealPerAdult=281.25 // 9 meals all up
        //const mealPerKid = 141.75
        setFeeOpt(feeOpt)
        let total_payable=0.00 
        //let extraMealCost=0.00
      //  let discountPercent=0.50
        let regTitle01=regTitle  //local variable to recompute special rates for ms tri su, qua phu v.v...
         console.log('here i got reg title='+ regTitle01)
        const discountMealCode=["RMS","RMSB","WMS"] // these titles entited for 50% meal discount
        
          if (feeOpt === 'KingSuite_1A') {
            //total_payable = payCtx.package0001 // package 1 650 + 281.25
            if (discountMealCode.includes(regTitle01)){
	            console.log(' need to recompute the rates for ' + regTitle01)
	            //total_payable=  512.50//payCtx.package0001*discountPercent  //- 281.25 + 281.25/2
	            total_payable=  payCtx.package0001
	            console.log('now recompute discount for 50% package' + total_payable)
	            setBMealDiscount(1)
            }
            else{
	            //console.log('here i have numAdult ' + numAdult)
	            //extraMealCost=mealPerAdult * (numAdult-1) //the registrant cost is already calcualted in package
	           total_payable = payCtx.package0001 //+ extraMealCost// package 1 650 + 281.25
            }
            
            console.log(' total payable per room selected ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable.toFixed(2))
            localStorage.setItem('totalPayAble', total_payable.toFixed(2))

        }
        else if (feeOpt === 'KingSuite_2A') { //package 2
            if (discountMealCode.includes(regTitle01)){
	            console.log(' need to recompute the rates for ' + regTitle01)
	            //total_payable= 700.00 //payCtx.package0002*discountPercent  //- 562.50 + 562.50/2
	            total_payable= payCtx.package0002
	            console.log('now recompute discount for 50% meals' + total_payable)
	            setBMealDiscount(1)
            }
            else{
	           total_payable = payCtx.package0002 // package 1 650 + 281.25
            } 
            //total_payable = payCtx.package0002 //numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if (feeOpt === 'KingSuite_3A') { //package 2a	
            if (discountMealCode.includes(regTitle01)){
	            console.log(' need to recompute the rates for ' + regTitle01)
	            //total_payable= 700.00 //payCtx.package0002*discountPercent  //- 562.50 + 562.50/2
	            total_payable= payCtx.package002A
	            console.log('now recompute discount for 50% meals' + total_payable)
	            setBMealDiscount(1)
            }
            else{
	           total_payable = payCtx.package002A // package 1 650 + 281.25
            } 
            //total_payable = payCtx.package0002 //numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if (feeOpt === 'KingSuite_4A') { //package 3 
            if (discountMealCode.includes(regTitle01)){
	            console.log(' need to recompute the rates for ' + regTitle01)
	            total_payable=payCtx.package0003 //* discountPercent //- 1125.00 + 1125.00/2
	            
	            console.log('now recompute discount for 50% meals' + total_payable)
	            setBMealDiscount(1)
            }
            else{
	           total_payable = payCtx.package0003 // package 1 650 + 281.25
            }
            
            //total_payable = payCtx.package0003 //numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if (feeOpt === 'KingSuite_2A2K') { //package 4
            if (discountMealCode.includes(regTitle01)){
	            console.log(' need to recompute the rates for ' + regTitle01)
	            total_payable=payCtx.package0004 //* discountPercent  //- 840.00 + 840.00/2
	            
	            console.log('now recompute discount for 50% meals' + total_payable)
	            setBMealDiscount(1)
            }
            else{
	           total_payable = payCtx.package0004 // package 1 650 + 281.25
            }
            //total_payable = payCtx.package0004  //correction by Mr Truong Ho 3/21/2024
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if (feeOpt === 'DoubleSuite_2A') { // pkg 5
        
            if (discountMealCode.includes(regTitle01)){
	            console.log(' need to recompute the rates for ' + regTitle01)
	            //total_payable= 700.00  //payCtx.package0005  * discountPercent //- 562.50 + 562.50/2
	            total_payable= payCtx.package0005//  * discountPercent //- 562.50 + 562.50/2
	            console.log('now recompute discount for 50% meals' + total_payable)
	            setBMealDiscount(1)
            }
            else{
	           total_payable = payCtx.package0005 // package 1 650 + 281.25
            }
            //total_payable = payCtx.package0005 //numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if (feeOpt === 'DoubleSuite_2A1K') { // pkg 5
        
            if (discountMealCode.includes(regTitle01)){
	            console.log(' need to recompute the rates for ' + regTitle01)
	            //total_payable= 700.00  //payCtx.package0005  * discountPercent //- 562.50 + 562.50/2
	            total_payable= payCtx.package005A//  * discountPercent //- 562.50 + 562.50/2
	            console.log('now recompute discount for 50% meals' + total_payable)
	            setBMealDiscount(1)
            }
            else{
	           total_payable = payCtx.package005A // package 1 650 + 281.25
            }
            //total_payable = payCtx.package0005 //numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if (feeOpt === 'DoubleSuite_4A') { // pkg 6
            if (discountMealCode.includes(regTitle01)){
	            console.log(' need to recompute the rates for ' + regTitle01)
	            total_payable=payCtx.package0006 //* discountPercent //- 1125.00 + 1125.00/2
	            
	            console.log('now recompute discount for 50% meals' + total_payable)
	            setBMealDiscount(1)
            }
            else{
	           total_payable = payCtx.package0006 // package 1 650 + 281.25
            }
            //total_payable = payCtx.package0006 //numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if (feeOpt === 'DoubleSuite_2A2K') { // pkg 7
            
            if (discountMealCode.includes(regTitle01)){
	            console.log(' need to recompute the rates for ' + regTitle01)
	            total_payable=payCtx.package0007 //* discountPercent //- 840.00 + 840.00/2
	            
	            console.log('now recompute discount for 50% meals' + total_payable)
	            setBMealDiscount(1)
            }
            else{
	           total_payable = payCtx.package0007 // package 1 650 + 281.25
            }
            //total_payable =  payCtx.package0007 // correction by Truong Ho 3/21/2024
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if (feeOpt === 'DoubleSuite_4A2K') { //pk 8
            if (discountMealCode.includes(regTitle01)){
	            console.log(' need to recompute the rates for ' + regTitle01)
	            total_payable=payCtx.package0008//  * discountPercent //- 1402.50 + 1402.50/2
	            
	            console.log('now recompute discount for 50% meals' + total_payable)
	            setBMealDiscount(1)
            }
            else{
	           total_payable = payCtx.package0008 // package 1 650 + 281.25
            }
            //total_payable = payCtx.package0008  //numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if (feeOpt === 'DoubleSuite_2A4K') { //pk 9
            if (discountMealCode.includes(regTitle01)){
	            console.log(' need to recompute the rates for ' + regTitle01)
	            total_payable=payCtx.package0009 // *  discountPercent//- 1117.50 + 1117.50/2
	            
	            console.log('now recompute discount for 50% meals' + total_payable)
	            setBMealDiscount(1)
            }
            else{
	           total_payable = payCtx.package0009 // package 1 650 + 281.25
            }
            //total_payable = payCtx.package0009  // correction by Mr Truong Ho 3/21/2024
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if(feeOpt==='MealsOnly'){
	         console.log('this is ' + feeOpt)
	         console.log(' here i have total adult ' +  numAdult )
	         console.log(' here i have total children' +  numChildren )
	         
	          
         }
         else if(feeOpt==='TestOnly'){
	            console.log('im in testing mode')
	         	total_payable=1.00
	         	setTotalPayAble(total_payable)
         }
        /** Suggestion if registrants can be special fee? or need to register all ?
            else if { feeOpt ==='TBD') {
				total_payable=0.00 
				setTotalPayAble(total_payable)
				
            }
	        
        */
        
        /*
        else {
            total_payable = numChildrenPay * noSleepOverFeeChild + numAdult * noSleepOverFeeAdult
            console.log(' total payable before 1/5/2023 ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        }
        */
        setTotalPayAble(total_payable.toFixed(2))
        localStorage.setItem('totalPayAble', total_payable.toFixed(2))

    }//end fee handler 
    const updateDependList = (numDependents) => {
        console.log('**** i call func to update depend list ')
        let perVal = ''
        for (let i = 0; i++; i < numDependents) {
            perVal = localStorage.getItem('person' + i.toString)
            console.log(' here i got person value  ' + i.toString + '=' + perVal)
        }
    }
    const getCamperData = (event) => {
        event.preventDefault()
        //setDbResponse('')
        console.log('im click to submit with some value bnew camper=' + cbNewCamper + ' &  bReturn= ' + cbReturnCamper + '\n full name =' + fullName +
            '\n age=' + age + ' Gender=' + gender + '\n address ' + address + ' city= ' + city + ' zipcode= ' + zipcode + '\n church attend=' + churchName + '\n' +
            '\n no. of dependent=' + numDependents + '\n' + JSON.stringify(dependentDataList) + '\n feeOpt ' + feeOpt + '\n total adult ' + numAdult + '\n num children ' + numChildren + '\n' 
            //'dependant value= ')

        )//' + bNewCamper + ' bRetCamper:  ' + bRetCamper)
        const now = new Date()//.toLocaleDateString('en-US')
        console.log('time now ' + now)
        //updateDependList(numDependents)
        //let numChildrenPay = 0
       // let total_payable = 0.00
        //const future = new Date('2024-05-06')
        //const past = new Date('2024-01-01')
        console.log('time now ' + now)
        //console.log(dateInPast(now, future)) //still not there yet true
        //console.log(dateInPast(now, past)) // false 
        //if (dateInPast(now, future)) {
        //console.log(' im still not there in future date 5/1/2023 yet')
        //const roomFee=650.00
        //const motelFeeAdult = 195.00
        //const motelFeeChild = 115.00
        ///const domFeeAdult = 145.00
        //const domFeeChild = 85.00
       // const noSleepOverFeeChild = 20.00
        //const noSleepOverFeeAdult = 40.00
        /*if (numChildren >= 4) {
            numChildrenPay = numChildren - 2

        }
        else {
            numChildrenPay = numChildren
        }
        */
	   //"KingSuite-1A", "KingSuite-2A", "KingSuite-4A" ,"KingSuite-2A2K","DoubleSuite-4A","DoubleSuite-2A","DoubleSuite-2A2K","DoubleSuite-4A2K","DoubleSuite-2A4K"
        /*
        if (feeOpt === 'KingSuite_1A') {
            total_payable = 1025.00 //numChildrenPay * domFeeChild + numAdult * domFeeAdult
            console.log(' total payable per room selected ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)

        }
        else if (feeOpt === 'KingSuite_2A') {
            total_payable = 1400.00 //numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if (feeOpt === 'KingSuite_4A') {
            total_payable = 2150.00 //numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if (feeOpt === 'KingSuite_2A2K') {
            total_payable = 1770.00  //numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if (feeOpt === 'DoubleSuite_2A') {
            total_payable = 1400.00 //numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if (feeOpt === 'DoubleSuite_2A2K') {
            total_payable = 1770.00 //numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if (feeOpt === 'DoubleSuite_4A2K') {
            total_payable =  2520.00 //numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
        else if (feeOpt === 'DoubleSuite_2A4K') {
            total_payable = 2140.00  //numChildrenPay * motelFeeChild + numAdult * motelFeeAdult
            console.log(' total payable  ' + total_payable.toFixed(2))
            setTotalPayAble(total_payable)
        
        }
       
       
        setTotalPayAble(total_payable)
        localStorage.setItem('totalPayAble', total_payable)
        */ 
       
        // updateDependList(numDependents)
        

        const submitData = {
            
            cbNewCamper: cbNewCamper,
            cbReturnCamper: cbReturnCamper,
            fullName: fullName,
            age: age,
            gender: gender,
            phone: phone,
            email: email,
            address: address,
            city: city,
            zipcode: zipcode,
            statecode:statecode,
            churchName: churchName,
//            pastorName: pastorName,
            numDependents: numDependents,
            dependantDataList: dependentDataList,
            feeOpt: feeOpt,
            numAdult: numAdult,
            numChildren: numChildren,
            regTitle:regTitle,
            bMealDiscount:bMealDiscount,
           
            totalPayAble: totalPayAble,//total_payable //localStorage.getItem('totalPayAble'), //totalPayAble
            airport: airport,
            timearrival:timearrival

        }
        axios.post(useCtx.sendHoiDongData, submitData).then(
            response => {
                console.log(response.data.status, response.data.message)
                console.log(' i got ' + response.status)
                setDbResponse('')
                if (response.status === 200) {
                    if (!response.data.status) {
                        console.log(' error in data saving: ' + response.data.message)
                        setDbResponse(response.data.message)

                    }
                    else {
                        setDbResponse(response.data.message)
                        setTotalCharges(response.data.totalCharges)
                        console.log('good data response ' + dbResponse + 'show the cc form? and feOpt' + response.data.feeOpt)
                        setCCForm(true)
                        setShowSubmit(false)
                        setFeeID(response.data.feeOpt)

                    }
                }
            }
        ).catch(error => {
            console.log(' got error ' + error)
        })

    }
    
    return (
        <div className='container'>
              
            <NavBarHoiDongRego />
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
     
              <MyPopUp  btnLabel={'Read Me First!'} data={<DataContentReadMe/>} /> 
              
               
              <MyPopUp  btnLabel={'ROOMS SOLD OUT!'} data={<RoomStat/>} /> 
              
              <MyPopUp  btnLabel={'Add me to waiting list to share room'} data={<DataContent/>} /> 
             </div>         
              <blockquote className="blockquote text-center">
                     
  						<p class="mb-0" style={{fontFamily:'sans-serif', fontSize:'0.9rem'}}>Quí vị đã được chuyển qua trang nhà của Hội Thánh Tin Lành Lời Chúa , Houston, TX   (LoiChua.Church )
               <br/>Ban Ghi Danh Hội Đồng Giáo Hạt 49, xin chân thành cảm ơn HT TL Lời Chúa đã dùng domain loichua.church để giúp
                        vấn đề kỹ thuật và host cho việc ghi danh HDGH 49 trực tuyến online. <br/>
                        
                        </p>
  						<footer class="blockquote-footer"><br/><cite title="Source Title">Ban Ghi Danh HDGH 49</cite></footer>
			</blockquote>
              <section style={{color:'indianred' , fontSize:'1.25rem'}}>
                       ALL ROOMS ARE SOLD OUT.  HOWEVER, YOU CAN CONTINUE TO REGISTER FOR MEALS ONLY PACKAGE<br/>
                       ADULT $281.25 /PERSON /9 MEALS  & KID $141.75 /PERSON/9 MEALS<br/>
                       SORRY NO PARTIAL MEAL PACKAGE! ALL INCLUDES 9 MEALS!
                       <br/>
                       Please use 'name', 'phone'' and 'email' which are not being registered before or it will raise 'Duplication' error
                       as we like to keep all registrations are unique. 
                       <br/>For MealsOnly option,  once registration is completed, you will receive an invoice to pay
                       <br/>
                       Thank you
                       
              </section>
                       <p>&nbsp;</p> 
                 
            <div style={{ background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(81,24,57,1) 0%, rgba(214,122,142,1) 30%, rgba(0,212,255,1) 100%)" }}>
            {/**  background: rgb(2,0,36);
            background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(81,24,57,1) 0%, rgba(214,122,142,1) 30%, rgba(0,212,255,1) 100%)"
              old: 
               background: "linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(76,101,221,0.9290091036414566) 100%)"
            
             */}


                <div className="mb-3">
                    <Form onSubmit={getCamperData}>
                         <br/>
                        <h6 style={{ color: 'whitesmoke', fontWeight: "bold" }}><InfoCircle />  1. Primary Registrant Information</h6>
                         <p style={{color:'yellow' , fontWeight:'bolder'}}>Type in your name the same as on your ID for later Hotel CheckIn</p>
                        {['checkbox'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                                 

                                <Row>{/* Primary Camper */}
                                    <Col xs={5}>
                                        
                                        <Form.Group controlId="form.fullName">
                                            <Form.Control type="text" value={fullName} onChange={nameChangeHandler} placeholder="Enter Full Name" required />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="form.age">
                                            <Form.Control type="text" value={age} onChange={ageChangeHandler} placeholder="Age" pattern="[0-9]+" required />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="checkbox"
                                            id="autoSizingCheck2"
                                            label="Male"
                                            name='maleGender'
                                            onChange={getMaleGender}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="checkbox"
                                            id="autoSizingCheck2"
                                            label="Female"
                                            name='femaleGender'
                                            onChange={getFemaleGender}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                  <Col>
                                        <Form.Label><strong>Your Title: MS=Mục Sư, TD=Truyền Đạo, CTVMV=Công Tác Viên Mục Vụ, TH=Tín Hữu, RMS=MS Trí Sự, 
                                        RMSB= Bà MS Trí Sự, WMS=MS Góa Phụ</strong></Form.Label>
                                        <Form.Control as="select" className="rounded-0 shadow" onChange={regTitleHandler}>
                                            <option className="d-none" value="{regTitle}">
                                                Your Title
											 </option>
                                            {["MS", "TD", "CTVMV", "TH", "RMS","RMSB","WMS"].map(option => (
                                                <option key={option}>{option}</option>
                                            ))}
                                            
                                            
                                        </Form.Control>

                                    </Col>
                                </Row>
                               
                                <Row>&nbsp;</Row>
                                <Row>
                                    <Col>
                                        <Form.Control type='text' value={address} onChange={addressHandler} placeholder='Address' />
                                        <br />
                                    </Col>
                                   </Row>
                                   <Row> 
                                    <Col>
                                        <Form.Control type='text' value={city} onChange={cityHandler} placeholder="City" />
                                    </Col>
                                    <Col>
                                        <Form.Control type='text' value={statecode} onChange={stateHandler} placeholder="State"  pattern="[A-Za-z]*" maxLength='2'/>
                                    </Col>
                                    <Col>
                                        <Form.Control type='text' value={zipcode} onChange={zipcodeHandler} placeholder="Zip" pattern="[0-9]*" maxLength='5'/>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col xs={6}>
                                        <Form.Control type='text' value={phone} onChange={phoneHandler} placeholder='Your phone #, 10 digits only' pattern="[0-9]*" maxLength='10' required/>
                                    </Col>
                                    <Col>

                                        <Form.Control type='email' value={email} onChange={emailHandler} placeholder='i.e YourEmail@any.com ' maxLength='65' required />

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label><strong>Your Church:</strong></Form.Label>
                                        <Form.Control as="select" className="rounded-0 shadow" onChange={churchNameHandler}>
                                            <option className="d-none" value="{churchName}">
                                                Select Your Church From List
											</option>
                                            {/** churchList is from backend exel sheet and  */}
                                            {churchList.map(option => ( 
                                                <option key={option}>{option}</option>
                                            ))}
                                        </Form.Control>
                                          <br/>
                                    </Col>

                                </Row>
                                
                                <Row><br /><h6 style={{ color: 'whitesmoke', fontWeight: "bold" }}><FileBarGraph /> 2. Total Count Family Members / Friends </h6></Row>


                                <Row>
                                    <Col>
                                        <Form.Label><strong>Fee Schedules: A=Adult, K=Kid 2A=2 Adults, 2K=2 Kids, i.e 2A2K=2 Adults+2 Kids</strong>
                                                 <br/>9 Stay-In Packages and also MealsOnly Option available  
                                                 <p style={{color:'yellow', fontWeight:'bold'}}>If you select MealsOnly, please CHECK your email for instruction link to pay</p>  
                                        
                                        </Form.Label>
                                         <Form.Control as="select" className="rounded-0 shadow" onChange={feeHandler}>
                                            <option className="d-none" value="{feeOpt}">
                                                Select Room/Suite Type
										    </option>
										      {
										      [ "MealsOnly", "KingSuite_1A", "KingSuite_2A","KingSuite_3A"  ,"KingSuite_4A" ,"KingSuite_2A2K","DoubleSuite_2A","DoubleSuite_4A","DoubleSuite_2A2K","DoubleSuite_4A2K","DoubleSuite_2A4K"]
	                                           .map(option =>(
                                                <option key={option}>{option}</option>
                                              ))
                                              }
                                            
                                           
                                            </Form.Control>
                                              </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Label><strong>Please enter total adults (from 13 yrs old and above, including yourself)</strong></Form.Label>
                                        <Form.Control type='text' value={numAdult} onChange={numAdultHandler}  placeholder="No. of Adult >=13 years old" />

                                    </Col>
                                    <Col>
                                         <Form.Label><strong>Please enter total children (from 5 to 12 yrs old)</strong></Form.Label>
                                        
                                        <Form.Control type='text' value={numChildren} onChange={numChildrenHandler} placeholder="No. of Children <=12 years old" />

                                    </Col>
                                    
                                    <br/><span style={{color:'yellow', fontWeight:'bolder', fontSize:'1.5rem'}}>Package PayAble = ${totalPayAble?totalPayAble:0}</span>
                                     <br/>
                                     <br/>

                                </Row>

                                
                                <Row>
                                    <h6 style={{ color: 'whitesmoke', fontWeight: "bold" }}><PersonStanding /><PersonStandingDress /> 3. Your dependants (family, friends) in details</h6>
                                     <p style={{color:'ghostwhite'}}>Please provide detais of your dependants in this order<br/>
                                     full name, relationship , age, gender</p>
                                     
                                    <Col xs={12}>
                                         <Form.Label><strong>Please enter numbers of dependants (excluding you)</strong>
                                                      <br/>then kindly enter fullname ,relationship, age, gender (in the next input box)
                                                      <br/>For example:  Jane Doe, wife, 80, female 
                                         </Form.Label>
                                        
                                        <Form.Control type='text' value={numDependents} onChange={numDependentHandler} placeholder="number i.e 2 or 3 "pattern="[0-9]*"  ref={numDepInput} maxLength='1' />


                                    </Col>
                                </Row>
                                <Row>
                                    {numDependents ? <PersonSubForm personTotal={numDependents} /> : ''}
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Label><span style={{color:'yellow', fontWeight:'bolder'}}>Transport (This fee is not included in all packages!)
                                                 <br/>Payment will be made on the day of arrival, please PAY to transport team
                                                 <br/> $50/person/trip (LAX) 
                                                       , $40/person/trip (SNA)
                                                       ,$30/person/trip (ONT)
                                                  
                                                 </span>
                                                 <span style={{color:'yellow', fontWeight:'bolder'}}><br/>PLEASE call MS Tran Thien Duc (626) 476 0608 to schedule your pick up</span> 
                                        </Form.Label>
                                         <Form.Control as="select" className="rounded-0 shadow" onChange={airportHandler}>
                                            <option className="d-none" value="{airport}">
                                                Select AirPort (Los Angeles-LAX,Ontario-ONT ,JohnWayne-SNA)
										    </option>
										      {
										      ["LAX", "ONT" ,"SNA", "MYOWN"]
	                                           .map(option =>(
                                                <option key={option}>{option}</option>
                                              ))
                                              }
                                             
                                         </Form.Control>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Label><span style={{color:'yellow', fontWeight:'bolder'}}>Time Of Arrival
                                                 
                                                  <br/>Last pick up at 8:00pm - no exception  </span>
                                        </Form.Label>
                                         <Form.Control as="select" className="rounded-0 shadow" onChange={timeArrivalHandler}>
                                            <option className="d-none" value="{timearrival}">
                                                Select Time Frame
										    </option>
										      {
										      ["9AM","10AM","11AM","12PM","1PM","2PM","3PM","4PM","5PM","6PM","7PM","8PM"]
	                                           .map(option =>(
                                                <option key={option}>{option}</option>
                                               ))
                                              }
                                             
                                         </Form.Control>
                                    </Col>
                                </Row> 
                            </div>
                        ))}
                        <br />
                        {/** 
                        <Button variant="primary" type="submit" size='sm'>Register</Button>
                        
                        */}
                        <Row>
                            <div style={{ color: 'yellow', paddingTop: '20px' }}>{dbResponse ? dbResponse : ''}</div>
                            
                            {/** HERE SHOW THE PAY NOW BUTTON PER CONDITION such as done with registration then show it */}
                            
                            <div> { ccForm? <PayPackageComp feeID={feeID} bMealDiscount={bMealDiscount} numAdult={numAdult} numChildren={numChildren} totalPayAble={totalPayAble}/> :''}</div>
                            
                            
                            <div style={{ color: 'white' }}>
                                <p>&nbsp;</p>
                                <p>Your option is {feeOpt ? feeOpt : 'N/A'} <br />
                                          dependantDataList: .............
                                <br />
                                with Adult={numAdult ? numAdult : 0}  and children={numChildren ? numChildren : 0} ,<br />
                                </p>
                                <div style={{ background: '#fff', color: '#487FDB' }}>
                                    <h6>Total Payable ${totalCharges ? totalCharges : 0.00}</h6>
                                      {/*
                                    <p>Please make a Check / Money Order payable to <br /> "Vietnamese Alliance Church" , memo "Registration District Conference 49th"</p>
                                    <p>
                                        Address: John Doe
                                    	<br />12345 Joy St.
                                    	<br />Garden Grove, CA 12345
                                   </p>
                                     */}
                                    <p>ONLY Visa/Master/Discover Cards Accepted (No American Express)
                                       <br/>OR You can also pay by Zelle to email: treasurer@ghvnhk.org (Preferred Method)  
                                    </p>
                                    
                                    
                                    {showSubmit?<Button variant="primary" type="submit" size='sm'><span style={{fontWeight:'bold'}}>Register/ Đăng ký</span></Button>:<p>Please click on Pay Now</p>}
                                    
                                    
                                      
                                    
                                    
                                </div>

                            </div>

                        </Row>
                    </Form>

                    <p className='text-primary'><a href='/login' className='text-white'>&nbsp;</a></p>
                </div>
            </div>
        </div >
    )
}
export default HoiDongRegoForm