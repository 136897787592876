import React from 'react';
import JotFormEmbed from 'react-jotform-embed';

const JotFormTest = () => {
  const formID = '241273031424141'; // Replace with your form ID

  return (
    <JotFormEmbed formID={formID} src='https://form.jotform.com/241273031424141'/>
  );
};

export default JotFormTest;


/*
const JotFormEmbed = () => {
  const [formHeight, setFormHeight] = useState(400); // Optional: Set initial height

  const iFrameCode = 'YOUR_IFRAME_CODE_HERE'; // Replace with your actual code

  return (
    <iframe
      src={iFrameCode}
      width="100%"
      height={formHeight}
      frameBorder="0"
      scrolling="auto"
      allowtransparency="true"
    ></iframe>
  );
};

export default JotFormEmbed;
*/
/*

const TestComp = () => {

    return (
        <>
          
    <iframe
      id="JotFormIFrame-241273031424141"
      title="HDGH"
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allow="geolocation; microphone; camera; fullscreen"
      src="https://form.jotform.com/241273031424141"
      frameborder="0"
      style="min-width:100%;max-width:100%;height:539px;border:none;"
      scrolling="no"
    >
    </iframe>
    <script src='https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'></script>
    <script>window.jotformEmbedHandler("iframe[id='JotFormIFrame-241273031424141'", "https://form.jotform.com/")</script>
    
        </>
    )
}
export default TestComp



    <iframe
      id="JotFormIFrame-241273031424141"
      title="HDGH"
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allow="geolocation; microphone; camera; fullscreen"
      src="https://form.jotform.com/241273031424141"
      frameborder="0"
      style="min-width:100%;max-width:100%;height:539px;border:none;"
      scrolling="no"
    >
    </iframe>
    <script src='https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'></script>
    <script>window.jotformEmbedHandler("iframe[id='JotFormIFrame-241273031424141'", "https://form.jotform.com/")</script>
    
*/