//import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Carousel } from 'react-bootstrap';
//import '../App.css';
import './TopSlide.css';

const TopSlide = (props) => {
    return (
        <React.Fragment>
            <Carousel variant="dark" className='Topslide_short'>
                <Carousel.Item>
                    <img
                        className="d-block w-100 smaller-img"

                        src="assets/images-cus/Picnic-yem-loichua.jpeg"
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h5>Picnic </h5>
                        <p>We had fun in the park!</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 smaller-img"
                        src="assets/images-cus/children-picnic.jpeg"
                        alt="Second slide"
                    />
                    <Carousel.Caption>
                        <h5>Children</h5>
                        <p>All together.. </p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100  smaller-img"
                        src="assets/images-cus/youth-loichua.jpeg"
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                        <h5>YEM's Bible Study</h5>
                        <p></p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

        </React.Fragment>
    )
}

export default TopSlide;