import React from 'react'
import DataTable, { createTheme } from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { useHistory } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap'

//import Export from 'react-data-table-component'
import { useEffect, useContext, useState , useRef } from 'react';
import AuthContext from '../store/auth'
import axios from 'axios'
import '../xuan2023/modal.css'
import Popup from "reactjs-popup";
import "bootstrap/dist/css/bootstrap.min.css";
import "reactjs-popup/dist/index.css";
import HoiDongDataExport from './HoiDongDataExport';
import HoiDongRoomList from './HoiDongRoomList';
import EmailBot from './EmailBot'
//import { MyTextInput, MyCheckBox, MySelect } from '../generalform/GeneralFormik'

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme('solarized', {
    text: {
        primary: '#1c50f5',
        secondary: '#2aa198',
    },
    background: {
        default: 'white',
    },
    context: {
        background: '#80aeab',
        text: '#1c50f5',
    },
    divider: {
        default: '#afb8ba',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');
const columns = [
    {
        name: 'UID',
        selector: row => row.userid,
        sortable: true,
        grow: 2
    },
    {
        name: 'Registrant Name',
        selector: row => row.fullname,
        sortable: true,
        grow: 2
    },
    {
        name: 'Phone',
        selector: row => row.phone,
        grow: 2
    },
    {
        name: 'Email',
        selector: row => row.email,
        grow: 3
    },
    
    {
        name: 'Title',
        selector: row => row.regtitle,
        sortable: true,
        grow: 2
    },
    {
        name: 'Transaction ID',
        selector: row => row.transactionId ? row.transactionId: 'n/a',
        sortable: true,
        cell: row =>(
			 
			     !row.transactionId?<div style={{color:'red'}}>NA</div>:<div style={{color:'green'}}>{row.transactionId}</div>	 
			 
			
		)
    },
    {
        name: 'Total PayAble',
        selector: row => row.totalPayAble,
        sortable: true
    },
    {
        name: ' Fully Paid?',
        selector: row => row.bPaidFull ?<div style={{color:'green'}}>Yes</div> :<div style={{color:'red'}}>No </div>,
        sortable: true
    },
    {
        name: 'Church Name',
        selector: row => row.churchName,
        sortable: true,
        grow: 4 //set increase width to show all job number or it is truncated if long string
    },
    {
        name: 'Adult(s)',
        selector: row => row.numAdult
    },
    {
        name: 'Chidren',
        selector: row => row.numChildren
    },
    {
        name: 'Dependants',
        selector: row => row.dependantDataList,
        grow: 4
    },
    {
        name: 'Fee Option',
        selector: row => row.feeOpt,
        sortable: true,
        grow: 2
    },
    {
        name: 'Registed On',
        selector: row => row.registeredOn,
        sortable: true,
        grow: 2
    },
    
    
    /*
    {
        name: 'Zipcode',
        selector: row => row.zipcode,
        sortable: true,
        grow: 2
    },
     {
        name: 'State',
        selector: row => row.statecode,
        sortable: true,
        grow: 2
    },
    {
        name: 'Church Name',
        selector: row => row.churchName,
        sortable: true,
        grow: 3 //set increase width to show all job number or it is truncated if long string
    },
    {
        name: 'Adult(s)',
        selector: row => row.numAdult
    },
    {
        name: 'Chidren',
        selector: row => row.numChildren
    },
    {
        name: 'Fee Option',
        selector: row => row.feeOpt,
        sortable: true,
        grow: 2
    },
    {
        name: 'Registed On',
        selector: row => row.registeredOn,
        sortable: true,
        grow: 2
    },
     {
        name: 'Total PayAble',
        selector: row => row.totalPayAble,
        sortable: true
    },
     {
        name: ' Fully Paid?',
        selector: row => row.bPaidFull ? 'Yes' : 'No',
        sortable: true
    },
    {
        name: 'Transaction ID',
        selector: row => row.transactionId ? 'Yes' : 'No',
        sortable: true
    },
    {
        name: 'Dependents',
        selector: row => row.dependantDataList,
        grow: 2
    },
    {
        name: 'Memo Text ',
        selector: row => row.memoText,
        sortable: true
    },
    {
        name: 'Stay IN',
        selector: row => row.roomNumber,
        sortable: true
        //grow:2 //set increase width to show all job number or it is truncated if long string
    },
   {
        name: 'Arrival Airport',
        selector: row => row.airport,
        sortable: true
        //grow:2 //set increase width to show all job number or it is truncated if long string
    },
    {
        name: 'Arrival Time',
        selector: row => row.timearrival,
        sortable: true
        //grow:2 //set increase width to show all job number or it is truncated if long string
    },
    {
        name: 'Discount Meal',
        selector: row => row.bMealDiscount,
        sortable: true
        //grow:2 //set increase width to show all job number or it is truncated if long string
    },
    */
];

/*
const conditionalRowStyles = [
  {
    when: row => row.transactionId!=='',
    style: {
      backgroundColor: 'green',
      color: 'white',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  // You can also pass a callback to style for additional customization
  {
    when: row => row.bPaidFull=1,
    style: row => ({
      backgroundColor: row.isSpecia ? 'pink' : 'inerit',
    }),
  },
];
*/

const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

function MyComponent() {

    const useCtx = useContext(AuthContext)
    const [dataTable, setDataTable] = useState([])
    const [adultCnt, setAdultCnt] = useState(0)
    const [childrenCnt, setChildrenCnt] = useState(0)
    const [totalReceiveAble, setTotalReceiveAble] = useState(0.00)
    const [totalReceived, setTotalReceived] = useState(0.00)
    const [balance, setBalance] = useState(0.00)
    const [formOn, setFormOn] = useState(false)
    const [rowData, setRowData] = useState([])
   // const [smallShirt, setSmallShirt] = useState(0)
    //const [medShirt, setMedShirt] = useState(0)
    //const [largeShirt, setLargeShirt] = useState(0)
    //const [xLargeShirt, setXLargeShirt] = useState(0)
    const [ kingSuiteCnt, setKingSuiteCnt] = useState(0)
    const [doubleSuiteCnt, setDoubleSuiteCnt] = useState(0)
    const [mealsOnlyOrder, setMealsOnlyOrder] = useState(0)
    const [totalUsers, setTotalUsers] = useState(0)
    const [paidUsers, setPaidUsers]=useState(0)
    const tableRef = useRef(null)
    //const [value, setValue] = useState('')
    //const tableData={columns,dataTable}
    let totalAdults = 0
    let totalChildren = 0
    let totalReceiveAbleAs = 0.00
    let totalReceivedAs = 0.00
    let balanceAs = 0.00
    
    const fetchData=()=>{
		
		  fetch(useCtx.getHoiDongUserAll).then(res => res.json()).then(result => {
            
            console.log(' i got all result by getting all ' + JSON.stringify(result))
            if (result.status === 1) {
                setDataTable(result.data)
                let dataRetrieved = result.data
                if (dataRetrieved.length > 0) {
                   

                    dataRetrieved.map((dObj) => {
                        console.log('got no. of adult ' + dObj.numAdult)
                        totalAdults += dObj.numAdult
                        totalChildren += dObj.numChildren
                        totalReceiveAbleAs += dObj.totalPayAble
                   
                        if (dObj.bPaidFull) {
                            //console.log(' here i got flag pay full ' + dObj.bPaidFull)
                            totalReceivedAs += dObj.totalPayAble
                        }


                    })
                    //set king and double room taken at the moment
                    setDoubleSuiteCnt (result.doubleSuiteCnt)
                    setKingSuiteCnt(result.kingSuiteCnt)
                    setMealsOnlyOrder(result.mealsOnlyOrder)
                    setAdultCnt(totalAdults)
                    //localStorage.set('adultCnt', totalAdults)
                    //localStorage.set('childrenCnt', totalChildren)
                    setChildrenCnt(totalChildren)
                    setTotalReceiveAble(totalReceiveAbleAs.toFixed(2))
                    setTotalReceived(totalReceivedAs.toFixed(2))
                    //find out balance
                    balanceAs = totalReceiveAbleAs - totalReceivedAs
                    setBalance(balanceAs.toFixed(2))
                    // total users and paid users
                    setTotalUsers(result.totalUsers) 
                    setPaidUsers(result.paidUsers)
                }
            }


        })
		
	}
    const refreshData = () => {
    fetchData().then(() => {
      if (tableRef.current) {
        tableRef.current.forceUpdate(); // Assuming RDTC has a forceUpdate method
      }
      })
    }
    useEffect(() => {
          fetchData()
        /*
        fetch(useCtx.getHoiDongUserAll).then(res => res.json()).then(result => {
            
            console.log(' i got all result by getting all ' + JSON.stringify(result))
            if (result.status === 1) {
                setDataTable(result.data)
                let dataRetrieved = result.data
                if (dataRetrieved.length > 0) {
                   

                    dataRetrieved.map((dObj) => {
                        console.log('got no. of adult ' + dObj.numAdult)
                        totalAdults += dObj.numAdult
                        totalChildren += dObj.numChildren
                        totalReceiveAbleAs += dObj.totalPayAble
                   
                        if (dObj.bPaidFull) {
                            //console.log(' here i got flag pay full ' + dObj.bPaidFull)
                            totalReceivedAs += dObj.totalPayAble
                        }


                    })
                    //set king and double room taken at the moment
                    setDoubleSuiteCnt (result.doubleSuiteCnt)
                    setKingSuiteCnt(result.kingSuiteCnt)
                    setMealsOnlyOrder(result.mealsOnlyOrder)
                    setAdultCnt(totalAdults)
                    //localStorage.set('adultCnt', totalAdults)
                    //localStorage.set('childrenCnt', totalChildren)
                    setChildrenCnt(totalChildren)
                    setTotalReceiveAble(totalReceiveAbleAs.toFixed(2))
                    setTotalReceived(totalReceivedAs.toFixed(2))
                    //find out balance
                    balanceAs = totalReceiveAbleAs - totalReceivedAs
                    setBalance(balanceAs.toFixed(2))
                    // total users and paid users
                    setTotalUsers(result.totalUsers) 
                    setPaidUsers(result.paidUsers)
                }
            }


        })
        */
    }, [])
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL"
    }
    const MyModalForm = (props) => {
        console.log('  i got row data = ' + props.rowData)
        //const dateInPast = (firstDate, secondDate) => firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0);

        let rowData = props.rowData
        //added function for form input change....?
        const [userid, setUserID] = useState(rowData.userid)
        const [fullName, setFullName] = useState(rowData.fullname)
        const [email, setEmail] = useState(rowData.email)
        const [phone, setPhone] = useState(rowData.phone)
        const [numAdult, setNumAdult] = useState(rowData.numAdult)
        const [numChildren, setNumChildren] = useState(rowData.numChildren)
        const [feeOpt, setFeeOpt] = useState(rowData.feeOpt)
        const [paidFull, setPaidFull] = useState(rowData.bPaidFull)
        const [totalPayAble, setTotalPayAble] = useState(rowData.totalPayAble)
        const [memoText, setMemoText] = useState(rowData.memoText)
        const [dependentList, setDependentList] = useState(rowData.dependantDataList)
        const [transactionId, setTransactionId] = useState(rowData.transactionId)
        const [dbResponse, setDbResponse] = useState('')

        const fullNameHanlder = event => {
            setFullName(event.target.value)
        }
        const emailHandler = event => {
            setEmail(event.target.value)
        }
        const numAdultHandler = event => {
            //let numAdult = event.target.value
            //console.log(' i got total num adult=' + numAdult)
            //localStorage.setItem('numAdult', numAdult)
            setNumAdult(event.target.value)
        }
        const numChildrenHandler = event => {
            setNumChildren(event.target.value)
        }
        const feeOptHandler = event => {
            setFeeOpt(event.target.value)
        }

        const paymentHandler = event => {
            //console.log(' On Pay Handler , here i have total adult ' + numAdult + ' total children ' + numChildren + ' fee Opt' + feeOpt)
            event.preventDefault()
            console.log('im clicking on update payment status')
            setPaidFull(event.target.value)
        }
        const updateHoiDongUserStatus = (event) => {
            event.preventDefault()
            console.log('\n full name =' + fullName + '\n phone=' + phone + '\n email=' + email +
                '\n feeOpt ' + feeOpt + '\n total adult ' + numAdult + '\n num children ' + numChildren + '\n total Payable' + totalPayAble + 'memoTxt '+memoText
            )
            const now = new Date()//.toLocaleDateString('en-US')
            console.log('time now ' + now)
            let numChildrenPay = 0
            let total_payable = 0.00
            const future = new Date('2023-05-01')
            const past = new Date('2023-01-01')
            console.log('time now ' + now)
            localStorage.setItem('totalPayAble', total_payable)

               //wrap up all data to update info
            const submitData = {
                
                
                userid: userid,
                fullName: fullName,
                phone: phone,
                email: email,
                //age: age,
                //gender: gender,
                //address: address,
                //city: city,
                //zipcode: zipcode,
                //churchName: churchName,
                //pastorName: pastorName,
                //numDependents: numDependents,
                dependantDataList: dependentList,
                feeOpt: feeOpt,
                numAdult: numAdult,
                numChildren: numChildren,
                totalPayAble:  totalPayAble,//localStorage.getItem('totalPayAble'),
                bPaidFull: paidFull,
                memoText: memoText,
                transactionId:transactionId

            }//end submit data 
            axios.post(useCtx.updateHDUser, submitData).then(
                response => {
                    console.log(response.data.status, response.data.message)
                    console.log(' i got ' + response.status)
                    if (response.status === 200) {
                        if (!response.data.status) {
                            console.log(' error in data saving: ' + response.data.message)
                            setDbResponse(response.data.message)

                        }
                        else {
                            setDbResponse(response.data.message)

                        }
                    }
                }
            ).catch(error => {
                console.log(' got error ' + error)
            })



        }//end updateHoiDongUserStatus
        const removeRegistration = (e) => {
            e.preventDefault()

            //const userid = props.rowData.userid 
            console.log(' neet to get user id  to remove from db = ' + userid)
            const submitData = {
                userid: userid
            }
            axios.post(useCtx.removeHDRegistrationUrl, submitData).then(
                response => {
                    console.log(response.data.status, response.data.message)
                    console.log(' i got ' + response.status)
                    if (response.status === 200) {
                        if (!response.data.status) {
                            console.log(' error in data saving: ' + response.data.message)
                            setDbResponse(response.data.message)

                        }
                        else {
                            setDbResponse(response.data.message)

                        }
                    }
                }
            ).catch(error => {
                console.log(' got error ' + error)
            })
        }//end remove registration func
       
     
    const refreshData = () => {
    		fetchData().then(() => {
                if (tableRef.current) {
		           console.log('im called to reload? ')
                  tableRef.current.forceUpdate(); // Assuming RDTC has a forceUpdate method
               }
         })
    	}//end refreshData
        return (
            <Popup contentStyle={{ width: '60%' , maxHeight:'600px', overflowY:'auto'}} trigger={<button className="button lead"><span style={{ color: 'red', fontSize: '0.8rem', fontWeight: 'bold' }}> Edit {rowData.fullname} </span></button>} modal closeOnDocumentClick onClose={
                 refreshData
            } >
                {close => (<div className='container'>

                    <div className='d-flex justify-content-end'><a className='close' onClick={close}>[&times;]</a></div>
                    <div className="card">
                        <div className="card-header text-primary">
                            <h5>User Data To Edit</h5>
                        </div>
                        <div className="card-body">
                            <Form onSubmit={updateHoiDongUserStatus}>
                                <h6>Primary User Info:</h6>
                                <Form.Control size='sm' type='text' value={fullName} onChange={fullNameHanlder} />
                                <Form.Control size='sm' type='text' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='phone number ' />
                                <Form.Control size='sm' type='text' value={email} placeholder='email' onChange={(e) => setEmail(e.target.value)} />

                                <h6>Room Booking Schedule:</h6>
                                <Form.Control as="select" className="rounded-0 shadow" onChange={feeOptHandler} size='sm'>
											<option className="d-none" value="{feeOpt}">
                                                {feeOpt}
										    </option>
										      {
										      ["KingSuite_1A", "KingSuite_2A", "KingSuite_4A" ,"KingSuite_2A2K","DoubleSuite_2A","DoubleSuite_4A","DoubleSuite_2A2K","DoubleSuite_4A2K","DoubleSuite_2A4K","MealsOnly"]
	                                           .map(option =>(
                                                <option key={option}>{option}</option>
                                              )) 
                                              }
                                    
                                </Form.Control>

                                <h6><br />Number of Adults & Children)</h6>

                                <Form.Control size='sm' type='text' value={numAdult} placeholder='no. of adults >11' onChange={numAdultHandler} />
                                <Form.Control size='sm' type='text' value={numChildren} placeholder='no. of children <= 11' onChange={numChildrenHandler} />
                                <h6>Dependent List (i.e john doe, brother,36,male  and seperated by pipe '|'' )</h6>
                                <Form.Control size='sm' type='text' value={dependentList} placeholder='list of dependents' onChange={(e) => setDependentList(e.target.value)} />

                                <h6>Total Payable:</h6>
                                <Form.Control size='sm' type='text' value={totalPayAble.toFixed(2)} placeholder='Total Payable' />
                                <br />
                                <h6>Payment Status</h6>

                                <select value={paidFull} onChange={paymentHandler} className='rounded-0 shadow'>
                                    <option value=''>Select Yes to update payment as paid full</option>
                                    <option value='1'>Yes</option>
                                    <option value='0'>No</option>
                                </select>
                                <h6><br />Transaction ID</h6>
                                <Form.Control size='sm' type='text' value={transactionId} placeholder='enter transaction id' onChange={(e) => setTransactionId(e.target.value)} />

                                <h6><br />Memo notes:</h6>
                                <Form.Control size='sm' type='text' value={memoText} placeholder='enter check number or Zelle Confirmation if available or any remarks on this rego' onChange={(e) => setMemoText(e.target.value)} />

                                <button className='btn btn-sm btn-primary' onClick={updateHoiDongUserStatus}>Update Registration Info</button> &nbsp; | &nbsp;
                                <button className='btn btn-sm btn-danger' onClick={removeRegistration}>Deleted This  Registration </button>



                            </Form>
                            <div className='text-info' style={{ fontSize: '0.8rem' }}>
                                <p className='text-danger'>{dbResponse ? dbResponse : ''}</p>
                                <h5>Summary for {fullName} / id={userid}</h5>
                                <ul>

                                    <li>Fee Option Per Accomodation Method: {feeOpt}</li>
                                    <li>Email: {email}</li>
                                    <li>No. Of Adult : {numAdult}</li>
                                    <li>No. Of Children: {numChildren}</li>
                                    <li>Total Payable:  {totalPayAble.toFixed(2)}</li>
                                    <li> Paid full ? {paidFull ? 'Yes' : 'No'} </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                )}

            </Popup>
        )
    }//end my modal form

    //click on row to edit and resubmit to update the data row
    const rowHandler = row => {
        console.log(' im selected and row ' + JSON.stringify(row) + '\n or row.fullname' + row.fullname)
        setRowData(row)
        setFormOn(true)

    }
    
    const history = useHistory()
    const handleLogout=()=>{
	     //
	     
	     history.push("/hoidong2024/register")
    }
    const doubleroomCnt=102
    const kingroomCnt=66
    return (
        <>
            <DataTableExtensions
                 ref={tableRef}
                columns={columns}
                data={dataTable}
                print={false}
                export={false}

            >
                <DataTable
                    title='Camper Listing - Danh Sach Tin huu tham du HDGH 49'
                    noHeader

                    highlightOnHover
                    dense={true}
                    pagination
                    
                    onRowClicked={rowHandler}
                    paginationComponentOptions={paginationComponentOptions}

                />
            </DataTableExtensions>
            <div className='row'>
                <div className='col'>{formOn ? <MyModalForm rowData={rowData} /> : ''}</div>
                <div className='col-6' style={{ fontSize: '1.5rem' }}>
                    <h6><strong>Summary of Total Registered Attendance</strong></h6>
                    <h6><strong>Accomodation:</strong></h6>
                    <ul className='list-inline'>
                        <li className='list-inline-item'>King Suite Taken={kingSuiteCnt} <span style={{ color: 'red' }}>(Vacancies ={kingroomCnt - kingSuiteCnt})</span></li>
                        <li className='list-inline-item'>Double Suite Taken={doubleSuiteCnt} <span style={{ color: 'red' }}>(Vacancies ={doubleroomCnt - doubleSuiteCnt})</span></li>
                        <li className='list-inline-item'>Number Meals Order={mealsOnlyOrder} <span style={{ color: 'red' }}>One meal order can have many meal tickets, please check the order specific for ticket number</span></li>
                        

                    </ul>
                   
                    <h6><strong>Attendees: (Note that this statistic based on number registered, the actual attendees may be different
                    )</strong></h6>
                    <ul className='list-inline'>
                        <li className='list-inline-item'>Adults={adultCnt} ({'>'}=13 years old  )</li>
                        <li className='list-inline-item'>Children={childrenCnt} ({'<'}=12 years old)</li>

                    </ul>
                    <h6><strong>Payment: </strong></h6>
                    <ul className='list-inline'>
                        <li className='list-inline-item'>Total  'paid full' registrants  = {paidUsers}</li>
                        <li className='list-inline-item'>Total  'unpaid'' registrants = {totalUsers - paidUsers}</li>
                        
                        <li className='list-inline-item'>Total ReceiveAble (expected) = ${totalReceiveAble}</li>
                        <li className='list-inline-item' style={{ color: 'green' }}>Total Received (in hand) = ${totalReceived}</li>

                    </ul>

                    <p> -------------------------------------------------</p>
                    <p style={{ color: 'red' }}>Balance To Receive = ${balance}</p>
                    <ul className='list-inline'>
                        <li className='list-inline-item'><a href='/hoidong2024/register'>Create Registration</a></li>


                    </ul>
                    
                       <HoiDongDataExport />
                       
                    
                      
                       
                    
                    
                </div>
                <div className='col'> </div>
            </div>
            
            <div className='row'>
                  <div className='col'> <EmailBot/></div>
                  
            </div>
            
            
            <div className='row'>
                      <p>Room Assignment by Hotel - click on primary registrant to update the room number</p>
                        
                  <div className='col'> <HoiDongRoomList/></div>
                  <button className='btn btn-sm btn-primary' onClick={handleLogout}>Logout</button>
            </div>
            
        </>
    )
}
//expandableRows

//expandableRowsComponent={ExpandedComponent}

const UserListWithLogin = () =>{
	//const [bLoggedIn, setBloggedIn]= useState(0)
	const [username, setUsername] = useState('');
	 const [password, setPassword] = useState('');
  	const [isLoggedIn, setIsLoggedIn] = useState(false);
  	const [errorMessage, setErrorMessage] = useState('');
     const handleUsernameChange = (event) => {
		    setUsername(event.target.value);
		  };
		
		  const handlePasswordChange = (event) => {
		    setPassword(event.target.value);
		  };
		
		  const handleSubmit = async (event) => {
		    event.preventDefault();
		
		    // Simulate API call for illustrative purposes (replace with actual API call)
		    const response = await fetch('https://api.loichua.church:5001/main/hdlogin2024', {
		      method: 'POST',
		      headers: { 'Content-Type': 'application/json' },
		      body: JSON.stringify({ username, password }),
		    });
		     setIsLoggedIn(false)
		     console.log('resp from backend '+JSON.stringify(response))
		     
		     if (response.ok) {
			   console.log(' im in with response ' + JSON.stringify(response))  
			   if(!response.body){
				 console.log('empty body resp received')
			   }
			   else{
				const resp_data = await response.json()
				console.log('now im here buddy ' + JSON.stringify(resp_data))
				if(resp_data.status=='Failed'){
					console.error('failed login as ', resp_data.message)
                    setErrorMessage(resp_data.message); // Clear any previous error messages
					
				}
				else{
					console.log(' im ok with login with message ' + resp_data.message)
					setIsLoggedIn(true)
					localStorage.setItem('localLoggedInSession','1')
				}
			   }
		       //setIsLoggedIn(true);
		       //setErrorMessage(''); // Clear any previous error messages
		    } else {
		      const errorData = await response.json();
		      setErrorMessage(errorData.message || 'Login failed.');
		    }
		  }
			
	
	
	
	if (isLoggedIn || localStorage.getItem('localLoggedInSession')==='1'){
	   console.log(' i have lgin = ' + isLoggedIn)
	   //localStorage.setItem('bLoginCookie',1)
       return (
        <div className='container'>
            <MyComponent />
            
        </div>
    )
    }
    else{//not login then return the component below
	   //logic need and may make it whole component
	  
		    
			  
			  return(
			  
			  <div className="login-container">
		      
		      
		      <form onSubmit={handleSubmit}>
		        <label htmlFor="username">Username:</label>
		        <input
		          type="text"
		          id="username"
		          value={username}
		          placeHolder='Enter username'
		          onChange={handleUsernameChange}
		          required
		        />
		        <label htmlFor="password">Password:</label>
		        <input
		          type="password"
		          id="password"
		          value={password}
		          placeHolder='Enter password'
		          onChange={handlePasswordChange}
		          required
		        />
		        <button type="submit">Login</button>
		      </form>
		          {errorMessage?<p style={{color:'red'}}>{errorMessage}</p>:''} 
		    </div>
			  
			  )
			  
		}//end else if not login ok
}
export default UserListWithLogin