import React ,{ useState, useEffect } from 'react'
import './login.module.css'
import { useForm } from 'react-hook-form'
//import UseFetch from './useFetch'
import axios from 'axios'
import Protected from '../protected'
const LoginForm = () => {
    const {
        register,
        handleSubmit,
       // watch,
        formState: { errors }
    } = useForm();
     const [userData,setUserData]=useState({})
     const [login,setLogin] = useState(false)
     const [error, setError]=useState('')
    const onSubmit = (data) => {
        console.log(data);
        const user_login = data.username
        const user_password = data.password
        
          //const getWpApiLogin = "https://thuvien.loichua.church/api/users/login/?user_login="+user_login+"&user_password="+user_password 
          const getWpApiLogin='https://api.loichua.church:5001/main/login-action'
          setUserData({user_login:user_login , user_password:user_password, wpLoginUrl:getWpApiLogin})
         //const callbackData = UseFetch(getWpApiLogin)
        //console.log(' i have callback data ' + callbackData)


    }; // your form submit function which will invoke after successful validation
    
    //console.log(watch("username")); // you can watch individual input by pass the name of the input
    
    
    useEffect(()=>{
		  //console.log('frontend: i got user data ' + JSON.stringify(userData))
		  const headers = {
                headers: { 
					'Content-Type': 'application/json',
					'Access-Controll-Allow-Origin':'*'  
					}
            };
	      axios.post(userData.wpLoginUrl, userData,headers).then( 
			  resp=>{
				   console.log('got resp from backend status' + JSON.stringify(resp) + 'resp.data.status='+resp.data.status) 
				  
				  if(resp.data.status==='error'|| resp.data.status==='false'){
					  console.log('*** error login with message '+ resp.data.message)
					  setError(resp.data.message)
					  
				  }
				  else{
					  console.log(' pass case? status='+ resp.status)
					  setLogin(true)
					  localStorage.setItem('bLoginCookie',true)
				      console.log('here i got login state ' +login + ' and cookie=' + localStorage.getItem('bLoginCookie'))
					  
				  }
			  }).catch(error=>{
				  console.log(error)
			  })
	        
	},[userData,login])
	
	if (!login){
    return (
        <div className='container'>
           
			   
			  <h3>Login Form</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* register your input into the hook by invoking the "register" function */}
                <input  {...register("username", { required: true })}  placeholder='username'/>
                {errors.username && <p className='login-warning'>Vui lòng điền vào </p>}
                {/* include validation with required or other standard HTML validation rules */}
                <input {...register("password", { required: true  })} type='password' placeHolder='password' />
                {/* errors will return when field validation fails  */}
                {errors.password && <p className='login-warning'>Vui lòng điền vào </p>}

                <input type="submit" />
            </form>
             <p className='text-danger' style={{color:'red', fontSize:'1.4rem'}}>{error? error : ''}</p> 
		   
          
        </div>
     )
    }
    else{
		return(
		   <> 
		     <Protected bLogin={login}/>
		   </>	
		)
		
	}
}
export default LoginForm