import React from 'react'
import Navbar from '../christmas/navbar'

const CalendarComp = (props) => {

    return (
        <>
            <div className='container'>
                <h3>Lich Sinh Hoạt HTLC</h3>
                {props.showNavBar ? <Navbar /> : ''}


                {/*<iframe title='lich sinh hoat HTLC 2023' src="https://calendar.google.com/calendar/embed?src=loichua.church%40gmail.com&ctz=America%2FChicago&mode=AGENDA" style={{ border: "0" }} width="100%" height="600" frameborder="0" scrolling="no"></iframe>
                 */}
                <iframe title='lich sinh hoat HTLC 2023' src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FChicago&mode=AGENDA&src=bG9pY2h1YS5jaHVyY2hAZ21haWwuY29t&color=%23039BE5" style={{ border: '0' }} width="100%" height="600" frameborder="0" scrolling="no"></iframe>
                <p className='text-center'><a href='/'>Home</a></p>
            </div>
        </>
    )
}
export default CalendarComp