import React, { useState, useContext } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import AuthContext from '../store/auth'

const TraiHeDataExport = () => {
    const useCtx = useContext(AuthContext)
    const [churchName, setChurchName] = useState('')
    const [downloadLink, setDownloadLink] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    //form action here
    const getCamperData = (event) => {
        event.preventDefault()
        console.log('im clicked on submission with event  ' + churchName)
        if (churchName === '' || churchName === 'null') {
            setErrorMessage('Please select a church')
            return

        }

        axios.post(useCtx.getCampDataBy, { churchName: churchName }).then(response => {
            console.log(' i got response ' + response.data.status + ' message ' + response.data.message)
            setErrorMessage('')
            if (response.status === 200) {
                if (response.data.downloadFile) {
                    console.log('i got download file as ' + response.data.downloadFile)
                    //let downloadLink=response.data.downloadFile//'https://api.loichua.church:5001/main/downloadfile/'+response.data.downloadFile 
                    let downloadLink = useCtx.getCsvCampDataUrl + '/' + response.data.downloadFile
                    console.log(' now full link to download file ' + downloadLink)
                    setDownloadLink(downloadLink)
                }
            }//end resp status=200
        })

    }
    /*
    const getCsvFile = (event) => {
        event.preventDefault()
        console.log(' im clicked on download btn and now i have link ' + downloadLink)
        let dataSubmitted = { link: downloadLink }
        axios.post('https://api.loichua.church:5001/main/downloadfile/' + downloadLink)
    }
    */
    return (
        <div className='container'>
            <div className='row'>
                <div className='col'></div>
                <div className='col-6'>
                    <Form>
                        <Row>
                            <Col>
                                <p>&nbsp;</p>
                                <h6>Export Data By Church</h6>
                                <Form.Control as="select" className="rounded-0 shadow" onChange={(e) => setChurchName(e.target.value)}>
                                    <option className="d-none" value="{churchName}">
                                        Select Church From List
																</option>
                                    {["HT Loi Chua Houston-TX", "HT Austin-TX", "HT Kileen-TX", "HT Praire Dallas-TX", "HT Thi Thien Dallas-TX", "HT Cuu An Houston-TX", "HT Alief", "HT An Dien Houston-TX", "HT Amarillo TX", "Other", "All"].map(option => (
                                        <option key={option}>{option}</option>
                                    ))}
                                </Form.Control>
                                <button type="submit" size='sm' className='btn btn-alert' onClick={getCamperData}>Submit</button>
                                <br />

                                <a href={downloadLink ? downloadLink : ''}> {downloadLink ? 'Download' : ''}</a>
                                <p className='text-danger'>{errorMessage ? errorMessage : ''}</p>

                            </Col>

                        </Row>


                    </Form>
                </div>
                <div className='col'></div>
            </div>
        </div>

    )
}

export default TraiHeDataExport