
import React from 'react';
const styleHere={
    fontSize:'0.2rem',

};
const SmallTeamFooter = (props) => {

    return (
        <div class="footer__lower background-off-white pad-t-sm-2 pad-b-sm-2">

            <div class="container">

                <div class="row">

                    <div class="flex-md-6 flex-lg-4">
                        <p class="copyright">LoiChua Social Media - Version 1.0</p>
                    </div>
                    <div class="flex-md-6 flex-lg-4 text-center-lg">

                        <nav class="footer__nav">
                            <ul class="display-flex justify-content-between" style={styleHere}>
                                
                                <li><a href="/privacy">Privacy policy</a></li>
                                <li><a href="/about">About</a></li>
                                <li><a href="/contact">Contact</a></li>
                            </ul>
                        </nav>
                    </div>
                    <div class="flex-md-12 flex-lg-4 mar-t-md-2 mar-t-lg-0 text-right-lg">
                        <p>LoiChua Social Media Team <span class="bold underline">2021</span></p>
                        <div class="sharethis-inline-share-buttons"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SmallTeamFooter;