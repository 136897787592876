import React from 'react';
import './BannerEventSingle.css'
const BannerPhatThanhTinLanh = (props) => {

    return (
        <React.Fragment>
            <section class="banner single-banner-event full-width">

                <div class="container">

                    <div class="banner__content">

                        <div class="section-heading">
                            <span class="section-intro">Vietnamese Christian Broadcast - Phát Thanh Tin Lành</span>
                            <h1>Mời bạn lắng nghe phát thanh Tin Lành được cập nhật mỗi tuần!</h1>
                        </div>
                        <div class="breadcrumb">
                            <div class="breadcrumb__home--link"><a href="/">Home</a></div>
                            <span>/</span>
                            <div class="breadcrumb__home--link"><a href="/phat-thanh-tin-lanh-houston-texas">Media</a></div>
                            <span>/</span>
                            <div class="breadcrumb__current--page-link">Thanksgiving</div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default BannerPhatThanhTinLanh;