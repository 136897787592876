import React, { useContext } from 'react';
import Welcome from './Welcome';
import VideoBackground from './VideoBackGround';
import YouTubeEmbed from './YouTubeEmbed';
import AuthContext from './store/auth';
import mystyle from './css/loichuastyle.module.css';
import PhatThanhTinLanh from './PhatThanhTinLanh';
const MainSection = (props) => {
    const useCtx = useContext(AuthContext);
    return (
        <React.Fragment>
            <main>

                <VideoBackground intro='Jeus is our Lord' greeting='Connect with us. Join us this Sunday' learnmore='Find out more' />

                <section class="upcoming-events">

                    <div class="upcoming-events__content">

                        <div class="row no-gutter">

                            <div class="flex-md-6 flex-lg-3">

                                <div class="upcoming-intro background-secondary">

                                    <div class="section-heading">
                                        <span class="section-intro">Thanksgiving & Fall Festival</span>
                                        <h2>Our upcoming events</h2>

                                    </div>

                                    <a href="/event" class="button button--primary button--fill">More details...</a>

                                </div>

                            </div>
                            <div class="flex-md-6 flex-lg-3">

                                <div class="card events">

                                    <div class="card__body" className={mystyle.card_font}>

                                        <div class="label uppercase">Join</div>

                                        <div class="event__details mar-t-sm-2 mar-b-sm-2">

                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-calendar-line"></i></span>
                                                <span class="text">Every Sunday</span>
                                            </div>
                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-time-line"></i></span>
                                                <span class="text">10:30AM - 12:00PM</span>
                                            </div>
                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-map-pin-line"></i></span>
                                                <span class="text">{props.churchaddress}</span>
                                            </div>
                                        </div>
                                        <h3 class="event__tile">Sunday Service</h3>

                                    </div>
                                    <div class="card__footer">

                                        <div class="card__cta">
                                            <a href="/about" class="button button--primary button--fill">Learn more</a>
                                        </div>
                                        <div class="card__share text-right">
                                            <button class="transparent button button--primary button--outline share-button"><i
                                                class="ri-share-line"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-md-6 flex-lg-3">

                                <div class="card events">

                                    <div class="card__body" className={mystyle.card_font}>

                                        <div class="label uppercase">Join</div>

                                        <div class="event__details mar-t-sm-2 mar-b-sm-2">

                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-calendar-line"></i></span>
                                                <span class="text">Every Sunday</span>
                                            </div>
                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-time-line"></i></span>
                                                <span class="text">11:00AM-12:00PM</span>
                                            </div>
                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-map-pin-line"></i></span>
                                                <span class="text">{props.churchaddress}</span>
                                            </div>
                                        </div>
                                        <h3 class="event__tile">YEM's Ministry</h3>

                                    </div>
                                    <div class="card__footer">

                                        <div class="card__cta">
                                            <a href="/yem" class="button button--primary button--fill">Learn more</a>
                                        </div>
                                        <div class="card__share text-right">
                                            <button class="transparent button button--primary button--outline share-button"><i
                                                class="ri-share-line"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-md-6 flex-lg-3">

                                <div class="card events">

                                    <div class="card__body" className={mystyle.card_font}>

                                        <div class="label uppercase">JOIN</div>

                                        <div class="event__details mar-t-sm-2 mar-b-sm-2">

                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-calendar-line"></i></span>
                                                <span class="text">Every Sunday</span>
                                            </div>
                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-time-line"></i></span>
                                                <span class="text">11:45 AM - 12:45PM</span>
                                            </div>
                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-map-pin-line"></i></span>
                                                <span class="text">{props.churchaddress}</span>
                                            </div>
                                        </div>
                                        <h3 class="event__tile">Children's Minstry</h3>

                                    </div>
                                    <div class="card__footer">

                                        <div class="card__cta">
                                            <a href="/children-ministry" class="button button--primary button--fill">Learn more</a>
                                        </div>

                                        <div class="card__share text-right">
                                            <button class="transparent button button--primary button--outline share-button"><i
                                                class="ri-share-line"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Welcome />

                <section class="recent-sermon default-section-spacing background-off-white">

                    <div class="recent-sermon__content">

                        <div class="container">

                            <div class="row mar-b-sm-4">

                                <div class="flex-md-8 flex-lg-9">
                                    <div class="section-heading mar-b-sm-0">
                                        <span class="section-intro">Sermons</span>
                                        <h2>Recent sermons</h2>
                                    </div>
                                </div>
                                <div class="flex-md-4 flex-lg-3 text-right-md">
                                    <a href="/video-list" class="button button--primary button--fill">View all sermons</a>
                                </div>

                            </div>

                            <div class="row">

                                <div class="flex-md-6 flex-lg-10">

                                    <div class="card sermon">


                                        <div class="card__body">

                                            <h3 class="sermon__tile mar-b-sm-2">&nbsp;</h3>

                                            <YouTubeEmbed embedId='h9oaqVokf8w' />
                                        </div>

                                        <div class="card__footer">

                                            <div class="card__cta">
                                                <a href={useCtx.youtubeURL} class="button button--primary button--fill">More at our YouTube Channel</a>
                                            </div>
                                            <div class="card__share text-right-sm">
                                                <button class="transparent button button--primary button--outline share-button"><i
                                                    class="ri-share-line"></i></button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="testimonial default-section-spacing text-center-sm">

                    <div class="container">

                        <div class="section-heading">
                            <span class="section-intro"><a href='/read-dailyhope'>Daily Hope</a></span>
                            <h2>Phát Thanh Tin Lành Mùa Tạ Ơn 2021</h2>
                             <PhatThanhTinLanh />
                             
                        </div>
                        <div id="testimonial-carousel" class="testimonial__carousel owl-carousel">

                            <div class="testimonial__element">

                                <div class="testimonial__picture">

                                    <div class="avatar avatar--large">
                                        <img src="assets/images/avatar-1.png" alt="" />
                                    </div>

                                </div>
                                <div class="testimonial__text">
                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio temporibus vitae architecto at
                                    harum hic autem facilis veniam. Adipisci, quibusdam. Tempore quasi eum incidunt asperiores illo
                  consequatur perspiciatis, laboriosam ipsam, doloribus enim repellendus quo dolorum.</p>
                                </div>
                                <div class="testimonial__author bold leading">Jackie B. Venn</div>

                            </div>
                            <div class="testimonial__element">

                                <div class="testimonial__picture">

                                    <div class="avatar avatar--large">
                                        <img src="assets/images/avatar-4.png" alt="" />
                                    </div>

                                </div>
                                <div class="testimonial__text">
                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio temporibus vitae architecto at
                                    harum hic autem facilis veniam. Adipisci, quibusdam. Tempore quasi eum incidunt asperiores illo
                  consequatur perspiciatis, laboriosam ipsam, doloribus enim repellendus quo dolorum.</p>
                                </div>

                                <div class="testimonial__author bold leading">John Doe</div>

                            </div>
                            <div class="testimonial__element">

                                <div class="testimonial__picture">

                                    <div class="avatar avatar--large">
                                        <img src="assets/images/avatar-6.png" alt="" />
                                    </div>

                                </div>
                                <div class="testimonial__text">
                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio temporibus vitae architecto at
                                    harum hic autem facilis veniam. Adipisci, quibusdam. Tempore quasi eum incidunt asperiores illo
                  consequatur perspiciatis, laboriosam ipsam, doloribus enim repellendus quo dolorum.</p>
                                </div>

                                <div class="testimonial__author bold leading">H. Hyuas</div>

                            </div>
                        </div>
                    </div>
                </section>
                <div class="separator background-primary"></div>


                <section class="ministries default-section-spacing">

                    <div class="ministries__content">

                        <div class="container">

                            <div class="row mar-b-sm-4">

                                <div class="flex-md-8 flex-lg-9">
                                    <div class="section-heading mar-b-sm-0">
                                        <span class="section-intro">Ministries</span>
                                        <h2>Some of our ministries</h2>
                                    </div>
                                </div>
                                <div class="flex-md-4 flex-lg-3 text-right-md">
                                    <a href="ministries-layout-1.html" class="button button--primary button--fill">View all ministries</a>
                                </div>
                            </div>

                            <div class="row">

                                <div class="flex-md-6 flex-lg-4">

                                    <div class="card ministry">

                                        <div class="card__header">

                                            <img src="assets/images-cus/yem-meeting.jpeg" alt="YEM - Youth activities at church Tinlanh " class="card__image" />

                                            <div class="card__details">

                                                <div class="ministry__details">

                                                    <div class="icon-text">
                                                        <span class="icon"><i class="ri-group-line"></i></span>
                                                        <span class="text color-off-white"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card__body">

                                            <h3 class="ministry__tile mar-b-sm-2">YEM's ministry</h3>
                                            <p class="excerpt" className={mystyle.card_font}>
                                                <p>Welcome to the Youth & English Ministry at Houston Vietnamese Christian Church! We are a united family Living In Fellowship Everyday with our Creator and community of believers. In all our ministries, we seek to glorify God and share the Gospel of Jesus Christ!
                            Please join us in our praise, prayer, and proclamation that Jesus Christ is Lord!</p>
                                            </p>

                                        </div>
                                        <div class="card__footer">

                                            <div class="card__cta">
                                                <a href="ministries-single.html" class="button button--primary button--fill">Learn more</a>
                                            </div>

                                            <div class="card__share text-right-sm">
                                                <button class="transparent button button--primary button--outline share-button"><i
                                                    class="ri-share-line"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex-md-6 flex-lg-4">

                                    <div class="card ministry" >

                                        <div class="card__header">

                                            <img src="assets/images-cus/children-choir.jpeg" alt="" class="card__image" />

                                            <div class="card__details">

                                                <div class="ministry__details">

                                                    <div class="icon-text">
                                                        <span class="icon"><i class="ri-group-line"></i></span>
                                                        <span class="text color-off-white"> {/*12 podcasters */}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card__body" >

                                            <h3 class="ministry__tile mar-b-sm-2">Children's ministry </h3>
                                            <p class="excerpt" className={mystyle.card_font}>
                                                The Children's Ministry is a place where children aged 3 to 12 can play, craft
                                                and learn about God's Word in the Bible. A healthy environment for children
                                                to be nurtured and raised in God's love. We invite you to bring your children to us.
                                            </p>

                                        </div>
                                        <div class="card__footer">

                                            <div class="card__cta">
                                                <a href="ministries-single.html" class="button button--primary button--fill">Learn more</a>
                                            </div>
                                            <div class="card__share text-right-sm">
                                                <button class="transparent button button--primary button--outline share-button"><i
                                                    class="ri-share-line"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex-md-12 flex-lg-4 mar-t-md-2 mar-t-lg-0">

                                    <div class="card ministry">

                                        <div class="card__header">

                                            <img src="assets/images-cus/loichua-adult-choir.jpeg" alt="" class="card__image" />

                                            <div class="card__details">

                                                <div class="ministry__details">

                                                    <div class="icon-text">
                                                        <span class="icon"><i class="ri-group-line"></i></span>
                                                        <span class="text color-off-white"></span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="card__body">

                                            <h3 class="ministry__tile mar-b-sm-2">LoiChua's Adult Choir </h3>
                                            <p class="excerpt" className={mystyle.card_font}>
                                                We are pleased to welcome all new members to join our Adult Choir.  We are sure your skills and voices
                                                can help the worship lively and praise our Lord.
                                                <br />
                                                We now recruit members for Thanksgiving and Christmas provgram.
                                            </p>

                                        </div>

                                        <div class="card__footer">

                                            <div class="card__cta">
                                                <a href="coming-up" class="button button--primary button--fill">Learn more</a>
                                            </div>

                                            <div class="card__share text-right-sm">
                                                <button class="transparent button button--primary button--outline share-button"><i
                                                    class="ri-share-line"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="podcast-cta pad-t-sm-6 pad-t-lg-8 pad-b-sm-6 pad-b-lg-8">

                    <div class="container">

                        <div class="row text-center-sm text-left-lg align-items-center">

                            <div class="flex-lg-9">
                                <h2 class="color-off-white">Watch our YouTube Channel from your phone or anywhere with our weekly worship services
                                </h2>
                            </div>
                            <div class="flex-lg-3 text-right-lg">
                                <a href={useCtx.youtubeURL} class="button button--outline button--off-white">Find out more at our YouTube Channel</a>
                            </div>

                        </div>
                    </div>
                </section>

                <div class="combine-section">

                    <section class="staffs default-section-spacing text-center-sm">

                        <div class="staffs__content">

                            <div class="container">

                                <div class="section-heading">
                                    <span class="section-intro">Staffs</span>
                                    <h2>Some of our leaders</h2>
                                </div>
                                <div class="row">

                                    <div class="flex-md-6 flex-lg-3">

                                        <div class="card staff">

                                            <div class="card__header no-overlay">

                                                <img src="assets/images-cus/ms-le-thuan-2.jpg" alt="" class="card__image" />

                                            </div>
                                            <div class="card__body">

                                                <h3 class="cause__tile mar-b-sm-2">Pastor Le Phuoc Thuan</h3>

                                                <p>Senior pastor</p>

                                            </div>
                                            <div class="card__footer justify-align-center">

                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-facebook-line"></i></a>
                                                </div>
                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-linkedin-line"></i></a>
                                                </div>
                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-twitter-line"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex-md-6 flex-lg-3">

                                        <div class="card staff">

                                            <div class="card__header no-overlay">

                                                <img src="assets/images-cus/pastor-ngon-bui.jpg" alt="" class="card__image" />

                                            </div>
                                            <div class="card__body">
                                                <h3 class="cause__tile mar-b-sm-2">Pastor Bui Trung Ngon</h3>
                                                <p>Associate Pastor</p>
                                            </div>

                                            <div class="card__footer justify-align-center">

                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-facebook-line"></i></a>
                                                </div>
                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-linkedin-line"></i></a>
                                                </div>
                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-twitter-line"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-md-12 flex-lg-3 mar-t-md-2 mar-t-lg-0">

                                        <div class="card staff">

                                            <div class="card__header no-overlay">

                                                <img src="assets/images-cus/pastor-sang.jpg" alt="" class="card__image" />

                                            </div>
                                            <div class="card__body">

                                                <h3 class="cause__tile mar-b-sm-2">Pastor Tran Sang</h3>

                                                <p>Associate Pastor</p>

                                            </div>
                                            <div class="card__footer justify-align-center">

                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-facebook-line"></i></a>
                                                </div>
                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-linkedin-line"></i></a>
                                                </div>

                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-twitter-line here"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Added pastor Huyen */}
                                    <div class="flex-md-12 flex-lg-3 mar-t-md-2 mar-t-lg-0">

                                        <div class="card staff">

                                            <div class="card__header no-overlay">

                                                <img src="assets/images-cus/ms-huyen.jpeg" alt="" class="card__image" />

                                            </div>
                                            <div class="card__body">

                                                <h3 class="cause__tile mar-b-sm-2">Pastor Pham Van Huyen</h3>

                                                <p>Associate Pastor</p>

                                            </div>
                                            <div class="card__footer justify-align-center">

                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-facebook-line"></i></a>
                                                </div>
                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-linkedin-line"></i></a>
                                                </div>

                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-twitter-line here"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="decorative-bg"></div>
                    <section class="default-section-spacing">

                    </section>


                </div>

            </main>
        </React.Fragment>
    )
};

export default MainSection;