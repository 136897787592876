import React from 'react';
/**
 * SEE scss for styling of this block comp
 *  
 */
import './FooterSimple.css'
//import AuthContext from './store/auth';
const FooterForYEM = (props) => {
    //const authCtx = useContext(AuthContext);
    return (
        <React.Fragment>
            <footer class="footer">

                <div class="footer__upper">

                    <div class="time-location">

                        <div class="prayer-request default-section-spacing text-center-sm">

                            <div class="container">

                                <div class="row justify-content-end">

                                    <div class="flex-lg-8">
                                        <h2 class="mar-b-sm-2">{props.footerMessage}
                                        </h2>
                                        <a href="/contact" class="button button--primary button--fill">{props.callActionTxt ? props.callActionTxt : 'Request a prayer'}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="footer__lower background-off-white pad-t-sm-2 pad-b-sm-2">

                    <div class="container">

                        <div class="row">

                            <div class="flex-md-6 flex-lg-4">
                                <p class="copyright">LoiChua Social Media - Version 1.0</p>
                            </div>
                            <div class="flex-md-6 flex-lg-4 text-center-lg">

                                <nav class="footer__nav">
                                    <ul class="display-flex justify-content-between">
                                        <li><a href="/">Terms of Use</a></li>
                                        <li><a href="/">Privacy policy</a></li>
                                        
                                        <li><a href="/contact">Contact</a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="flex-md-12 flex-lg-4 mar-t-md-2 mar-t-lg-0 text-right-lg">
                                <p>LoiChua Social Media Team <span class="bold underline">2021</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>



        </React.Fragment>
    )
}
export default FooterForYEM;