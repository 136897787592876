import "./VideoCarousel.css";
import React from "react";
//import { Carousel } from "react-bootstrap";
//import ReactPlayer from "react-player";
import "bootstrap/dist/css/bootstrap.css";
import CarouselVideoComp from "./CarouselVideoComp";

const VideoCarousel = () => {
	//define array of object with list of video as follows then we can have slide show of videos
  const videoList = [
	/*
	{
      id: 1,
      title: "You are invited to Christmas Eve Service",
      src: "https://www.youtube.com/watch?v=loKKuhus_ls",
      credit: "Video by  Social Media Team HTLC",
    },
	{
      id: 2,
      title: "Welcome",
      src: "https://www.youtube.com/watch?v=ggGRengEvtY",
      credit: "Video by  Social Media Team HTLC",
    },
    
    {
      id: 3,
      title: "Lễ Tạ Ơn 2022",
      src: "https://www.youtube.com/watch?v=kOwl9QOIn48",
	  
	  credit: "Video by  Social Media Team HTLC",
	},
	*/
	{
      id: 4,
      title: "Ca Đoàn Lời Chúa - Duy Khải - Thanh Liên",
      src:"https://www.youtube.com/playlist?list=PLtYuXPAPKZ5JqRS9P0YvDuhgVkJ8ojxvE",
      //src: "https://www.youtube.com/watch?v=vUkMeECsoPI",
	  credit: "",
	},
	/*
	https://youtu.be/vUkMeECsoPI
    { 
      id: 3,
      title: "Video 3",
      src: Vid3,
      credit: "Video by cottonbro from Pexels",
	},
	*/
  ];

  return (
    <div className="App">
      <CarouselVideoComp videoArray={videoList} />
    </div>
  );
};

export default VideoCarousel;