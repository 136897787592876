import React from 'react';

const ThoiBieuSinhHoat = (props) => {

    return (
        <>
            <iframe title="thoi bieu sinh hoat htloichua" src="https://docs.google.com/document/d/1xwrQo1TFEaK1mk37syIihdqm9gONSxUn/edit?usp=sharing&amp;ouid=104143288978923424291&amp;rtpof=true&amp;sd=true" width="100%" height="900"></iframe>
        </>
    )
}

export default ThoiBieuSinhHoat;