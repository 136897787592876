import React from 'react';
import TaiLieuSuuTam from './TaiLieuSuuTam';
import BannerPhatThanhTinLanh from './BannerPhatThanhTinLanh';
import SmallTeamFooter from './SmallTeamFooter';
import SiteHeaderWithSlide from './SiteHeaderWithSlide';

const TaiLieuSuuTamContainer = () => {

    return (
        <>
            <SiteHeaderWithSlide />
            <main>
                <BannerPhatThanhTinLanh />
                <TaiLieuSuuTam />

            </main>
            <SmallTeamFooter />
        </>
    )
}
export default TaiLieuSuuTamContainer;