import React from 'react';
import './BannerAbout.css';
const BannerMissionary = () => {
    return (
        <>
            <section class="banner full-width">

                <div class="container">

                    <div class="banner__content">

                        <div class="section-heading">
                            <span class="section-intro">Hội Thánh Tin Lành Lời Chúa </span>
                            <h1>Trang Truyền Giáo </h1>
                        </div>
                        <div class="breadcrumb">
                            <div class="breadcrumb__home--link"><a href="/">Home</a></div>
                            <span>/</span>
                            <div class="breadcrumb__current--page-link">About</div>
                        </div>
                    </div>
                </div>

            </section>

        </>
    )
}

export default BannerMissionary; 