import React ,{useContext} from 'react'
import useFetch from'./useFetch'
import Navbar from '../christmas/navbar'
import AuthContext from '../store/auth'
import CommonFooter from '../christmas/common_footer'
const PostHolder =(props)=>{
     
    return(
        
       <div className="clearfix">
       <img src={props.data.yoast_head_json.og_image[0].url} className="col-md-6 float-md-end mb-3 ms-md-3 img-fluid" alt={props.data.yoast_head_json.title} />
       <h3><a href={props.data.link} target='new'>{props.data.title.rendered}</a></h3>
       <p className='lead text-right' dangerouslySetInnerHTML={{__html: props.data.excerpt.rendered}}></p>
       <p><a href={props.data.link} target='new' className='lead'><b>...đọc thêm</b></a></p>

      </div>
    
   
    )

}

const GrabPosts=()=>{
   const authConfig = useContext(AuthContext); 
    const posts = useFetch('https://thuvien.loichua.church/wp-json/wp/v2/posts')
   //console.log(' got posts ' + JSON.stringify(posts))
   //dangerouslySetInnerHTML={{__html: this.props.post.title.rendered}}
   return(
       <>
       <div className='container'>
                        <Navbar />
                       
      </div>
      
       <div className='container'>
        { posts && posts.map((post,index)=>(
           
             <PostHolder data={post} />

         ))}
   	  
        </div>
       <CommonFooter  authConfig={authConfig} />
        </>
   	)
}

export default GrabPosts
