import React from 'react'
//import PDFViewer from 'pdf-viewer-reactjs'

const PDFViewerComp = () => {
    return (

        <p>not used</p>
    )
}

export default PDFViewerComp 