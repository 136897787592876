import React from 'react' 
//import AuthContext from '../store/auth'

const CommonFooter =(props)=>{
   //const authCtx = useContext(AuthContext); 

	return(
		 <div className='container'>
		   <footer>
                 
                 <div className="card">
                  <div className="card-header text-primary">
                    <h2>Giờ Nhóm Hằng Tuần:</h2>
                  </div>
                  <div className="card-body">
                    <h2 className="card-title text-primary">&nbsp;</h2>
                   
                    
                    <div className="d-flex justify-content-center">
                        <ul className='list-group list-group-horizontal text-center'>
                             <li className='list-group-item'><a href='/'>Trường Chúa Nhật</a>9:30am-10:00pm</li>
                           
                            <li className='list-group-item'><a href='/indexVN'>Thờ phượng chung</a>: 10:30am-12:00pm</li>
                            <li className='list-group-item'><a href='/yem'>Thanh thiếu niên</a>: 10:45am-12:00pm</li>
                            <li className='list-group-item'><a href='/cm'>Nhi đồng</a>: 11:45am-12:45pm</li>
                      </ul>
                    </div>
                    <div className="d-flex justify-content-center">
                        <p>&nbsp;</p>
                        <address className='text-primary lead'>Địa chỉ nhà thờ:<br/>
                            Hội Thánh Lời Chúa<br/> 
                            3134 Frick Rd<br/>
                            Houston , TX 77038<br/>
                            Tel: (713) 239-1466<br/>
                            Quản nhiệm: MS Phạm Văn Huyên<br/>
                            Email: info@loichua.church 
                        </address>
                    </div>
                    
                  </div>
                </div>
                 <a href='/indexVN' className='text-primary lead'>Trang nhà</a>&nbsp;|&nbsp;  
                 <a href={props.authConfig.facebookURL} className='text-primary lead'>Facebook</a> &nbsp;|&nbsp; <a href='https://thuvien.loichua.church/youtube-channel/' className='text-primary lead'>YouTube</a>
                 &nbsp;|&nbsp;<a href='/thuvien' className='text-primary lead'>Thư viện</a>&nbsp;|&nbsp;<a href='/contact' className='text-primary lead'>Contact</a>
                 <p ><a href='/contact' className='text-primary lead'>By Social Media Team HTLC 2022</a></p>
                 
                 
            </footer>
			</div>
	)
}
export default CommonFooter