import React from 'react';

const MediaVBC= (props)=>{

    return(
        
         
            <>
            {/*<iframe src={props.vbcUrl} height='600' width='800' title='Phat Thanh Lien Huu Houston - Texas' />*/}
            <audio controls>
                    <source  src={props.vbcUrl} type='audio/mpeg' /> 
            </audio>
            </>
        
    )
}

export default MediaVBC;
