import React, { useState, useEffect, useContext } from 'react';
import AuthContext from './store/auth';
import axios from 'axios';
import YouTubeEmbed from './YouTubeEmbed';
const VideoList = () => {
    const useCtx = useContext(AuthContext);
    const [videolist, setVideoList] = useState([]);

    useEffect(() => {

        const instance = axios.create({
            baseURL: useCtx.apiBaseURL 
        });
        const blogUrl = 'https://htloichua.blogspot.com/';
        instance.post("/main/get-video-list", { 'videoUrl': blogUrl }).then(res => {////"/main/get-video-list"
            console.log('got resp ' + res.data.status);
            console.log('got resp message ' + res.data.message);
            console.log('got resp data ' + JSON.stringify(res)); //give videos id here as list 
            setVideoList(res.data.data);




        })
            .catch(err => {
                console.log(err);
            })
    }, [useCtx.apiBaseURL])

    return (
        <>
            {videolist && videolist.map((v) => {
                return (
                    <div key={v.id}>
                         <p>&nbsp;</p> 
                        <YouTubeEmbed embedId={v} />
                        <p>&nbsp;</p>


                    </div>

                )

            })
            }

        </>
    )
}

export default VideoList;