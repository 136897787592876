import React from 'react';
import { Helmet } from "react-helmet";

import HeaderNavigator from './HeaderNavigator';
//import FooterSimple from './FooterSimple';
// Alternative inline style
//import background from '../components/img/yem-picnic.jpg';
import './YemMinistry.css'
import TopSlide from './TopSlide';
import './TopSlide.css';
import FooterForYEM from './FooterForYEM';
import VideoYEMBackground from './VideoYEMBackground';

const YemMinistry = (props) => {

    return (
        <React.Fragment>
            <Helmet>
                <title>Hội Thánh Tin Lành Lời Chúa -Giới Thiệu Mục Vụ Thanh Thiếu Niên Tin Lành YEM</title>
                <meta name='description' content='Tin lành cho người Việt Houston, Texas. Nhà thờ Tin lành tọa lạc tại 3134 Frick Rd, Houston , TX 77038 ' />
                <meta name='keywords' content='tin lanh, nha tho, nguoi viet, co doc nhan, hoi thanh, song voi loi chua' />
            </Helmet>
            <header class="header light-text" data-onscroll-classes="fixed-header animate-header">

                <div class="header__top-nav background-off-white">

                    <div class="container">
                        <div class="verse-of-the-day hide-on-md">

                            <button class="button button--secondary button--fill button-block-sm no-shadow modal-button"
                                data-modal-target="verse-of-the-day">Click to see the verse of the day</button>

                        </div>
                        <div class="verse-of-the-day hide-on-sm show-on-md">

                            <div class="row align-items-center">
                                <div class="flex-md-3 flex-lg-2">
                                    <div class="background-secondary text-center-md verse-of-the-day-button">Verse of the day</div>
                                </div>

                                <div class="flex-md-9 flex-lg-10">
                                    <p class="italic">Rejoice always, pray without ceasing, give thanks in all circumstances; for this is
                  the will of God in Christ Jesus for you. <span class="citation bold">~1 Thessalonians 5:16-18</span>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <HeaderNavigator />
            </header>
            <main>
                {/*
                <section class="banner single-banner-yem-minitries full-width">
                  

                    <div class="container">

                        <div class="banner__content">

                            <div class="section-heading">
                                <span class="section-intro">Jesus is Lord</span>
                                <h1>YEM's ministry</h1>
                            </div>

                            <div class="breadcrumb">
                                <div class="breadcrumb__home--link"><a href="/">Home</a></div>
                                <span>/</span>
                                <div class="breadcrumb__home--link"><a href="/">Ministries</a></div>
                                <span>/</span>
                                <div class="breadcrumb__current--page-link">YEM's ministry</div>
                            </div>
                        </div>
                    </div>
                </section>
                */}
                <VideoYEMBackground />
                <section class="single-page single-yem-ministry default-section-spacing">

                    <div class="container">
                        <iframe title="yem" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FHTLoiChua%2Fposts%2Fpfbid0ZXj754j22e4EYhqDbuHr3wFKD335hWAaVJqegwvgyMBE3dA8VtjvMXwrWoqs6JjKl&show_text=true&width=500" width="500" height="462" style={{ border: 'none', overFlow: 'hidden' }} scrolling="no"
                            frameBorder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                        <div class="description pad-b-sm-4">

                            <div class="section-heading">
                                <span class="section-intro">Description</span>
                                <h2>Description</h2>
                            </div>
                            <p>Welcome to the Youth & English Ministry at Houston Vietnamese Christian Church! We are a united family Living In Fellowship Everyday with our Creator and community of believers. In all our ministries, we seek to glorify God and share the Gospel of Jesus Christ!
                            Please join us in our praise, prayer, and proclamation that Jesus Christ is Lord!</p>


                        </div>
                        <div class="responsibilities pad-b-sm-4">

                            <div class="section-heading">
                                <span class="section-intro">Activities</span>
                                <h2>Activities</h2>
                            </div>
                            <p>Prayer Meetings via Skype:

                            Tuesdays, 8-9pm Middle/High School

                            Wednesdays, 8-9pm College/Career+


                            </p>
                            <p>
                                Sports Night: Thursdays, 6-9pm


                                YEM Life Fellowship: Saturdays, 12-3pm


                                Worship Service: Sundays, 10:30am

                                Lunch Refreshments served after at 12pm
                            </p>

                        </div>
                        <div class="details pad-b-sm-2">

                            <div class="section-heading">
                                <span class="section-intro">Details</span>
                                <h2>Details</h2>
                            </div>
                            <div class="row">

                                <div class="flex-md-6 flex-lg-4">

                                    <div class="icon-text mar-b-sm-06">
                                        <span class="icon"><i class="ri-user-star-line"></i></span>
                                        <span class="text bold">Leads by Pastor Sang Tran</span>
                                    </div>
                                    <div class="icon-text mar-b-sm-06">
                                        <span class="icon"><i class="ri-group-line"></i></span>
                                        <span class="text bold">30 members</span>
                                    </div>
                                    <div class="icon-text">
                                        <span class="icon"><i class="ri-calendar-line"></i></span>
                                        <span class="text bold">First Saturday of every month @ 10:30am</span>
                                    </div>
                                </div>
                                <div class="flex-md-6 flex-lg-4">

                                    <div class="icon-text mar-b-sm-06">
                                        <span class="icon"><i class="ri-phone-line"></i></span>
                                        <span class="text bold">713-239-1466</span>
                                    </div>

                                    <div class="icon-text mar-b-sm-06">
                                        <span class="icon"><i class="ri-mail-line"></i></span>
                                        <span class="text bold">htloichua@gmail.com </span>
                                    </div>
                                    <div class="icon-text">
                                        <span class="icon"><i class="ri-map-pin-line"></i></span>
                                        <span class="text bold">3134 Frick Rd, Houston , TX 77038</span>
                                    </div>
                                </div>
                                <div class="flex-md-12 flex-lg-4 mar-t-md-2 mar-t-lg-0">

                                    <div class="icon-text">
                                        <span class="icon"><i class="ri-money-dollar-circle-line"></i></span>
                                        <span class="text bold">FREE to join</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single-page__share background-off-white pad-t-sm-2 pad-b-sm-2">

                        <div class="container">
                            <TopSlide />
                        </div>
                    </div>

                    <div class="single-page__share background-off-white pad-t-sm-2 pad-b-sm-2">

                        <div class="container">

                            <div class="display-flex align-items-center">

                                <div class="bold leading text">Share</div>

                                <div class="social">
                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                        class="ri-facebook-line"></i></a>
                                </div>
                                <div class="social">
                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                        class="ri-linkedin-line"></i></a>
                                </div>
                                <div class="social">
                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                        class="ri-twitter-line"></i></a>
                                </div>
                                <div class="social">
                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                        class="ri-instagram-line"></i></a>
                                </div>

                            </div>

                        </div>

                    </div>
                    {/*
                    <div class="single-yem-ministry__cta text-center-sm color-off-white default-section-spacing">

                        <h2 class="mar-b-sm-2">We invite you to join our YEM's ministry.<br /> God is with you</h2>
                        <a href="" class="button button--primary button--fill">Join today</a>

                    </div>
                    */}
                </section>
            </main>
            <FooterForYEM footerMessage="We invite you to join our YEM's ministry" callActionTxt='Find out more...' />

        </React.Fragment>
    )
}
export default YemMinistry;