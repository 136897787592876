import React from 'react';
import SiteHeader from './SiteHeader';
import ScrollBack from './ScrollBack';
import SmallTeamFooter from './SmallTeamFooter';
//import Magazine from './Magazine';
import BannerForMagazine from './BannerForMagazine';
import SinglePageMagazine from './SinglePageMagazine';
const MagazineContainer = () => {

    return (
        <>
            <SiteHeader />
            <main>
                <BannerForMagazine />
                <SinglePageMagazine />

            </main>
            <ScrollBack />
            <SmallTeamFooter />
        </>
    )
}

export default MagazineContainer;