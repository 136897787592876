import React from 'react';
//import SiteHeader from './SiteHeader';
import MediaVBC from './MediaVBC';
import BannerPhatThanhTinLanh from './BannerPhatThanhTinLanh';
import SmallTeamFooter from './SmallTeamFooter';
import SiteHeaderWithSlide from './SiteHeaderWithSlide';
const GeneralContainer = () => {

    return (
        <>
            <SiteHeaderWithSlide />
            <main>
                <BannerPhatThanhTinLanh />
                <MediaVBC vbcUrl='https://www.vbchouston.com/media/mp3/Rp-Nov-21-21.mp3' />
            </main>
            
            <SmallTeamFooter />

        </>
    )

}

export default GeneralContainer; 