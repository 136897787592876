import React, { useContext } from 'react'
//import FooterSimple from '../FooterSimple'
import Navbar from '../xuan2023/navbar'
import BioCardTopPic from '../xuan2023/biocardtopic'
//import SlideComp from './../slideshow/slidecomp'
//import SlideListImages from '../slides/SlideComp' 
import GetLoiSongHangNgay from '../GetLoiSongHangNgay'
import AuthContext from '../store/auth'
import PhatThanhTinLanh from '../PhatThanhTinLanh'

import '../xuan2023/modal.css'

import Popup from "reactjs-popup";
//import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "reactjs-popup/dist/index.css";
import CommonFooter from '../xuan2023/common_footer'
//import VideoCarousel from '../videos/CarouselVideos'
import CarouselVideoComp from '../videos/CarouselVideoComp'
import ShowBible from '../bible'
import RandomVerse from '../xuan2023/RandomVerse'

const MyModal = () => {

    return (
        <Popup trigger={<button className="button lead"><span style={{ color: 'darkblue', fontSize: '1.2rem', fontWeight: 'bold' }}> Giờ Nhóm Hằng Tuần </span></button>} modal closeOnDocumentClick >
            {close => (<>

                <div className='d-flex justify-content-end'><a className='close' onClick={close}>[&times;]</a></div>
                <div className="card">
                    <div className="card-header text-primary">
                        <h2>Chương Trình nhóm lại hằng tuần:</h2>
                    </div>
                    <div className="card-body">
                        <h2 className="card-title text-primary">&nbsp;</h2>
                        <div className="d-flex justify-content-center">
                            <ul className='list-group list-group-horizontal text-center'>
                                <li className='list-group-item'><a href='/indexVN'>Thờ phượng chung</a>: 10:30am-12:00pm</li>
                                <li className='list-group-item'><a href='/yem'>Thanh thiếu niên</a>: 10:45am-12:00pm</li>
                                <li className='list-group-item'><a href='/cm'>Nhi đồng</a>: 11:45am-12:45pm</li>
                            </ul>
                        </div>
                        <div className="d-flex justify-content-center">
                            <p>&nbsp;</p>
                            <address className='text-primary lead'>Địa chỉ nhà thờ:<br />
                            Hội Thánh Lời Chúa<br />
                            3134 Frick Rd<br />
                            Houston , TX 77038<br />
                            Tel: (713) 239-1466<br />
                            Quản nhiệm: MS Phạm Văn Huyên<br />
                            Email: quannhiem@loichua.church
                        </address>
                        </div>
                    </div>
                </div>
            </>
            )}

        </Popup>
    )
};




const Thanksgiving = (props) => {
    //defineaster list of data images here as js object below to use SlideListImages component
    const authCtx = useContext(AuthContext);

    const fontBold = {
        color: 'darkblue', fontSize: '1.2rem', fontWeight: 'bold'
    }
    const kdnVideoList = [
        { //keep adding in the format below 
            id: 1,
            title: "Kỷ niệm 20 năm thành lập HTLC 2004-2024",//"Dem Thanh Bet Le Hem",
            src: "https://youtu.be/NA3yqfB4AcI",//""https://www.youtube.com/watch?v=vUkMeECsoPI",
            credit: ""
        },
        { //keep adding in the format below 
            id: 2,
            title: "Lễ Hiền Mẫu 2024", //"Bon Chan Chien",
            src: "https://youtu.be/cl2i6tWf5Ak",
            credit: ""
        },
        { //keep adding in the format below 
            id: 3,
            title: "Lễ Phụ Thân ", //"Den Voi Vui Mung",
            src: "https://youtu.be/z7eKJmOrAtY",
            credit: ""
        },
        //https://youtu.be/
    ]
    const yemVideoList = [
        { //keep adding in the format below 
            id: 1,
            title: "YEM Anniversary",
            src: "https://www.youtube.com/watch?v=OjQVqPA4kLs",
            credit: "Video by Social Media Team HTLC"
        },
        {
            id: 2,
            title: "",
            src: "https://www.youtube.com/watch?v=ihJvYOTCVZ0",
            credit: "Video by Social Media Team HTLC",
        },
    ]
    const sermonVideoList = [
        { //keep adding in the format below 
            id: 5,
            title: "Doi Song Bien Doi",
            src: "https://www.youtube.com/watch?v=mbdRL8p6-Ds",
            credit: "Video by Social Media Team HTLC"
        },
        { //keep adding in the format below 
            id: 1,
            title: "Nam Moi Cung Nhau Tan Toi",
            src: "https://youtu.be/yop_rl8C618",
            credit: "Video by Social Media Team HTLC"
        },
        { //keep adding in the format below 
            id: 2,
            title: "Trung Tin Den Chet",
            src: "https://www.youtube.com/watch?v=6UrEMcK0B5c",
            credit: "Video by Social Media Team HTLC"
        },
        { //keep adding in the format below 
            id: 3,
            title: "Chua Nhat Truyen Giao HTLC",
            src: "https://youtu.be/LIfhZz3Wqj4",
            credit: "Video by Social Media Team HTLC"
        },
        { //keep adding in the format below 
            id: 4,
            title: "Chua Dang Cho",
            src: "https://www.youtube.com/watch?v=vYQqB6XshhM",
            credit: "Video by Social Media Team HTLC"
        },
    ]
    return (
        <>
            <header>
                <div className="overlay">
                    <div className='container-fluid'>
                        <Navbar />

                    </div>
                </div>

                <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop" height='550'>
                    <source src="/assets/media/Thanksgiving-2023-wider.mp4" type="video/mp4" />
                </video>


            </header>
            <div className='container'>

                <div className='row' style={{ marginRight: '1.6rem' }}>
                    <div className='col'>
                        <br />
                        <a href='/'><img width='550' src='/assets/images-cus/phatthanhtinlanh.png' className='img-fluid' alt='phat thanh tin lanh - hoi thánh lời Chúa houston texas - nha tho tin lanh houston' /></a>
                        <br />
                        <PhatThanhTinLanh />
                        <br />
                        <iframe src="https://www.facebook.com/plugins/video.php?height=400&href=https%3A%2F%2Fwww.facebook.com%2FHTLoiChua%2Fvideos%2F619219996211256%2F&show_text=false&adapt_container_width=true&width=500&t=0" width="500" height="400" style={{ border: 'none', overFlow: 'hidden' }} scrolling="no" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                        <br />
                        <span style={{ paddingLeft: '1rem' }}><img width='550' src='/assets/images-cus/daily-bread.jpg' className='img-fluid' alt='loi song hang ngay' />
                        </span>
                        <GetLoiSongHangNgay />
                        <br />
                        <a href='https://thuvien.loichua.church/mua-chay-lent/'><img width='550' src='/assets/images-cus/RememberingHisSacrifice.png' className='img-fluid' alt='tim hieu ve mua chay tai nha tho tin lanh- lent season' /></a>
                        <br />
                        <audio controls>
                            <source src="https://tinlanh.org/Audio/PhucAm/MuaChay.mp3" type='audio/mpeg' />
                        </audio>
                        <br />
                        <a href='https://thuvien.loichua.church/khi-cay-vi-cam-len-tieng/' target='new'><img width='550' src='/assets/images-cus/when-violin-speaks-up.png' className='img-fluid' alt='khi cay vi cam len tieng' /></a>
                        <br />
                        <a href='https://loichua.church/thuvien' target='new'><img width='630' src='/assets/images-cus/church-blog.png' className='img-fluid' alt='thu vien tin lanh - loi chua' /></a>
                        <br />





                    </div>
                    <div className='col' style={{ marginRight: '0.55rem' }}>
                        <br />
                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <a href='https://fb.me/e/1tGLbVhIM' target='new'><img src="https://drive.google.com/uc?id=1SPde_TsCNNC8_QbKwpGC5rY-oDPQlIz1" /></a>{/** note the format to use uc?id={FILEID} */}
                            
                            <p className='center-align-text lead'><span style={fontBold}> <a href='https://fb.me/e/1tGLbVhIM' target='new'>Đăng kí tham dự (nhấp vào 'going')</a></span>

                            </p>
                        </div>
                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            {/*
                            <div><span style={{ paddingLeft: '1rem' }}><ShowBible /></span></div>
                                <a href="https://fb.me/e/1tGLbVhIM">HDNG</a>
                            <div>
                                <RandomVerse showNav={''} />
                            </div>
                            */}
                            <div>

                            </div>
                            <div>
                                <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FHTLoiChua%2Fposts%2Fpfbid0SePktaAFDS4aVBTMFFVGJGMNx2hAgMdoLpu7V32pJS5Cwf9Q3AT7BeieWE5xMMvvl&show_text=true&width=500" width="500" height="419" style={{ border: 'none', overFlow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                {/**<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FHTLoiChua%2Fposts%2Fpfbid0TgsjWoDf5E6TqPrkwRUMV7zRRZQLRKhZZBBwbx8C2WFvpRB4WcGBmgMopF2zwiEkl&show_text=true&&adapt_container_width=true&width=500" width="500" height="550" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                 */}
                                {/*
                                <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FHTLoiChua%2Fposts%2Fpfbid021N14xnR2MNPvP6x38T367R9jtJ2ayWZuTENJQ5rYUXXishMyUhsdTX8XfQpN3p8sl&show_text=true&width=500" width="500" height="578" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowFullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                {/*
                                <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Fdave.bui%2Fvideos%2F1618224891976157%2F&show_text=false&width=476&t=0" width="476" height="476" style={{ border: 'none', overFlow: "hidden" }} scrolling="no" frameBorder="0" allowFullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                                */}
                            </div>
                            <MyModal />

                        </div>
                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><span style={fontBold}>Giờ thờ phượng mỗi Chúa nhật:</span>
                                <br />
                                Trường Chúa Nhật: 9:30AM
                                <br />
                                Thờ phượng chung: 10:30AM
                                <br />
                                <a href='/yem' style={fontBold}>Thanh Thiếu Niên</a>: 10:30AM-12:00PM
                                <br />
                                <a href='/children-ministry' style={fontBold}>Nhi đồng</a>: 11:45AM-12:45PM
                                Mong được đón tiếp quí vị.
                            </p>
                        </div>
                        {/*
                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><a href='/DangHien' style={fontBold}>Dâng hiến</a></p>
                            <p className='left-align-text lead'>
                                Mùa Tạ Ơn và Giáng Sinh lại đến, Hội thánh chúng ta có nhiều điều để tạ ơn trong năm vừa qua, trong tinh thần tạ ơn, kính xin quí con dân Chúa
                                sẻ hổ trợ giúp đỡ trong truyền giáo, các mục vụ và các dự án sửa chửa cần thiết trong cơ sở HT sắp đến.
                            </p>
                            <p className='left-align-text lead'>
                                Với tháng còn lại trong năm nay và hội đồng truyền giáo ngày 12/04/2022 (Dec 4 2022), chúng tôi kêu gọi sự dâng hiến nhiệt thành của con dân Chúa cho hội thánh và công tác truyền giáo
                                toàn cầu.
                                <br />
                                Mọi dâng hiến đều đươc khấu trừ thuế ( tax-deductible).  Xin cảm ơn tấm lòng rời rộng của quí vị góp phần tiếp tục xây dựng Vương Quốc của Đức Chúa Trời và cho vinh hiển Danh Ngài.
                            </p>
                            <p className='center-text lead'>
                                <a href='/DangHien'><img src='/assets/images-cus/giveback-donate.png' width='100' height='100' alt='' /> Dâng hôm nay</a>
                            </p>
                        </div>
                        */}
                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><a href='/yem' style={fontBold}>Video Ca Nhac Thanh Chon Loc </a></p>
                            {/*
                                <VideoCarousel />
                                */}
                            <CarouselVideoComp videoArray={kdnVideoList} />

                            <br />

                        </div>
                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><a href='/yem' style={fontBold}>Youth English Ministry (YEM) Activities</a></p>
                            {/*
                            <SlideListImages data={dataImg}/>
                            */}
                            <CarouselVideoComp videoArray={yemVideoList} />
                            <br />

                        </div>

                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><a href='/' style={fontBold}>Ban Nam Giới & Phụ Nữ</a></p>
                            <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vSSkOXoCCc0Dug5IUKzaTpz5sYwQGemA9lvSZ4kYardaC0etpv17RfdjKwCV6LHYXpHaSRGGRECrAY9/embed?start=true&loop=true&delayms=2000" frameborder="0" width="480" height="299" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" title='HT Loi Chua - sinh hoat nam gioi phu nu' />
                        </div>
                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><a href='/' style={fontBold}>Sứ Điệp </a></p>
                            <CarouselVideoComp videoArray={sermonVideoList} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <h2 className='text-primary'>Ban Linh vụ</h2>
                    <div className='col'><BioCardTopPic imgUrl='https://loichua.church/assets/images-cus/ms-huyen.jpeg' name='Rev Phạm Văn Huyên, MDiv' position='Senior Pastor' />

                    </div>
                    <div className='col'><BioCardTopPic imgUrl='https://loichua.church/assets/images-cus/pastor-ngon-bui.jpg' name='Rev Bùi Trung Ngôn, BA' position='Assistant Pastor' />
                    </div>
                    <div className='col'><BioCardTopPic imgUrl='https://loichua.church/assets/images-cus/ms-le-thuan-3.jpg' name='Rev Lê Phước Thuận, MDiv' position='Pastor' />

                    </div>
                    <div className='col'><BioCardTopPic imgUrl='https://loichua.church/assets/images-cus/pastor-sang.jpg' name='Pastor Trần Sang' position='Youth Pastor' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col'></div>
                </div>
            </div>
            <CommonFooter authConfig={authCtx} />

        </>
    )
}

export default Thanksgiving