import React from 'react';

const Privacy = (props)=>{

    return(
        <React.Fragment>
            <div style={{textAlign:'left', margin:'2rem'}}>
                <h3>Privacy Policy</h3>
 
<h3>Privacy Policy Contact Information:</h3>

Vietnamese Christian Church Houston
<address>
3134 Frick Rd <br/>
Houston TX 77038 <br/>
United States<br/>
(713) 239-1466<br/>
loichua.church@gmail.com<br/>	

</address>

<p>            
We respect your privacy regarding any information we may collect while operating our website.<br/>
For visitors on our website, we collect:<br/>

    Any voluntary information submitted to us by visitors.<br/>
    Aggregate (General Tracking) information about pages that users visit on our site<br/>
                Non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, <br />
                and the date and time of each visitor request. Our purpose in collecting non-personally identifying information is to better understand how our visitors use our website. <br />
                From time to time, we may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of our website.<br/>
                Potentially personally-identifying information like Internet Protocol (IP) addresses. We do not use such information to identify visitors and do not disclose such information,
                other than under the same circumstances for disclosing personally-identifying information, as described below.
            </p>
<p>            
The information that we collect is used for:<br/>

    Internal review, tracking, or contacting and is then archived for future reference.<br/>
    Delivering custom content to our website visitors.<br/>
    Improving the quality of our website content and user friendliness for a better user experience.<br/>
    Contact information for service announcements, including newsletters, emails or periodic event from our church.<br/>
    Not Shared with any outside organization for commercial purposes, except where required. We may still share this information for tracking and other non-commercial purposes.<br/>
                Google Analytics is setup to track traffic to or from this website. Google’s ability to use and share information collected by Google Analytics about your visits to this<br/>
                site is
                restricted by the Google Analytics Terms of Use
            </p>
<p>            
Gathering of Personally-Identifying Information<br/>
                Certain visitors to our website choose to interact with us in ways that require us to gather personally-identifying information. The amount and type of information that <br />
                we gather depends on the nature of the interaction. For example, we ask visitors who sign up for an account to provide a name, email address and phone number. <br />
                Those who engage in transactions with us are asked to provide additional information, including as necessary the personal and financial information required to process those <br />
                transactions. In each case, we collect such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor’s interaction with us. We do not disclose <br />
                personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent
                them from engaging in certain website-related activities.
</p>
<h3>          Protection of Certain Personally-Identifying Information</h3>      
<p>
We use SSL encryption to protect the data that is transferred between you and our servers. We disclose potentially personally-identifying and personally-identifying information only to those of our employees, contractors and affiliated organizations that (i) need to know that information in order to process it on our behalf or to provide services available at our websites, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using our website, you consent to the transfer of such information to them. We will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to our employees, contractors and affiliated organizations, as described above, we disclose potentially personally-identifying and personally-identifying information only when required to do so by law, or when we believe in good faith that disclosure is reasonably necessary to protect the property or rights of our organization, third parties or the public at large. If you are a registered user of a our website and have supplied your email address, we may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what’s going on with our organization and our products. We primarily use our blogs to communicate this type of information, so we expect to keep this type of email to a minimum. We will take all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.
Cookie Policy
</p>
            <p>A cookie is a string of information that a website stores on a visitor’s computer. The visitor's browser provides this information to the website each time the visitor loads <br />
                a page. We use cookies to help us identify and track visitors, their usage of our website, and their website access preferences. Most cookies are harmless, the data is only stored temporarily to make the website work. But cookies can track you across the internet,
                when they are set by third party features (e.g. Google Maps).</p>
<h3>Viewing and Deleting Cookies</h3>

<p>            
You may view the settings in your browser to see a list of all cookies that are saved on our website. You can view the contents or delete them at any time.
</p>
<h3>  Third Party Cookies</h3>              

            <p>We use third-party vendors (e.g. Google Maps, Google Analytics, etc) who may add third-party cookies which collect data and compile your activity with other data they own. These cookies
                can legitimately track you, record what pages you visit, and provide highly targetting advertising based on your activity. Not all third party features do this,
                but you should be aware that it is common practice. As long as we use their features on the website, we can't control how they use cookies.
                But reputable companies often let you opt-out of tracking (see options below).
</p>
<h3>Opt-Out of Cookie Collection</h3>

            <p>        We provide a Cookie Consent notice when you first visit our site. If you agree, we set a cookie that is valid for 12 months, at which point you need to agree again. If you wish to revoke your acceptance of our Cookie policy, you can easily open your browser settings, find the section that describes your security (keyword "cookies")
                nd delete all your cookies for this domain.</p>
    
<p>If you do not consent, you should not use our website, because at the moment, there is no way to use our site without cookies. We require the use of basic cookies for doing very mundane things like: remembering the active menu, or storing common variables to make the site load faster. You cannot opt out of these required cookies or the site won't work. We do use third party services (e.g. Google Maps) which may set cookies that could track you around the internet on other sites that use those same services. Unfortunately, we can't provide you functionality like Maps, Chat, or Analytics (which is important for our business operations) without these cookies. They are critical to our core functionality and business activities, and we don't currently have a way to disable only specific features of the site. But if you are concerned about third party cookies tracking you, you do have a few options:
</p>
<p> Edit your browser settings to "disable third party cookies".
    Opt out of cookie collection at the third party company, e.g. Google Opt-out</p>
   

<h3>California Online Privacy Protection Act</h3>
            <p>CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being
                collected and those individuals or companies with whom it is being shared.</p>

<p>
According to CalOPPA, we agree to the following:<br/>

    Users can visit our site anonymously.<br/>
    Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website.<br/>
    Our Privacy Policy link includes the word 'Privacy' and can easily be found on the page specified above.<br/>
            </p>
            <p>
            You will be notified of any Privacy Policy changes on our Privacy Policy page. You can change your personal information by logging in to your account, or by chatting with us or by sending us a support ticket.
How does our site handle Do Not Track signals?

            </p>

<p>We do not currently have the technology to honor Do Not Track signals. However, third-party services may honor this request.</p>
<h3>Does our site allow third-party behavioral tracking?</h3>
            <p>It's also important to note that we do not allow third-party behavioral tracking.<br/>
COPPA (Children Online Privacy Protection Act)

When it comes to the collection of personal information from children under the age of 13 years old, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United States' consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.
<br/>
We do not specifically market to children under the age of 13 years old.

            </p>
<h3>Fair Information Practices</h3>

            <p>The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical
                to comply with the various privacy laws that protect personal information.</p>
            <p>
                In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:<br/>

    We will notify you via email within 72 hours.<br/>
    We will notify the users via in-site notification within 72 hours.<br/>
                We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors<br/>
                who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or <br />
                government agencies to investigate and/or prosecute non-compliance by data processors.

            </p>

<p>CAN SPAM Act</p>
<p>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.</p>

<p>We collect your email address in order to: <br/>

    Send information, respond to inquiries, and/or other requests or questions to our church services and ministries.<br/>
    
    Send you additional information related to services.
</p>
    
<p>To be in accordance with CANSPAM, we agree to the following:<br/>

    Not use false or misleading subjects or email addresses.<br/>
    Identify the message as an advertisement in some reasonable way.<br/>
    Include the physical address of our business or site headquarters.<br/>
    Monitor third-party email marketing services for compliance, if one is used.<br/>
    Honor opt-out/unsubscribe requests quickly.
</p>

            <p>If at any time you would like to unsubscribe from receiving future emails, you can follow the instructions at the bottom of each email and we will promptly remove you from ALL non-account related correspondence.
                But as long as you use our services,
                you are required to consent to service, and account related correspondence.</p>

<h3>         Privacy Policy Changes</h3>   
<p>Although most changes are likely to be minor, we may change our Privacy Policy from time to time, at our sole discretion. We encourage visitors to frequently check this page for any changes to our Privacy Policy. If you have an account, you should also check your site’s dashboard for alerts to these changes. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.
</p>
<h3>Other Information:</h3>
<p>If you have any questions, or are currently receiving a newsletter or emails from us and would like to stop receiving them, please notify us by contacting us by mail, phone or email at the above address.</p>

            <p>With respect to sensitive information, we will redirect the user to a secure page on our site before transferring or receiving sensitive data. This information may include credit card or
                banking information, personal information, or other sensitive information.</p>

                <p>For questions regarding our privacy policy or website in general, please contact by mail, phone, or email, at the above address. </p>
                <p>Back To <a href='/'>Home</a></p>
 
</div>
        
</React.Fragment>
        
    )

}
export default Privacy;