import React from 'react';

const WelcomeVN = (props) => {
    const welcomeStyle = {

        fontFamily: "Arial",
        fontSize: '1.5rem',
        textAlign: 'left'
    };
    return (

        <React.Fragment>

            <section class="welcome welcome--2 welcome--about pad-t-sm-2 pad-b-sm-2 pad-t-lg-4 pad-b-lg-4">

                <div class="welcome__content">

                    <div class="container">

                        <div class="row align-items-center default-section-spacing pad-b-sm-0 mar-b-lg-4">

                            <div class="flex-lg-6">

                                <div class="section-heading">
                                    <iframe title='welcome to httl loi chua' src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Floichua.church%2F&width=450&layout=standard&action=like&size=small&share=true&height=35&appId=268395626549167" width="450" height="35" style={{ border: 'none', overFlow: 'hidden' }} scrolling="no" frameBorder="0" allowFullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                    <span class="section-intro">Welcome</span>
                                    <h2>&nbsp;</h2>
                                </div>
                                <p style={welcomeStyle}>
                                    Chào mừng quý vị và các bạn đến với website Hội Thánh Tin lành Lời Chúa,<br />

                                    Hội thánh Tin lành Lời Chúa có cơ sở tọa lạc tại 3134 Frick rd. Houston Texas
                                    <br />
                                    Nhà thờ này do người Mỹ thành lập từ những năm 50 của thế kỷ trước. Với biểu tượng là một ngọn hải đăng. Và tên hội thánh là Hội thánh Báp tít Hải đăng. Năm 2011, Hội thánh Mỹ nầy đã dời đi nơi khác, và chuyển giao cơ sở lại cho Hội thánh Lời Chúa.
                                     <a href='/church-history' style={{ color: 'blue' }}>...MỜI BẠN ĐỌC THÊM </a>
                                </p>
                                {/*<h3><a href='/doc-them' >Read more...</a></h3>*/}


                            </div>
                            <div class="flex-lg-6">

                                <div class="post-media">

                                    <div id="sermon-video" class="sermon-video">
                                        {/*
                                        <video width="580" height="480" controls poster='assets/images-cus/welcome-to-htlc.png' disablePictureInPicture >
                                            <source src="assets/media/htloichua-promo.mp4" type="video/mp4" />
                                        </video>
                                        */}
                                        <iframe title="sermon" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FHTLoiChua%2Fposts%2Fpfbid02VVuXahJH7x5isvQ4rMP6AMUVA9NRcmNTUfDffsdNTuZPEwJA6QnfryqS46YFiCkPl&show_text=true&width=500"
                                            width="500" height="560" style={{ border: "none", overFlow: "hidden" }}
                                            scrolling="no" frameBorder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment >
    )
}
export default WelcomeVN;