import React from 'react';
import { Helmet } from "react-helmet";

import mystyle from './css/loichuastyle.module.css'

const AboutUsWelcome = () => {
    const threePointStyle = {
        textAlign: 'left',

    }
    return (
        <>
            <Helmet>
                <title>Hội Thánh Tin Lành Lời Chúa -giới thiệu Tin lành</title>
                <meta name='description' content='Tin lành cho người Việt Houston, Texas. Nhà thờ Tin lành tọa lạc tại 3134 Frick Rd, Houston , TX 77038 ' />
                <meta name='keywords' content='tin lanh, nha tho, nguoi viet, co doc nhan, hoi thanh, song voi loi chua' />
            </Helmet>
            <section class="welcome welcome--2 welcome--about pad-t-sm-2 pad-b-sm-2 pad-t-lg-4 pad-b-lg-4">

                <div class="welcome__content">

                    <div class="container">

                        <div class="row align-items-center default-section-spacing pad-b-sm-0 mar-b-lg-4">

                            <div class="flex-lg-6" style={threePointStyle}>

                                <div class="section-heading" >
                                    <span class="section-intro">WELCOME</span>
                                    <h2>Hội Thánh Tin Lành Lời Chúa</h2>
                                </div>

                                <h2>Mục đích</h2>
                                <p className={mystyle.card_font}>LÀM VINH HIỂN ĐỨC CHÚA TRỜI, MỞ RỘNG VƯƠNG QUỐC CỦA NGÀI, BẰNG CÁCH THI HÀNH ĐẠI MẠNG LỆNH CỦA CHÚA (Giăng 17:4; Ma-thi-ơ 6:9,10)
                                  </p>

                                <h2>Sứ mạng</h2>
                                <p className={mystyle.card_font}>
                                    ĐEM LỜI CHÚA ĐẾN VỚI MỌI NGƯỜI, GIÚP HỌ TiẾP NHẬN CHÚA JESUS, ĐÀO TẠO HỌ THÀNH MÔN ĐỒ CỦA NGÀI, ĐỂ ĐI RA PHỤC VỤ VÀ BIẾN ĐỔI CỘNG ĐỒNG
                                    (Bởi Con Người đã đến tìm và cứu kẻ bị hư mất. Lu.19:10)
                                    </p>

                                <h2>Khải tượng</h2>

                                <p className={mystyle.card_font}>HÀNG NGÀN NGƯỜI VIETNAM VÀ CÁC SẮC DÂN KHÁC TẠI HOUSTON ĐƯỢC CỨU QUA HT LỜI CHÚA, ĐƯỢC ĐÀO TẠO THÀNH MÔN ĐỒ CHÚA JESUS, VÀ QUA HỌ CỘNG ĐỒNG ĐƯỢC BIẾN ĐỔI.
                                    “Ta lập ngươi làm ánh sáng cho các dân, để đem sự cứu rỗi đến tận cùng trái đất.” (Công.13:47b)</p>
                                {/*}
                                <p><span style={{ fontSize: 'x-large' }}><strong>GIỚI THIỆU GIA ĐÌNH PHÚC ÂM LIÊN HIỆP</strong></span></p>
                                <p><span style={{ fontSize: 'medium' }}>HTTL Lời chúa là một phần của Phúc Âm Liên Hiệp Hoa kỳ , và Chúa Giêsu là trung tâm điểm, với hơn 6 triệu tín hữu trung kiên.  Phát xuất từ tình yêu của Chúa Giêsus và năng quyền của Đức Thánh Linh, là lý do gia đình Phúc Âm Liên Hiệp sẵn sàng đi ra, mang sứ điệp Tin Lành đến những vùng lân cận cho đến khắp nơi trên thế giới (Công vụ 1: 8) </span></p>

                                <p><strong><span style={{ fontSize: 'medium' }}>CHÚA GIÊ XU LÀ TRUNG TÂM ĐIỂM</span></strong></p>
                                <p><span style={{ fontSize: 'medium' }}>HTTL Lời chúa tôn Chúa Giê xu là Đấng cầm quyền trên mọi thế lực, và Ngài là Đấng sáng tạo vũ trụ (Cô-lô-se 2: 9-10).  Trong Chúa Giêsu, mọi người nhận được sự thoả vui, hạnh phúc tràn đầy.
                                 </span></p>
                                <p><strong><span style={{ fontSize: 'medium' }}>CHÚNG TÔI DỰA VÀO QUYỀN NĂNG CỦA ĐỨC THÁNH LINH<br /></span></strong></p>
                                <p><span style={{ fontSize: 'medium' }}> Công-vụ 1: 8 là niềm khích lệ cho chúng tôi:  </span><em>
                                    <span style={{ fontSize: 'large' }}>&#8216;Nhưng khi Đức Thánh Linh giáng trên các ngươi, thì các ngươi sẽ nhận lấy quyền phép
              và làm chứng về ta tại thành Giê-ru-sa-lem, cả xứ Giu-đê, xứ Sa-ma-ri, cho đến cùng trái đất&#8217;.</span></em><br /></p>
                                <p><span style={{ fontSize: 'medium' }}>Chỉ bởi quyền năng mầu nhiệm của Đức Thánh Linh, giúp chúng tôi có thể thể hiện tình yêu thương, lòng nhân ái và sự thương xót của Chúa Giêsu đến những người không biết Ngài.</span></p>
                                <p><strong><span style={{ fontSize: 'medium' }}>CHÚNG TÔI LÀ MỘT GIA ĐÌNH<br /></span></strong></p>

                                <p><span style={{ fontSize: 'medium' }}>Chúng tôi là một phần của Hội Thánh Đức Chúa Trời, có nghĩa chúng tôi đã được
                       chọn lựa để thực hiện mục đích cứu rỗi của Ngài trên thế gian, khôi phục lại thế giới hư mất cho đến khi Chúa trở lại.<br />
Là thành viên của Liên Minh Thế Giới, Hội Thánh Phước Âm Liên Hiệp là một trong những mạng lưới kết hợp các nhà thờ khắp nơi, tạo nên một đại gia đình trên thế giới.<br />
                                </span><span style={{ fontSize: 'large' }}> <em>&#8216;Thì cũng vậy, chúng ta là nhiều người mà hiệp nên một thân trong Đấng Christ,  và hết thảy chúng ta đều là các phần chi thể của nhau&#8217;
  </em>  (Rô-ma 12: 5)<br />
                                    </span><br /></p>
                           */}

                            </div>
                            <div class="flex-lg-6">

                                <div class="image-box mar-t-sm-8 mar-t-lg-0 mar-b-sm-8 mar-b-lg-0">

                                    <div class="box box--center">
                                        <img src="assets/images-cus/andrew-yem-standing.png" alt="" />
                                    </div>
                                    <div class="box box--top-right">
                                        <img src="assets/images/happy-kid.jpg" alt="" />
                                    </div>
                                    <div class="box box--bottom-right">
                                        <img src="assets/images/singing-girl.jpg" alt="" />
                                    </div>
                                    <div class="box box--top-left">
                                        <img src="assets/images-cus/church-front.jpeg" alt="" />
                                    </div>
                                    <div class="box box--bottom-left">
                                        <img src="assets/images/bible-sm.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default AboutUsWelcome;