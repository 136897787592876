import React, { useState, useContext } from 'react';
import axios from 'axios';
import AuthContext from './store/auth';

const RentalForm2 = (props) => {
    const useCtx = useContext(AuthContext);
    const [fname, setFname] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('')
    const [phone, setPhone] = useState('')
    const [churchname, setChurchName] = useState('')
    const [attendanceCount, setAttendanceCount] = useState('')
    const [daterequired, setDateRequired] = useState('')
    const [todaterequired, setToDateRequired] = useState('')
    const [denom, setDenom] = useState('')
    const [rentterm, setRentTerm] = useState('')
    const fnameHandler = (event) => {
        setFname(event.target.value);
    }
    const emailHandler = (event) => {
        setEmail(event.target.value);
    }
    const subjectHandler = (event) => {
        setSubject(event.target.value);
    }
    const messageHandler = (event) => {
        setMessage(event.target.value);
    }
    const phoneHandler = (event) => {
        setPhone(event.target.value);
    }
    const churchNameHandler = (event) => {
        setChurchName(event.target.value);
    }
    const attendanceCountHandler = (event) => {
        setAttendanceCount(event.target.value);
    }
    const dateHandler = (event) => {
        setDateRequired(event.target.value);
    }
    const toDateHandler = (event) => {
        setToDateRequired(event.target.value);
    }
    const denomHandler = (event) => {
        setDenom(event.target.value);
    }
    const rentTermHandler = (event) => {
        setRentTerm(event.target.value)
    }
    const formHandler = (event) => {
        event.preventDefault();
        const submitData = {
            fname: fname,
            email: email,
            phone: phone,
            churchname: churchname,
            attendanceCount: attendanceCount,
            daterequired: daterequired,
            todaterequired: todaterequired,
            denom: denom,
            rentterm: rentterm,
            subject: subject,
            message: message
        }
        console.log('got all submitDaata ' + JSON.stringify(submitData));
        axios.post(useCtx.sendEmailRentalURL2, submitData).then(
            response => {
                console.log(response.data)
                console.log('i got ' + response.status);
                if (response.status === 200) {
                    console.log('im here')
                    window.location.replace(useCtx.successURL);
                }
                else {
                    console.log('cant do')
                }
            }
        ).catch(error => {
            console.log('got error ' + error)
        })

    }
    return (

        <React.Fragment>

            <section class="contact default-section-spacing">

                <div class="container">

                    <form class="form contact__form" onSubmit={formHandler}>

                        <div class="row">

                            <div class="flex-md-6">

                                <div class="form__group">
                                    <label for="fname" class="form__label">Your full name <span class="color-danger">*</span></label>
                                    <input type="text" id="fname" class="form__input" name="fname" placeholder="Enter your full name" onChange={fnameHandler} required />
                                </div>
                            </div>
                            <div class="flex-md-6 mar-b-md-2">

                                <div class="form__group">
                                    <label for="email-2" class="form__label">Your email address <span
                                        class="color-danger">*</span></label>
                                    <input type="email" id="email-2" class="form__input" name="email"
                                        placeholder="Enter your email address" onChange={emailHandler} required />
                                </div>
                            </div>
                            <div class="flex-md-6">

                                <div class="form__group">
                                    <label for="phone" class="form__label">Phone Number <span class="color-danger">*</span></label>
                                    <input type="text" id="phone" class="form__input" name="phone" placeholder="Enter your phone " onChange={phoneHandler} maxLength='12' required />
                                </div>
                            </div>
                            <div class="flex-md-6 mar-b-md-2">

                                <div class="form__group">
                                    <label for="email-2" class="form__label">Your Church/Group Name<span
                                        class="color-danger">*</span></label>
                                    <input type="text" id="email-2" class="form__input" name="churchname"
                                        placeholder="Your church name or group name , organization name" onChange={churchNameHandler} maxLength='255' />
                                </div>
                            </div>
                            <div class="flex-md-6">
                                <div class="form__group">
                                    <label for="subject" class="form__label">Denomination<span class="color-danger">*</span></label>
                                    <input type="text" id="phone" class="form__input" name="denom" placeholder="i.e  Baptist or Methodist or Others , Non-denominations" onChange={denomHandler} maxLength='100' />

                                    {/*
                                    <select class='form__input' name='denom' value={denom} onChange={denomHandler}>
                                        <option value='baptist'>Baptist</option>
                                        <option value='cma'>Christian Missionary Alliance CMA</option>

                                        <option value='methodist'>Methodist</option>
                                        <option value='presbyterian'>Presbyterian</option>
                                        <option value='churchofchrist'>Church Of Christ</option>
                                        <option value='others'>Others</option>
                                        <option value='nodenom'>Christian Church with Non Denomination</option>


                                    </select>
                                    */}
                                </div>

                            </div>
                            <div class="flex-md-6">

                                <div class="form__group">
                                    <label for="fname" class="form__label">Estimated Attendance<span class="color-danger">*</span></label>
                                    <input type="text" id="phone" class="form__input" name="attendanceCount" placeholder="i.e  10 persons " onChange={attendanceCountHandler} maxLength='100' />
                                </div>
                            </div>
                            <div class="flex-md-6 mar-b-md-2">

                                <div class="form__group">
                                    <label for="email-2" class="form__label">From <span
                                        class="color-danger">*</span></label>
                                    <input type="text" id="email-2" class="form__input" name="daterequired"
                                        placeholder="Enter date i.e 4/1/2022" onChange={dateHandler} />
                                </div>
                            </div>

                            <div class="flex-md-6">

                                <div class="form__group">
                                    <label for="fname" class="form__label">To<span class="color-danger">*</span></label>
                                    <input type="text" id="phone" class="form__input" name="todaterequired" placeholder="i.e 4/3/2022" onChange={toDateHandler} maxLength='40' />
                                </div>
                            </div>
                            <div class="flex-md-12 mar-b-md-2">

                                <div class="form__group">
                                    <label for="subject" class="form__label">Rental Terms<span class="color-danger">*</span></label>
                                    <input type="text" id="phone" class="form__input" name="rentterm" placeholder="type in once off event or 1-6 month or > 6 months " onChange={rentTermHandler} maxLength='100' />

                                    {/*
                                    <select class='form__input' name='rentterm' onChange={rentTermHandler}>
                                        <option value='once'>Once Off Event</option>
                                        <option value='onetosixmth'>Short Term (1-6 months)</option>
                                        <option value='morethan6mth'>Long Term (> 6 month)</option>
                                    </select>
                                    */}
                                </div>
                            </div>


                            <div class="flex-md-12 mar-b-md-2">

                                <div class="form__group">
                                    <label for="subject" class="form__label">Subject <span class="color-danger">*</span></label>
                                    <input type="text" id="subject" class="form__input" name="subject" placeholder="i.e Facility Rent" onChange={subjectHandler} />
                                </div>
                            </div>
                            <div class="flex-md-12 mar-b-md-2">

                                <div class="form__group">
                                    <label for="message-2" class="form__label">Meeting Description<span class="color-danger">*</span></label>
                                    <textarea name="message" id="message-2" class="form__textarea form__input"
                                        placeholder="Briefly describe your rental purpose" onChange={messageHandler}></textarea>
                                </div>
                            </div>
                            <div class="flex-md-12">

                                <button class="button--primary button--fill" type="submit" name="submit">Send message</button>

                            </div>

                        </div>
                    </form>
                </div>
            </section>
        </React.Fragment>
    )
}

export default RentalForm2;
