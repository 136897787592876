import React from 'react';
import GetLoiSongHangNgay from './GetLoiSongHangNgay';

const VerseOfDay = (props) => {
    return (
        <React.Fragment>

            <div class="verse-of-the-day hide-on-sm show-on-md">

                <div class="row align-items-center">
                    <div class="flex-md-3 flex-lg-2">
                        <div class="background-secondary text-center-md verse-of-the-day-button">{ props.language==='English version'?'Lời Sống Hằng Ngày' : 'Verse of the day'}</div>
                    </div>
                    <div class="flex-md-7 flex-lg-6">
                        {/*<p class="italic" style={{color:'black'}}>{props.verse} &nbsp; 
	                    <span class="citation bold">{props.frombib}</span>
                        */}
                          <GetLoiSongHangNgay />
                    </div>
                    <div class="flex-md-2 flex-lg-4">
                        <p class="italic">
                            <span class="citation bold" ><a href={ props.language==='Vietnamese version'? 'https://loichua.church/': 'https://loichua.church/en'} style={{color:'red'}}>{props.language}</a></span>
                        </p>
                    </div>

                </div>

            </div>

        </React.Fragment>

    )
}
export default VerseOfDay;