import React, { useState, useEffect, useContext } from 'react';

//import VerseOfDay from './VerseOfDay';
import AuthContext from './store/auth';
import HeaderNavigator from './HeaderNavigator';
///import './css/w3.css';
//import TopSlideShort from './TopSlideShort';
//import AboutUs from './AboutUs';
import './SiteHeaderSlide.css';
const SiteHeaderWithSlide = (props) => {
    const useCtx = useContext(AuthContext);
    const [quote, setQuote] = useState('Be very careful, then, how you live—not as unwise but as wise, making the most of every opportunity, because the days are evil. Ephesians 5:15-16');
    //const [loading, setLoading] = useState(true);
    //const [author,setAuthor]= useState('');
    //const [imgSrc,setImgSrc]= useState('');

    useEffect(() => {
        //getQuote(); //turn off so we can see the padlock on loichua.org for now
        const intervalID = setInterval(() => {
            getQuote()
        }, 24 * 60 * 60 * 1000);
        return () => {
            clearInterval(intervalID);
        }
    });

    function getQuote() {
        fetch(useCtx.quoteURL)
            .then(res => res.json())
            .then(data => {
                console.log(data);
                setQuote(data.verse);

            }).catch(error => {
                //Here is still promise
                console.log(error);

            })
    }

    return (

        <React.Fragment>

            <header class="header light-text" data-onscroll-classes="fixed-header animate-header">


                <div class="header__top-nav background-off-white">

                    <div class="container">

                        <div class="verse-of-the-day hide-on-md">

                            <button class="button button--secondary button--fill button-block-sm no-shadow modal-button"
                                data-modal-target="verse-of-the-day">Click to see the verse of the day</button>

                        </div>
                        <div class="verse-of-the-day hide-on-sm show-on-md">

                            <div class="row align-items-center">
                                <div class="flex-md-3 flex-lg-2">
                                    <div class="background-secondary text-center-md verse-of-the-day-button">Listen & Read On</div>
                                </div>
                                <div class="flex-md-9 flex-lg-10">
                                    <div class="marquee">
                                        <p> <a href='/read-dailyhope' style={{color:'red'}}>Read Daily Hope</a> | <a href='/' style={{color:'red'}}>Vietnamese Christian Broadcast</a>  </p>
                                    </div>


                                </div>
                            </div>

                        </div>


                    </div>
                </div>















                <HeaderNavigator />



            </header>


        </React.Fragment>
    )


}

export default SiteHeaderWithSlide;
