import React from 'react';
import './ComingSoon.css';
const ComingSoon = (props) => {
    return (
        <React.Fragment>
            <header class="header light-text fixed">

                <nav class="bottom-nav">

                    <div class="container">

                        <div class="header__nav display-flex-column justify-align-center pad-t-sm-2 pad-b-sm-2">

                            <div class="header__logo brand--logo">
                                {/*<a href="index.html"><img src="images/brand-logo-light.png" alt="Faith in Christ Church"></a> */}
                                <h1 style={{ marginBottom: '2.5rem', fontSize: '2.5em', color: 'white' }}> <a href='/'>LoiChua.Church</a></h1>
                            </div>

                        </div>
                    </div>
                </nav>
            </header>
            {/* Main Section*/}
            <main>


                <section class="coming-soon banner display-flex-column justify-align-center text-center-sm">

                    <div class="container">

                        <div class="banner__content">

                            <div class="section-heading">
                                <br />
                                <h1 class="special">Coming soon</h1><br />
                                <h1 class="mar-b-sm-4 mar-b-xl-6">We are currently working on something awesome and it will be available
                soon!</h1>
                                <h2 class="regular-weight">Please enter your email to be informed on this page
                </h2>
                            </div>
                            <form class="form form--subscription mar-b-sm-6 mar-b-xl-8">

                                <div class="row">

                                    <div class="flex-lg-9 flex-xl-10">
                                        <div class="form__group">
                                            <input type="email" placeholder="Enter your email address" class="form__input" />
                                        </div>
                                    </div>
                                    <div class="flex-lg-3 flex-xl-2">
                                        <button type="submit" class="button--primary button--fill button-block-lg">Notify me</button>
                                    </div>
                                </div>
                            </form>
                            <div class="single-page__share">

                                <div class="display-flex justify-align-center">

                                    <div class="bold leading text">Follow us</div>

                                    <div class="social">
                                        <a href="/" class="transparent button button--primary button--outline share-button"><i
                                            class="ri-facebook-line"></i></a>
                                    </div>
                                    <div class="social">
                                        <a href="/" class="transparent button button--primary button--outline share-button"><i
                                            class="ri-linkedin-line"></i></a>
                                    </div>
                                    <div class="social">
                                        <a href="/" class="transparent button button--primary button--outline share-button"><i
                                            class="ri-twitter-line"></i></a>
                                    </div>
                                    <div class="social">
                                        <a href="/" class="transparent button button--primary button--outline share-button"><i
                                            class="ri-instagram-line"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>

        </React.Fragment>
    )
}

export default ComingSoon