import React from 'react'
//import Image from 'next/image'

const NavbarViet = () => {

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <a className="navbar-brand" href="/"><img src='/assets/images-cus/Htlc-Church-Logo.png' width='150px' height='auto' alt='Houston Vietnamese Christian Church' className="d-inline-block align-text-top" /></a>
                <button className="navbar-toggler" style={{ background: 'white' }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/indexvn">Trang Nhà</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" href="/about-httl-loichua">Khải Tượng </a>
                        </li>


                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Mục Vụ
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="/yem">YEM-Thanh Thiếu Niên</a></li>
                                <li><a className="dropdown-item" href="/children-ministry">Thiếu Nhi</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" href="/coming-soon">Ban Phụ Nữ</a></li>
                                <li><a className="dropdown-item" href="/coming-soon">Ban Nam giới</a></li>
                                <li><a className="dropdown-item" href="/laos-mission">Truyền giáo</a></li>

                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Truyền thông
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="https://www.youtube.com/c/htloichuatx">YouTube Channels</a></li>
                                <li><a className="dropdown-item" href="https://thuvien.loichua.church/hoi-thanh-tin-lanh-loi-chua-facebook/">Facebook</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" href="https://thuvien.loichua.church">Thư viện Lời Chúa</a></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">Lời Chứng</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/song-dung-muc-dich">Audio</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://thuvien.loichua.church/youtube-channel/">Video</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/DangHien">Dâng hiến</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/lich-sinh-hoat-hoi-thanh">Lịch Sinh Hoạt</a>
                        </li>

                        {/*
                        <li className="nav-item">
                            <a className="nav-link disabled">Disabled</a>
                        </li>
                        */}
                    </ul>
                    {/*
                    <form className="d-flex">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button className="btn btn-outline-success" type="submit">Search</button>
                    </form>
                    */}
                </div>
            </div>
        </nav>
    )
}
const Navbar = (props) => {

    if (props.lang === 'English') {

        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/"><img src='/assets/images-cus/Htlc-Church-Logo.png' width='150px' height='auto' alt='Houston Vietnamese Christian Church' className="d-inline-block align-text-top" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/indexvn">Home</a>
                            </li>


                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Ministries
                            </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a className="dropdown-item" href="/yem">YEM-Youth English Ministry</a></li>
                                    <li><a className="dropdown-item" href="/children-ministry">Children's Ministry</a></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" href="/coming-soon">Women's Ministry</a></li>
                                    <li><a className="dropdown-item" href="/coming-soon">Men's Ministry</a></li>
                                    <li><a className="dropdown-item" href="/laos-mission">Lao Mission</a></li>

                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Media
                            </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a className="dropdown-item" href="https://www.youtube.com/c/htloichuatx">YouTube Channels</a></li>
                                    <li><a className="dropdown-item" href="https://thuvien.loichua.church/hoi-thanh-tin-lanh-loi-chua-facebook/">Facebook</a></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" href="https://thuvien.loichua.church">Loi Chua's Libary</a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/">Testimonies</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/song-dung-muc-dich">Audio</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://thuvien.loichua.church/youtube-channel/">Video</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/DangHien">Give</a>
                            </li>
                            {/*
                        <li className="nav-item">
                            <a className="nav-link disabled">Disabled</a>
                        </li>
                        */}
                        </ul>
                        {/*
                    <form className="d-flex">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button className="btn btn-outline-success" type="submit">Search</button>
                    </form>
                    */}
                    </div>
                </div>
            </nav>
        )
    }
    else {
        return <NavbarViet />
    }



}
export default Navbar