/**
 * Page as container for all audio files of devotions by RickWarren
 * from the outbound site
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import SiteHeader from './SiteHeader';
import ScrollBack from './ScrollBack';
import SmallTeamFooter from './SmallTeamFooter';
import BannerAudioDevoRWContainer from './BannerAudioDevoRWContainer';
import AudioDevoRickW from './AudioDevoRickW';

const AudioDevoRWContainer = (props) => {

    return (
        <div>
            <Helmet>
                <title>Song dung muc dich -Hội Thánh Tin Lành Lời Chúa</title>
                <meta name='description' content='Mời bạn cùng đồng hành với chúng tôi 40 ngày để thay đổi và sống đúng mục đích theo quan điểm Tin lành bởi Rick Warren.  Hội Thánh Tin Lành Lời Chúa' />
                <meta name='keywords' content='tin lanh, song dung muc dich, purposed driven life, CMA , Houston, loi chua, nguoi viet' />


            </Helmet>
            <SiteHeader />
            <main>
                <BannerAudioDevoRWContainer />
                <AudioDevoRickW />
            </main>

            <ScrollBack />
            <SmallTeamFooter />
        </div>
    )

}

export default AudioDevoRWContainer;