/** Common component for pop up style */
import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

//style it with predefined css 
const styles = {
  popupContent: {
    backgroundColor: "lightblue",
    padding: 20,
    marginLeft:'13%',
    border: "1px solid #ddd",
    borderRadius: 5,
    width:'50%'
  },
  popupOverlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
}
export default function MyPopUp({btnLabel , data}){
return(
<>
	{/*  Note that row or anything can be passed as param or props.  Here data is object of list in js  */}
	<Popup trigger={<button> {btnLabel} </button>}  contentStyle={styles.popupContent}  position="bottom" >
			<div className='container-fluid'>
			     {data}
			</div>
	
	</Popup>
</>
)
}