import React from 'react';
import HeaderNavigator from './HeaderNavigator';
//import FooterSimple from './FooterSimple';
import './ChildrenMinistry.css';
import VideoChildrenBackground from './VideoChildrenBackground';
import SmallTeamFooter from './SmallTeamFooter';
const ManMinistry = (props) => {
    return (
        <React.Fragment>
            <header class="header light-text" data-onscroll-classes="fixed-header animate-header">

                <div class="header__top-nav background-off-white">

                    <div class="container">
                        <div class="verse-of-the-day hide-on-md">

                            <button class="button button--secondary button--fill button-block-sm no-shadow modal-button"
                                data-modal-target="verse-of-the-day">Click to see the verse of the day</button>

                        </div>
                        <div class="verse-of-the-day hide-on-sm show-on-md">

                            <div class="row align-items-center">
                                <div class="flex-md-3 flex-lg-2">
                                    <div class="background-secondary text-center-md verse-of-the-day-button">Verse of the day</div>
                                </div>

                                <div class="flex-md-9 flex-lg-10">
                                    <p class="italic">Rejoice always, pray without ceasing, give thanks in all circumstances; for this is
                  the will of God in Christ Jesus for you. <span class="citation bold">~1 Thessalonians 5:16-18</span>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <HeaderNavigator />
            </header>
            <main>

                <VideoChildrenBackground />

                <section class="single-page single-ministry default-section-spacing">

                    <div class="container">

                        <div class="description pad-b-sm-4">

                            <div class="section-heading">
                                <span class="section-intro">Description</span>
                                <h2>&nbsp;</h2>
                            </div>
                            <p>
                                Mục Vụ Thiếu Nhi là nơi các em từ 3 tuổi đến 12 tuổi được vui chơi,
                                làm thủ công và học hỏi về Lời Chúa dạy trong Kinh Thánh.
                                Một môi trường lành mạnh để các em được nuôi dưỡng và lớn lên
                                trong tình yêu Chúa. Mời quí phụ huynh mang con em mình đến với chúng tôi.
                            </p>
                            <p>
                                The Children's Ministry is a place where children aged 3 to 12 can play, craft
                                and learn about God's Word in the Bible. A healthy environment for children
                                to be nurtured and raised in God's love. We invite you to bring your children to join us on Sunday.
                            </p>

                        </div>
                        <div class="responsibilities pad-b-sm-4">

                            <div class="section-heading">
                                <span class="section-intro">Weekly Program</span>
                                <h2>&nbsp;</h2>
                                <p>Every Sunday at 10:30AM</p>
                            </div>
                            <p></p>
                            <p>
                            </p>

                        </div>
                        <div class="details pad-b-sm-2">

                            <div class="section-heading">
                                <span class="section-intro">Details</span>
                                <h2>&nbsp;</h2>
                            </div>
                            <div class="row">

                                <div class="flex-md-6 flex-lg-4">

                                    <div class="icon-text mar-b-sm-06">
                                        <span class="icon"><i class="ri-user-star-line"></i></span>
                                        <span class="text bold">Leads by Mrs Hanh Dang</span>
                                    </div>
                                    <div class="icon-text mar-b-sm-06">
                                        <span class="icon"><i class="ri-group-line"></i></span>
                                        <span class="text bold">25 members</span>
                                    </div>
                                    <div class="icon-text">
                                        <span class="icon"><i class="ri-calendar-line"></i></span>
                                        <span class="text bold">Every Sunday @ 10:30AM</span>
                                    </div>
                                </div>
                                <div class="flex-md-6 flex-lg-4">

                                    <div class="icon-text mar-b-sm-06">
                                        <span class="icon"><i class="ri-phone-line"></i></span>
                                        <span class="text bold">713-239-1466</span>
                                    </div>

                                    <div class="icon-text mar-b-sm-06">
                                        <span class="icon"><i class="ri-mail-line"></i></span>
                                        <span class="text bold">htloichua@gmail.com </span>
                                    </div>
                                    <div class="icon-text">
                                        <span class="icon"><i class="ri-map-pin-line"></i></span>
                                        <span class="text bold">3134 Frick Rd, Houston , TX 77038</span>
                                    </div>
                                </div>
                                <div class="flex-md-12 flex-lg-4 mar-t-md-2 mar-t-lg-0">

                                    <div class="icon-text">
                                        <span class="icon"><i class="ri-money-dollar-circle-line"></i></span>
                                        <span class="text bold">FREE to join</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single-page__share background-off-white pad-t-sm-2 pad-b-sm-2">

                        <div class="container">

                            <div class="display-flex align-items-center">

                                <div class="bold leading text">Share</div>

                                <div class="social">
                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                        class="ri-facebook-line"></i></a>
                                </div>
                                <div class="social">
                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                        class="ri-linkedin-line"></i></a>
                                </div>
                                <div class="social">
                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                        class="ri-twitter-line"></i></a>
                                </div>
                                <div class="social">
                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                        class="ri-instagram-line"></i></a>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="single-ministry__cta text-center-sm color-off-white default-section-spacing">

                        <h2 class="mar-b-sm-2">We invite you to bring your kids to our Children's ministry.<br /> God is with you</h2>
                        <a href="/contact" class="button button--primary button--fill">Join today</a>

                    </div>

                </section>
            </main>

            <SmallTeamFooter />


        </React.Fragment>
    )
}
export default ManMinistry;