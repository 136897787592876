import React from 'react';
//import { getClassSet } from 'react-bootstrap/lib/utils/bootstrapUtils';

const AuthContext = React.createContext({
    version: '2.13-with-login-implemented',
    quoteURL: 'https://api.loichua.church:5001/main/get-bible-verse',
    youtubeURL: 'https://www.youtube.com/c/htloichuatx',
    blogURL: 'https://htloichua.blogspot.com/',
    facebookURL: 'https://www.facebook.com/HTLoiChua',
    sendEmailURL: 'https://api.loichua.church:5001/main/send-email',
    //sendEmailRentalURL: 'https://api.loichua.church:5001/main/send-email-rental',
    sendEmailRentalURL2: 'https://api.loichua.church:5001/main/send-email-rental2',
    sendJobSubmissionURL: 'https://api.loichua.church:5001/main/send-job-submission',
    submitReimbursement: 'https://api.loichua.church:5001/main/send-email-reimbursement',

    successURL: 'https://loichua.church/success',
    scrapingUrl: 'https://api.loichua.church:5001/main/get-video-list',
    urlPTTL: 'https://tinlanh.org/WeeklyPrograms/RadioSaigonHouston/',
    apiBaseURL: 'https://api.loichua.church:5001',
    dailyDevoRickWURL: 'https://archive.org/details/SongTheoDungMucDich__Rick_Warren',
    loginUrl: 'https://loichua.church/login',
    sendCampData: 'https://api.loichua.church:5001/main/send-data-camp',
    sendHoiDongData: 'https://api.loichua.church:5001/main/send-hoidong-data',
    
    getCampUserAll: 'https://api.loichua.church:5001/main/get-camp-users',
    updateCampUser: 'https://api.loichua.church:5001/main/update-camp-user',
    updateHDUser:'https://api.loichua.church:5001/main/update-hdgh-user',
    removeRegistrationUrl: 'https://api.loichua.church:5001/main/remove-camp-user',
    removeHDRegistrationUrl: 'https://api.loichua.church:5001/main/remove-hdgh-user',
    
    getCampDataBy: 'https://api.loichua.church:5001/main/get-camp-data-by',
    getHDDataBy: 'https://api.loichua.church:5001/main/get-hoidong-data-by',
    
    getCsvCampDataUrl: 'https://api.loichua.church:5001/static/tmp',
    userListingUrl: 'https://loichua.church/login',
    hdListingUrl:'https://loichua.church/hoidong2024/listing/login' ,
    updateRoomUrl: 'https://api.loichua.church:5001/main/update-room',
    updateHotelRoomUrl: 'https://api.loichua.church:5001/main/update-hotel-room',
    
    getHoiDongUserAll:'https://api.loichua.church:5001/main/get-hoidong-users',
    getRoomStatUrl:'https://api.loichua.church:5001/main/get-room-stat',
    mailUrl:'https://api.loichua.church:5001/main/send-email-by-group'
})
export default AuthContext;