import React from 'react';
import './css/common.css';
import SiteHeaderVN from './SiteHeaderVN';
const DangHien = () => {

    return (
        <>
            <SiteHeaderVN />
            <section className="single-page single-ministry default-section-spacing">

                <div className="container">

                    <div className="description pad-b-sm-4">

                        <div class="section-heading">
                            <span className="section-intro"><a href='/DangHien' style={{ color: 'darkblue' }}>Dâng Hiến</a> &nbsp;| &nbsp; <a href='/donations' style={{ color: 'darkblue' }}>Donation</a></span>
                            <p className='left-align-text'>
                                Mùa Tạ Ơn và Giáng Sinh lại đến, Hội thánh chúng ta có nhiều điều để tạ ơn trong năm vừa qua, trong tinh thần tạ ơn, kính xin quí con dân Chúa
                                sẻ hổ trợ giúp đỡ trong truyền giáo, các mục vụ và các dự án sửa chửa cần thiết trong cơ sở HT sắp đến.
                            </p>
                            <p className='left-align-text'>
                                Với tháng còn lại trong năm nay và hội đồng truyền giáo ngày 12/04/2022 (Dec 4 2022), chúng tôi kêu gọi sự dâng hiến nhiệt thành của con dân Chúa cho hội thánh và công tác truyền giáo
                                toàn cầu.
                                <br />
                                Mọi dâng hiến đều đươc khấu trừ thuế ( tax-deductible).  Xin cảm ơn tấm lòng rời rộng của quí vị góp phần tiếp tục xây dựng Vương Quốc của Đức Chúa Trời và cho vinh hiển Danh Ngài.
                            </p>
                            <h2>Các hình thức dâng hiến:</h2>
                        </div>
                        <p className='left-align-text'>
                            <span className='section-intro'>1. Thẻ tín dụng (Visa/MasterCard)</span>
                            <br /><br />
                            <a href="https://app.easytithe.com/app/giving/loichua" style={{ backgroundColor: "", fontSize: "0.8em" }}>
                                <span className='section-intro'>&nbsp;&nbsp; Quỹ điều hành HT - Nhấn vào đây...</span></a>
                            <br />
                            <a href="https://app.easytithe.com/App/Form/0b8bdbd9-fffc-46f5-b192-fa75ba0340ce" style={{ backgroundColor: "" }}>
                                <span className='section-intro'>&nbsp;&nbsp; Quỹ Xây Dựng -Nhấn vào đây...</span></a>
                            <br />

                            <a href="https://app.easytithe.com/App/Form/dd078c94-3dfc-43b6-9b96-cb4c43d27fe9" style={{ backgroundColor: "" }}>
                                <span className='section-intro'>&nbsp;&nbsp; Quỹ Truyền Giáo -Nhấn vào đây...</span></a>
                            <br />

                            <a href="https://app.easytithe.com/App/Form/c5af05b8-de08-4d87-b902-5aaa4249ead6" style={{ backgroundColor: "" }}>
                                <span className='section-intro'>&nbsp;&nbsp; Mục vụ đặc biệt (xã hội, mồ côi) - Nhấn vào đây...</span></a>
                            <br />
                        </p>
                        <p className='left-align-text'>
                            <span className='section-intro'>2. Tiền mặt hoặc CHECKS</span>
                            <br /><br />
                            Xin viết check cho "Hội Thánh Tin Lành Lời Chúa" hoặc HTLC
                            <br />và bỏ vào hộp dâng hiến trong giò thờ phượng hoặc gửi về địa chỉ dưới đây:
                        </p>
                        <p className='left-align-text'>
                            Hoi Thanh Tin Lanh Loi Chua<br />
                            3134 Frick Rd<br />
                            Houston<br />
                            TX 77038
                        </p>
                        <p className='left-align-text'>
                            <span class='section-intro'>3. ZELLE (Thanh toán điện tử)</span><br /><br />
                            Xin Zelle qua điện thư:  giving@loichua.church.<br />
                            Muôn biết thêm về Zelle, xin nhấp vào <a href='https://www.zellepay.com/?gclid=Cj0KCQiA-K2MBhC-ARIsAMtLKRtmTIuFkxLvtcfxm_1Ew7hCxmWDA9ZJDV5nUdOiRhLr1I8P0Q9EdEwaAjrtEALw_wcB'>please visit this official Zelle site</a>
                        </p>
                    </div>
                    <div className="responsibilities pad-b-sm-4">

                        <div className="section-heading">
                            <span className="section-intro">Weekly Program</span>
                            <h2>&nbsp;</h2>
                            <p>Every Sunday at 10:30AM</p>
                        </div>
                        <p></p>
                        <p>
                        </p>
                    </div>
                    <div className="details pad-b-sm-2">
                        <div className="section-heading">
                            <span className="section-intro">Details</span>
                            <h2>&nbsp;</h2>
                        </div>
                        <div className="row">

                            <div className="flex-md-6 flex-lg-4">

                                <div className="icon-text mar-b-sm-06">
                                    <span className="icon"><i class="ri-user-star-line"></i></span>
                                    <span className="text bold">Pastor Le P Thuan</span>
                                </div>
                                <div className="icon-text mar-b-sm-06">
                                    <span className="icon"><i class="ri-group-line"></i></span>
                                    <span className="text bold">150 members </span>
                                </div>
                                <div className="icon-text">
                                    <span className="icon"><i className="ri-calendar-line"></i></span>
                                    <span className="text bold">Every Sunday @ 10:30AM</span>
                                </div>
                            </div>
                            <div className="flex-md-6 flex-lg-4">

                                <div className="icon-text mar-b-sm-06">
                                    <span className="icon"><i className="ri-phone-line"></i></span>
                                    <span className="text bold">713-239-1466</span>
                                </div>

                                <div className="icon-text mar-b-sm-06">
                                    <span className="icon"><i className="ri-mail-line"></i></span>
                                    <span className="text bold">info@loichua.church </span>
                                </div>
                                <div className="icon-text">
                                    <span className="icon"><i className="ri-map-pin-line"></i></span>
                                    <span className="text bold">3134 Frick Rd, Houston , TX 77038</span>
                                </div>
                            </div>
                            <div className="flex-md-12 flex-lg-4 mar-t-md-2 mar-t-lg-0">

                                <div className="icon-text">
                                    <span className="icon"><i className="ri-money-dollar-circle-line"></i></span>
                                    <span className="text bold">FREE to join</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="single-page__share background-off-white pad-t-sm-2 pad-b-sm-2">

                    <div className="container">

                        <div className="display-flex align-items-center">

                            <div className="bold leading text">Share</div>

                            <div className="social">
                                <a href="/" className="transparent button button--primary button--outline share-button"><i
                                    className="ri-facebook-line"></i></a>
                            </div>
                            <div className="social">
                                <a href="/" className="transparent button button--primary button--outline share-button"><i
                                    className="ri-linkedin-line"></i></a>
                            </div>
                            <div className="social">
                                <a href="/" className="transparent button button--primary button--outline share-button"><i
                                    className="ri-twitter-line"></i></a>
                            </div>
                            <div className="social">
                                <a href="/" className="transparent button button--primary button--outline share-button"><i
                                    className="ri-instagram-line"></i></a>
                            </div>

                        </div>

                    </div>

                </div>

                <div className="donation_bg text-center-sm color-off-white default-section-spacing">

                    <h3 className="mar-b-sm-2">Mỗi người nên tùy theo lòng mình đã định mà quyên ra, không phải phàn nàn hay là vì ép uổng;
                        vì Đức Chúa Trời yêu kẻ dâng của cách vui lòng (II Cô-rinh-tô 9:7)</h3>
                    <a href="https://app.easytithe.com/app/giving/loichua" className="button button--primary button--fill">Give today</a>

                </div>

            </section>

        </>
    )
}

export default DangHien;