import React from 'react';
import './SiteMap.css'
const SiteMap = (props) => {
    return (
        <div className="w3-container w3-deep-orange">
            <h2>LoiChua.Church - Mobile Site Map</h2>
            <p>&nbsp;</p>
            <ul className="w3-ul w3-border li-big-white">
                <li><a href='/'>Home-Trang Nhà</a></li>
                <li><a href='/yem'>YEM Ministries- Thanh niên</a></li>
                <li><a href='/children-ministry'>Children Ministries - Thiếu nhi</a></li>
                {/*<li><a href='/event'>Events - Sự kiện</a></li>
                <li><a href='/giang-sinh-2021'>Christmas Celebration Program 2021 - Giáng Sinh 2021</a></li>
                
                <li><a href='/phat-thanh-tin-lanh-houston-texas'>Phat Thanh Tin Lanh</a></li>
                <li><a href='/phat-thanh-lien-huu-tin-lanh'>Phat Thanh Lien Huu Tin Lanh</a></li>
                 
                <li><a href='/song-dung-muc-dich'>40 days to change your life - 40 ngày thay đổi đời sống bạn</a></li>
                
                <li><a href='/video-list'>Past Sermons - Bài giảng</a></li>
                <li><a href='/truyen-ngan'>Tuyển Tập Truyện Ngắn</a></li>
                */}
                <li><a href='https://thuvien.loichua.church/thoi-bieu-sinh-hoat-6-thang-dau-nam-2022/'>Sinh hoạt 6 tháng đầu năm 2022</a></li>
                <li><a href='https://thuvien.loichua.church/youtube-channel'>Video Bài giảng</a></li>
                <li><a href='https://thuvien.loichua.church/nhac-thanh-ca-chon-loc/'>Nhạc Thánh Ca Chọn Lọc</a></li>
                <li><a href='https://thuvien.loichua.church/tuyen-tap/'>Tuyển Tập Truyện Ngắn</a></li>
                <li><a href='https://thuvien.loichua.church/hoi-thanh-tin-lanh-loi-chua-facebook/'>Facebook</a></li>





                {/*
                <li><a href='/bai-chon-loc'>Selected Articles - Bài Chọn Lọc</a></li>
                <li><a href='/read-dailyhope'>Read Daily Devotion - Dưỡng Linh</a></li>
                */}
                <li><a href='/contact'>Contact - Liên lạc</a></li>
                <li><a href='/facebook-page'>LoiChua Facebook</a></li>
                <li><a href='https://thuvien.loichua.church'>Thư viện Lời Chúa</a></li>


            </ul>
        </div>
    )
}
export default SiteMap;