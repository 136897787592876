import React, { useEffect, useState } from 'react';
//import axios from 'axios';
//import parse from 'html-react-parser';
const Magazine = () => {
    const [article, setArticle] = useState([' New article is loading...please wait'])
    useEffect(() => {

        fetch("https://api.loichua.church:5001/main/get-dailyhope-article").then(res => res.json()).then(data => {
            console.log(data.data);
            const dataList = (data.data).split('\n\r');
            console.log('i got data list\n ' + dataList);
            setArticle(dataList);
        }).catch(error => {
            console.log(error)
        }

        )//end catch
    }, [])

    return (
        <>
            {
                article && article.map((a) => {
                    return (
                        <div key={a.id}><p style={{ textAlign: 'left', fontSize: '1.5rem' }}>{a}<br /><br /></p></div>
                    )

                })
            }


        </>
    )
}

export default Magazine;