import React, { useState } from 'react';
//import { useHistory } from 'react-router-dom';

import '../shortstories/ShortStories.css';

//may get it from rest api later for wp rest api
const stories = [
   // { url: 'https://yem.life/fall', title: 'Thu Vàng' },
   // { url: 'https://yem.life/ve-mot-giong-song', title: 'Về Một Giòng Sông' },
    { url:'https://lib.tinlanhlibrary.info/niem-vui-giang-sinh', title:'Niềm Vui Giáng Sinh'}
];


const SelectedArticleListing = (props) => {
    const [urlAt, setUrlAt] = useState('https://lib.tinlanhlibrary.info/niem-vui-giang-sinh');
    const clickHandler = (e) => {
        e.preventDefault();
        console.log('clicked on ' + e.currentTarget);
        //history.push('/sitemap');
        setUrlAt(e.currentTarget);

    }
    return (
        <>
            <div class='w3-container'>
                <ul className='short-stories'>
                    {/*
                    <li><a href="#home">Home</a></li>
                    <li><a href="#news">News</a></li>
                    <li><a href="#contact">Contact</a></li>
                    <li><a href="#about">About</a></li>
                    */}
                    <li><a href='/'>Trang Nhà</a></li>
                    {stories.map((d) => {
                        return (
                            <>
                                <li key={d.id} ><a href={d.url}  onClick={clickHandler}> {d.title}</a></li>






                            </>
                        )

                    })

                    }
                     <li><div class="sharethis-inline-share-buttons"></div></li>
                </ul>
            </div>
            
            
            
            
              
                <div class='w3-container iframe-container'><iframe class='responsive-iframe' src={urlAt} title='truyen ngan' /></div>
            



        </>
    )
}

export default SelectedArticleListing;