import React from 'react';
import mystyle from './css/loichuastyle.module.css'

const ChurchHistoryContent = () => {
    const threePointStyle = {
        textAlign: 'left',

    }
    return (
        <>
            <section class="welcome welcome--2 welcome--about pad-t-sm-2 pad-b-sm-2 pad-t-lg-4 pad-b-lg-4">

                <div class="welcome__content">

                    <div class="container">

                        <div class="row align-items-center default-section-spacing pad-b-sm-0 mar-b-lg-4">

                            <div class="flex-lg-6" style={threePointStyle}>

                                <div class="section-heading" >
                                    <span class="section-intro">WELCOME</span>
                                    <h2>Chào mừng quý vị và các bạn đến với website Hội Thánh Tin lành Lời Chúa</h2>
                                </div>

                                <h2>&nbsp;</h2>
                                <p className={mystyle.card_font}>
                                    Hội thánh Tin lành Lời Chúa có cơ sở tọa lạc tại 3134 Frick rd. Houston Texas
                                    <br/>
                                    Nhà thờ này do người Mỹ thành lập từ những năm 50 của thế kỷ trước. Với biểu tượng là một ngọn hải đăng. Và tên hội thánh là Hội thánh Báp tít Hải đăng. Năm 2011, Hội thánh Mỹ nầy đã dời đi nơi khác, và chuyển giao cơ sở lại cho Hội thánh Lời Chúa.
                                    <br/>
                                    Sứ mạng của HT Lời Chúa là đem lời chúa đến cho cộng đồng dân cư đủ mọi sắc dân đủ mọi thứ tiếng, tại Bắc houston trong đó ngôn ngữ chính là Việt Nam.
                                    <br/>
                                    Ngọn hải đăng nằm trước nhà thờ nói lên chân lý từ Kinh thánh “Lời Chúa là ngọn đèn cho chân tôi, ánh sáng cho đường lối tôi”.
<br/>
Lời Chúa là khuôn vàng thước ngọc soi đường chỉ lối và thiết lập hướng đi cho cuộc đời.
<br/>
Trong Lời Chúa con người tìm được mục đích của cuộc sống, để có thể sống một cuộc đời có ý nghĩa và ích lợi cho cộng đồng.
<br/>
Chúng tôi quy tụ những người khi sanh ra là tội nhân, đã gặp gỡ Chúa Giê xu, cuộc đời được đổi mới, cam kết theo Chúa, và thực hiện lời dạy của Ngài.
<br/>
Chúng tôi có cùng một sứ mạng là đem Lời Chúa đến với mọi người, phục vụ cộng đồng, và giúp họ trở nên người mới, sống có mục đích, hướng đi, trong tình yêu thương tương trợ, trong sự bình an, lạc quan và hy vọng.
<br/>
Hãy đến với chúng tôi!
<br/>
Website www.loichua.church là phương tiện chúng ta gặp gỡ giao lưu, tìm hiểu lời Chúa và thực hiện mục đích, sứ mạng của cuộc sống.
<br/>
Mong nhận được ý kiến và đóng góp của các bạn.
<br/>
Chúc các bạn những giây phút khoan khoái khi thưởng thức lời Chúa và những món ăn tinh thần bổ ích trong website nầy.
<br/>
<blockquote>
Houston, Thanksgiving 25/11/2021
MS LÊ PHƯỚC THUẬN

</blockquote>



                                  </p>

                               

                                {/*}
                                <p><span style={{ fontSize: 'x-large' }}><strong>GIỚI THIỆU GIA ĐÌNH PHÚC ÂM LIÊN HIỆP</strong></span></p>
                                <p><span style={{ fontSize: 'medium' }}>HTTL Lời chúa là một phần của Phúc Âm Liên Hiệp Hoa kỳ , và Chúa Giêsu là trung tâm điểm, với hơn 6 triệu tín hữu trung kiên.  Phát xuất từ tình yêu của Chúa Giêsus và năng quyền của Đức Thánh Linh, là lý do gia đình Phúc Âm Liên Hiệp sẵn sàng đi ra, mang sứ điệp Tin Lành đến những vùng lân cận cho đến khắp nơi trên thế giới (Công vụ 1: 8) </span></p>

                                <p><strong><span style={{ fontSize: 'medium' }}>CHÚA GIÊ XU LÀ TRUNG TÂM ĐIỂM</span></strong></p>
                                <p><span style={{ fontSize: 'medium' }}>HTTL Lời chúa tôn Chúa Giê xu là Đấng cầm quyền trên mọi thế lực, và Ngài là Đấng sáng tạo vũ trụ (Cô-lô-se 2: 9-10).  Trong Chúa Giêsu, mọi người nhận được sự thoả vui, hạnh phúc tràn đầy.
                                 </span></p>
                                <p><strong><span style={{ fontSize: 'medium' }}>CHÚNG TÔI DỰA VÀO QUYỀN NĂNG CỦA ĐỨC THÁNH LINH<br /></span></strong></p>
                                <p><span style={{ fontSize: 'medium' }}> Công-vụ 1: 8 là niềm khích lệ cho chúng tôi:  </span><em>
                                    <span style={{ fontSize: 'large' }}>&#8216;Nhưng khi Đức Thánh Linh giáng trên các ngươi, thì các ngươi sẽ nhận lấy quyền phép
              và làm chứng về ta tại thành Giê-ru-sa-lem, cả xứ Giu-đê, xứ Sa-ma-ri, cho đến cùng trái đất&#8217;.</span></em><br /></p>
                                <p><span style={{ fontSize: 'medium' }}>Chỉ bởi quyền năng mầu nhiệm của Đức Thánh Linh, giúp chúng tôi có thể thể hiện tình yêu thương, lòng nhân ái và sự thương xót của Chúa Giêsu đến những người không biết Ngài.</span></p>
                                <p><strong><span style={{ fontSize: 'medium' }}>CHÚNG TÔI LÀ MỘT GIA ĐÌNH<br /></span></strong></p>

                                <p><span style={{ fontSize: 'medium' }}>Chúng tôi là một phần của Hội Thánh Đức Chúa Trời, có nghĩa chúng tôi đã được
                       chọn lựa để thực hiện mục đích cứu rỗi của Ngài trên thế gian, khôi phục lại thế giới hư mất cho đến khi Chúa trở lại.<br />
Là thành viên của Liên Minh Thế Giới, Hội Thánh Phước Âm Liên Hiệp là một trong những mạng lưới kết hợp các nhà thờ khắp nơi, tạo nên một đại gia đình trên thế giới.<br />
                                </span><span style={{ fontSize: 'large' }}> <em>&#8216;Thì cũng vậy, chúng ta là nhiều người mà hiệp nên một thân trong Đấng Christ,  và hết thảy chúng ta đều là các phần chi thể của nhau&#8217;
  </em>  (Rô-ma 12: 5)<br />
                                    </span><br /></p>
                           */}

                            </div>
                            <div class="flex-lg-6">

                                <div class="image-box mar-t-sm-8 mar-t-lg-0 mar-b-sm-8 mar-b-lg-0">

                                    <div class="box box--center">
                                        <img src="assets/images-cus/andrew-yem-standing.png" alt="" />
                                    </div>
                                    <div class="box box--top-right">
                                        <img src="assets/images/happy-kid.jpg" alt="" />
                                    </div>
                                    <div class="box box--bottom-right">
                                        <img src="assets/images/singing-girl.jpg" alt="" />
                                    </div>
                                    <div class="box box--top-left">
                                        <img src="assets/images-cus/church-front.jpeg" alt="" />
                                    </div>
                                    <div class="box box--bottom-left">
                                        <img src="assets/images/bible-sm.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default ChurchHistoryContent;