import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import AuthContext from './store/auth';
import './AudioDevoRickW.css';

const AudioDevoRickW = (props) => {
    const useCtx = useContext(AuthContext);
    const [audioListItems, setAudioListItems] = useState([]);
    const [header, setHeader] = useState();
    const [title, setTitle] = useState();
    const [source, setSource] = useState();
    const audioRef = useRef();

    useEffect(() => {
        extractHandler();
    })

    const extractHandler = () => {
        //const instance = axios.create({
        //    baseURL: useCtx.apiBaseUrl
        //});
          
        //  "/main/testing" dailyDevoRickWURL
        axios.post("https://api.loichua.church:5001/main/audio-devo-rick-warren", { 'audioUrl': useCtx.dailyDevoRickWURL })
        .then(
            res => {
                console.log('got resp ' + res.data.status);
                console.log('got resp message ' + res.data.message);
                console.log('got resp data ' + JSON.stringify(res));
                console.log('got resp header ' + res.data.header);
                // give audios here as list
                setAudioListItems(res.data.data);
                setHeader(res.data.header);
            }
        ).catch(err => {
            console.log('got error ' + err);
        })
    }

    const handleClick = (data) => {
        // console.log(data.media);
        setSource(data.media);
        // console.log(source);
        setTitle(data.name);
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.load();
            audioRef.current.play();
        }
    }

    const playList = () => {
        const secondColumnStart = Number(audioListItems.length / 2).toFixed(0);
        // console.log(secondColumnStart);
        return (
            <div class="row default-section-spacing pad-b-sm-0 mar-b-lg-4">
                <div class="flex-lg-6" align="left">{
                    audioListItems && audioListItems.slice(0, secondColumnStart).map((d) => {
                        //console.log(d.name);
                        return (
                            //<p key={d.id}>{d.name}</p>
                            <p key={d.id}>
                                <button onClick={() => handleClick(d)}>
                                    {d.name}
                                </button>
                            </p>
                        )
                    })
                }</div>
                <div class="flex-lg-6" align="left">{
                    audioListItems && audioListItems.slice(secondColumnStart).map((d) => {
                        //console.log(d.name);
                        return (
                            <p key={d.id}>
                                <button onClick={() => handleClick(d)}>
                                    {d.name}
                                </button>
                            </p>
                        )
                    })
                }</div>
            </div>
        )
    }

    const audioPlayer = () => {
        return (
            <div>
                <div>
                    <h1>{header}</h1>
                </div>
                <div>
                    <p>{title}</p>
                    <audio controls ref={audioRef}>
                        <source src={source} type="audio/mp3"/>
                    </audio>
                </div>
            </div>
        )
    }
    
    return (
        <section class="single-page default-section-spacing">
            <div class="container">
                {audioPlayer() }
                {playList() }
            </div>
        </section>
    )
}

export default AudioDevoRickW;