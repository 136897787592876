import React, { useContext } from 'react';
/**
 * SEE scss for styling of this block comp
 *  
 */
import './FooterSimple.css'
import AuthContext from './store/auth';
const FooterSimple = (props) => {
    const authCtx = useContext(AuthContext);
    return (
        <React.Fragment>
            <footer class="footer">

                <div class="footer__upper">

                    <div class="time-location">

                        <div class="prayer-request default-section-spacing text-center-sm">

                            <div class="container">

                                <div class="row justify-content-end">

                                    <div class="flex-lg-8">
                                        <h2 class="mar-b-sm-2">{props.footerMessage}
                                        </h2>
                                        <a href="/contact" class="button button--primary button--fill">{props.callActionTxt ? props.callActionTxt : 'Request a prayer'}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="time-location__content">

                            <div class="container">

                                <div class="row">

                                    <div class="flex-lg-4 position">

                                        <div class="box-details">

                                            <div class="section-heading">
                                                <span class="section-intro">Visit</span>
                                                <h3>{props.intro}</h3>
                                            </div>
                                            <div class="display-flex mar-b-sm-2">
                                                <div class="left-side">
                                                    <span class="leading bold">Sunday:</span>
                                                </div>
                                                <div class="right-side">
                                                    <span class="leading mar-b-sm-06">9:00am - 10:30am (Sunday school)</span>
                                                    <span class="leading mar-b-sm-06">10:30am - 12:00pm (Morning worship)</span>
                                                    <span class="leading">12:30pm - 1:30pm (Fellowship lunch)</span>
                                                </div>
                                            </div>

                                            <div class="display-flex mar-b-sm-06">
                                                <div class="left-side">
                                                    <span class="leading bold">Location:</span>
                                                </div>
                                                <div class="right-side">
                                                    <span class="leading">3134 Frick Rd, Houston , TX 77038</span>
                                                </div>
                                            </div>

                                            <div class="display-flex mar-b-sm-06">
                                                <div class="left-side">
                                                    <span class="leading bold">Telephone:</span>
                                                </div>
                                                <div class="right-side">
                                                    <span class="leading">713-239-1466</span>
                                                </div>
                                            </div>
                                            <div class="display-flex">
                                                <div class="left-side">
                                                    <span class="leading bold">Email:</span>
                                                </div>
                                                <div class="right-side">
                                                    <span class="leading">htloichua@gmail.com</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="time-location__map">
                                <iframe title='htlc loichua location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3457.9326217554453!2d-95.47034498488772!3d29.923841881926283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c930b0c32687%3A0x96400a33c60daf0b!2s3134%20Frick%20Rd%2C%20Houston%2C%20TX%2077038!5e0!3m2!1sen!2sus!4v1634954832960!5m2!1sen!2sus" allowfullscreen=""></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer__middle default-section-spacing">

                    <div class="container">

                        <div class="row">

                            <div class="flex-md-6 flex-lg-3">

                                <div class="footer__widget color-off-white">

                                    <h3>Follow us</h3>
                                    <p class="mar-t-sm-2 mar-b-sm-2"></p>

                                    <div class="display-flex">

                                        <div class="social">
                                            <a href={authCtx.facebookURL} class="transparent button button--primary button--outline share-button"><i
                                                class="ri-facebook-line"></i></a>
                                        </div>
                                        <div class="social">
                                            <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                class="ri-linkedin-line"></i></a>
                                        </div>
                                        <div class="social">
                                            <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                class="ri-twitter-line"></i></a>
                                        </div>
                                        <div class="social">
                                            <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                class="ri-instagram-line"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-md-6 flex-lg-3">

                                <div class="footer__widget color-off-white">

                                    <h3>Resources</h3>

                                    <div class="causes-list mar-t-sm-2">

                                        <ul>
                                            <li><a href='https://tinlanh.org'>tinlanh.org </a></li>
                                            <li><a href='https://bible.com'>bible.com</a></li>

                                        </ul>

                                    </div>
                                </div>
                            </div>
                            <div class="flex-md-6 flex-lg-3 mar-t-md-2 mar-t-lg-0">

                                <div class="footer__widget color-off-white">

                                    <h3>Others</h3>

                                    <div class="podcast-list mar-t-sm-2">

                                        <ul>
                                            <li class="icon-text">
                                                <span class="icon"><i class="ri-video-line"></i></span>
                                                <span class="text"><a href="http://ghvnhk.org">Giáo Hạt Việt Nam Hoa Kỳ
                        </a></span>
                                            </li>

                                            <li class="icon-text">
                                                <span class="icon"><i class="ri-video-line"></i></span>
                                                <span class="text"><a href="https://tinlanhhyvong.com">Tin Lành Hy Vọng
                                </a></span>
                                            </li>




                                            <li class="icon-text">
                                                <span class="icon"><i class="ri-headphone-line"></i></span>
                                                <span class="text"><a href="https://httlvn.org">Hội Thánh Tin Lành Việt Nam
                          </a></span>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                            {/* hide this for now 
                            <div class="flex-md-6 flex-lg-3 mar-t-md-2 mar-t-lg-0">

                                <div class="footer__widget color-off-white">

                                    <h3>Join our newsletter</h3>

                                    <div class="newsletter mar-t-sm-2">

                                        <form class="form">

                                            <div class="form__group mar-b-sm-2">
                                                <input type="email" class="form__input" placeholder="Enter your email address" />
                                            </div>
                                            <button type="submit" class="button button--primary button--fill">Join newsletter</button>

                                        </form>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div class="footer__lower background-off-white pad-t-sm-2 pad-b-sm-2">

                    <div class="container">

                        <div class="row">

                            <div class="flex-md-6 flex-lg-4">
                                <p class="copyright">LoiChua Social Media - Version 1.0</p>
                            </div>
                            <div class="flex-md-6 flex-lg-4 text-center-lg">

                                <nav class="footer__nav">
                                    <ul class="display-flex justify-content-between">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="/about">About</a></li>

                                        <li><a href="/contact">Contact</a></li>
                                        <li><a href="/privacy">Privacy</a></li>
                                        
                                    </ul>
                                </nav>
                            </div>
                            <div class="flex-md-12 flex-lg-4 mar-t-md-2 mar-t-lg-0 text-right-lg">
                                <p>LoiChua Social Media Team <span class="bold underline">2021</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </React.Fragment>
    )
}
export default FooterSimple;