import React, { useEffect, useState } from 'react';
import axios from 'axios';
//import MediaVBC from './MediaVBC';

const GetLoiSongHangNgay = (props) => {
    const [mp3Link, setMp3Link] = useState();
    const [mp3Title, setMp3Title] = useState();
    //const [mp3Fig,setMp3Fig]=useState();
    const [linkUrl, setLinkUrl] = useState();
    const getAudioData = () => {
        axios.get("https://api.loichua.church:5001/main/get-loi-song-hang-ngay", {})
            .then(
                res => {
                    console.log('got res obj ' + res);
                    const json = JSON.stringify(res);
                    console.log(' *** now it is js obj ' + json);// json.mp3Link + ' and title  ' + json.mp3Title  );

                    const mp3Data = JSON.parse(json);
                    console.log(' *** now it is js obj ' + mp3Data.data.mp3Link + ' and title ' + mp3Data.data.mp3Title);
                    setMp3Link(mp3Data.data.mp3Link);
                    setMp3Title(mp3Data.data.mp3Title);
                    setLinkUrl(mp3Data.data.linkUrl);
                    //setMp3Fig(mp3Data.data.mp3Fig);

                }
            ).catch(err => {
                console.log('got error ' + err);
            })
    }

    useEffect(() => {
        getAudioData();
    }, [])

    return (
        <React.Fragment>
            {/*}
	 <figure>
		  <img src={mp3Fig}  width='100%'  height='auto' />
	 </figure>
	 */}
            <h2 style={{ color: "blueviolet" }}><a href={mp3Link} style={{ color: 'royalblue' }}>{mp3Title}</a></h2>
            <audio controls>
                <source src={mp3Link} type='audio/mpeg' />
            </audio>
        </React.Fragment>
    )
}

export default GetLoiSongHangNgay;
