import React from 'react'

const NavBarSummerCamp = ()=>{

	return(
		<>
			<nav className="navbar navbar-light bg-light">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src="../../assets/images-cus/traihe2024-logo.png" alt="" width="150" height="150" className="d-inline-block align-text-top" />


                    </a>
                    <div className='row'>
                        <div className='col' style={{ marginRight: '8rem', color: '#68290a', fontSize: '1.5rem' }}>
                            <h5>Lakeview Family Summer Camp From 25-26/5/2024</h5>
                            <h5>Theme: End Time Living</h5>
                            <h5>( Sống Đạo Thời Kỳ Cuối Cùng)</h5>
                        </div>
                        <div className='col-3' style={{ color: '#68290a' }}>
                            <p>Lakeview Methodist Conference Center<br />
                            Address: 400 Private Rd 6036, Palestine, TX 75801</p>
                        </div>
                        <div className='col'></div>
                    </div>

                </div>
            </nav>

		</>
	)
}
export default NavBarSummerCamp