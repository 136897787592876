import React, { useState } from 'react'
import Navbar from './navbar'
const RandomVerse = (props) => {
    const verses = [
        { id: 1, verseCtx: 'Cầu-xin Chúa dạy chúng tôi biết đếm các ngày chúng tôi, Hầu cho chúng tôi được lòng khôn-ngoan. Thi thien  90:12' },
        { id: 2, verseCtx: '14 nhưng tôi cứ làm một điều: quên lửng sự ở đằng sau, mà bươn theo sự ở đằng trước, tôi nhắm mục-đích mà chạy, để giựt giải về sự kêu-gọi trên trời của Đức Chúa Trời trong Đức Chúa Jêsus-Christ. Philip 3:14' },
        {
            id: 3, verseCtx: '23 Khá cẩn-thận giữ tấm lòng của con hơn hết, Vì các nguồn sự sống do nơi nó mà ra. Cham ngon 4:23'
        },
        { id: 4, verseCtx: '10  Đừng sợ, vì ta ở với ngươi; chớ kinh-khiếp, vì ta là Đức Chúa Trời ngươi! Ta sẽ bổ sức cho ngươi; phải, ta sẽ giúp-đỡ ngươi, lấy tay hữu công-bình ta mà nâng-đỡ ngươi. Esai 41:10' },
        { id: 5, verseCtx: '11 Đức Giê-hô-va phán: Vì ta biết ý-tưởng ta nghĩ đối cùng các ngươi, là ý-tưởng bình-an, không phải tai-họa, để cho các ngươi được sự trông-cậy trong lúc cuối-cùng của mình. Gie re mi 29:11' },

        { id: 6, verseCtx: 'lại hãy trao mọi điều lo-lắng mình cho Ngài, vì Ngài hay săn-sóc anh em. 1 Phi-e-ro 5:7' },

        { id: 7, verseCtx: 'Vì Đức Chúa Trời chẳng ban cho chúng ta tâm-thần nhút-nhát, bèn là tâm-thần mạnh-mẽ, có tình thương-yêu và dè-giữ. 2 Ti-mo-the 1:7' },
        { id: 8, verseCtx: 'Chúa là thành-tín, sẽ làm cho anh em bền-vững và giữ cho khỏi Ác-giả. 2 Te-sa-lo-ni-ca 3:3' },
        { id: 9, verseCtx: 'Hãy cầu sự bình-an với mọi người, cùng tìm theo sự nên thánh, vì nếu không nên thánh thì chẳng ai được thấy Đức Chúa Trời. He-bo-ro 12:14' },
        { id: 10, verseCtx: 'Vậy xin Đức Chúa Trời của sự trông-cậy, làm cho anh em đầy-dẫy mọi điều vui-vẻ và mọi điều bình-an trong đức-tin, hầu cho anh em nhờ quyền-phép Đức Thánh-Linh được dư-dật sự trông-cậy! Ro-ma 15:13' },
        { id: 11, verseCtx: '12 Phước cho người bị cám-dỗ; vì lúc đã chịu nổi sự thử-thách rồi, thì sẽ lãnh mão triều-thiên của sự sống mà Đức Chúa Trời đã hứa cho kẻ kính-mến Ngài. Gia co 1:12' },
        { id: 12, verseCtx: '9 Chớ mệt-nhọc về sự làm lành, vì nếu chúng ta không trễ-nải, thì đến kỳ, chúng ta sẽ gặt. Galati 6:9' },
        { id: 13, verseCtx: '5Hãy hết lòng tin-cậy Đức Giê-hô-va, Chớ nương-cậy nơi sự thông-sáng của con; 6Phàm trong các việc làm của con, khá nhận-biết Ngài, Thì Ngài sẽ chỉ-dẫn các nẻo của con. Cham ngon 3:5-6' },
        { id: 14, verseCtx: '23 Mỗi buổi sáng thì lại mới luôn, sự thành-tín Ngài là lớn lắm. 24 Hồn ta nói: Đức Giê-hô-va là cơ-nghiệp ta, nên ta để lòng trông-cậy nơi Ngài. Ca thuong 3:23-24' },
        { id: 15, verseCtx: 'Đức Giê-hô-va sẽ đi trước ngươi, Ngài sẽ ở cùng ngươi, chẳng lìa khỏi ngươi, chẳng từ-bỏ ngươi đâu. Chớ sợ, và chớ kinh-khủng. Phuc truyen 31:8' },
        { id: 16, verseCtx: '13 Tôi làm được mọi sự nhờ Đấng ban thêm sức cho tôi. Phi lip 4:13' },
        { id: 17, verseCtx: 'Lòng bình-tịnh là sự sống của thân-thể;Còn sự ghen-ghét là đồ mục của xương-cốt. Cham ngon 14:30' },
        { id: 18, verseCtx: '17 Vậy, nếu ai ở trong Đấng Christ, thì nấy là người dựng nên mới; những sự cũ đã qua đi, nầy mọi sự đều trở nên mới. 2 Co rinh to 5:17' },
        { id: 19, verseCtx: 'Hãy hát cho Đức Giê-hô-va một bài ca mới; Vì Ngài đã làm những sự lạ-lùng: Tay hữu và cánh tay thánh của Ngài đã giải-cứu Ngài. Thi 98:1' },
        { id: 20, verseCtx: 'Hãy cảm-tạ mà vào các cửa Ngài, Hãy ngợi-khen mà vào hành-lang Ngài, Khá cảm-tạ Ngài, chúc-tụng danh của Ngài.  Thi thien 100:4' },
        { id: 21, verseCtx: 'Cầu-xin Ngài ban cho ngươi điều lòng ngươi ước-ao, Làm thành các điều toan-tính của ngươi! Thi thien 20:4' },


    ]
    //const [verseId, setVerseId] = useState()
    const [verseDisplay, setVerseDisplay] = useState()
    const getVerse = async event => {
        event.preventDefault()
        const versecode = parseInt(event.target.versecode.value)
        console.log('on blur get code ' + versecode)
        //setVerseId(versecode)
        for (let i = 1; i < verses.length; i++) {
            if (i === versecode) {
                //console.log('need to display verse ctx' + verses[i]['verseCtx'])
                setVerseDisplay(verses[i]['verseCtx'])
            }
        }
    }

    return (
        <div className='container'>
            {props.showNav ?
                <Navbar />
                : ''
            }
            <div style={{ background: "linear-gradient(#e66465, #9198e5)" }}>
                <div className="mb-3">
                    <form onSubmit={getVerse}>
                        <label htmlFor="versecode" className="form-label text-white">Nhận Lời Chúa - Redeem your New Year gift</label>
                        <input name="versecode" type="text" className="form-control" id="versecode" placeholder="type a number between 1-20" pattern="[0-9]+" />
                        <button className='btn btn-sm' type='submit' style={{ color: 'whitesmoke', fontWeight: 'bold' }}>Get Verse</button>
                    </form>
                    <br />
                    <blockquote class="blockquote">
                        {verseDisplay ? verseDisplay : ''}

                    </blockquote>

                    <p className='text-primary'><a href='/' className='text-white'>Home</a></p>
                </div>
            </div>
        </div>


    )
}

export default RandomVerse