import React from 'react';
import { FacebookProvider, Like } from 'react-facebook';

const LikeComponent = (props) => {

    return (
        <React.Fragment>
            <FacebookProvider appId={props.appId}>
                <Like href={props.urllike} colorScheme="dark" showFaces share />
            </FacebookProvider>
        </React.Fragment>
    )
}

export default LikeComponent;