import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
//import { Link } from 'react-router-dom';
import AuthContext from './store/auth';
import { Link } from 'react-router-dom';

//import AboutUs from './AboutUs';
import './HeaderNavigator.css';
import BurgerMenu from './BurgerMenu';

const HeaderNavigator = () => {
    /*const logoStyle = {
        marginTop: '-50px',
        width: '15rem',


    }
    */
    const useCtx = useContext(AuthContext);
    return (
        <React.Fragment>
            <Helmet>
                <title>Hội Thánh Tin Lành Lời Chúa</title>
                <meta name='description' content='Mời bạn cùng đồng hành với chúng tôi 40 ngày để thay đổi và sống đúng mục đích theo quan điểm Tin lành bởi Rick Warren.  Hội Thánh Tin Lành Lời Chúa' />
                <meta name='keywords' content='tin lanh, song dung muc dich, purposed driven life, CMA , Houston, loi chua, nguoi viet' />


            </Helmet>
            <nav class="bottom-nav">

                <div class="container">

                    <div class="header__nav">

                        {/*<div class="header__logo brand--logo"> */}
                        <div class='header__logo brand--logo'>
                            {/*<div style={logoStyle}><a href="/"><img src="assets/images-cus/cma-logo.png" alt="loichua" /></a></div> */}
                            <h2 style={{ marginBottom: '2.5rem', fontSize: '2.5rem', color: 'white' }}>LoiChua.Church</h2>
                        </div>
                        {/*
                        <div class="header__mobile--opener hide-on-lg">
                            <button class="header__mobile--icon" aria-expanded="false" aria-controls="mobile-menu" data-toggle="mobile-menu">
                                <span class="line"></span>
                                <span class="line"></span>
                                <span class="line"></span>
                            </button>
                        </div>
                        */}
                        <BurgerMenu />

                        <ul class="header__navitems show-on-lg" id="mobile-menu">

                            <li class="header__extra">
                                <div class="cta">
                                    <a href="/donations" class="button button-block-sm button--primary button--fill">Give</a>
                                </div>
                            </li>

                            <li class="header__list active">
                                <a href="/" class="dropdown-link caret">Home</a>

                                <div class="header__submenu">

                                    <ul>
                                        <li class="header__list active"><a href="/">Home </a></li>

                                    </ul>

                                </div>
                            </li>

                            <li class="header__list"><Link to='/about'>About</Link></li>

                            <li class="header__list">
                                <a href="/" class="dropdown-link caret">Ministries</a>

                                <div class="header__submenu">
                                    <ul>
                                        { /* remove or add as need  */}
                                        <li class="header__list"><a href="/yem">YEM Ministry</a></li>
                                        <li class="header__list"><a href="/children-ministry">Children Ministry</a></li>
                                        <li class="header__list"><a href="/coming-soon">Other</a></li>

                                    </ul>
                                </div>
                            </li>

                            <li class="header__list">
                                <a href="/" class="dropdown-link caret">Events</a>

                                <div class="header__submenu">
                                    <ul>

                                        <li class="header__list"><a href="/event">Thanksgiving & Fall Festival</a></li>


                                    </ul>
                                </div>
                            </li>
                            <li class="header__list">
                                <a href="/" class="dropdown-link caret">Media</a>

                                <div class="header__submenu">
                                    <ul>
                                        <li class="header__list"><a href="/phat-thanh-tin-lanh-houston-texas">Christian Radio Broadcast -Phát Thanh Tin Lành </a></li>

                                        <li class="header__list"><a href="/video-list">Latest video sermons</a></li>
                                        <li class="header__list"><a href="/devotions">Devotions</a></li>
                                        <li class="header__list"><a href="/read-dailyhope">Read Daily Devotion</a></li>

                                        <li class="header__list"><a href='/facebook-page'>HT LoiChua Facebook</a></li>
                                        <li class="header__list"><a href={useCtx.youtubeURL}>HT LoiChua YouTube</a></li>

                                        <li class="header__list"><a href="/song-dung-muc-dich">Song Theo Dung Muc Dich - Rick Warren</a></li>




                                    </ul>
                                </div>
                            </li>

                            <li class="header__list">
                                <a href="/" class="dropdown-link caret">Blog</a>

                                <div class="header__submenu">
                                    <ul>
                                        <li class="header__list"><a href='/truyen-ngan'>Short Stories</a></li>


                                        <li class="header__list"><a href={useCtx.blogURL}>Loi Chua Blog</a></li>



                                    </ul>
                                </div>
                            </li>

                            <li class="header__list"><a href="/contact">Contact</a></li>


                        </ul>

                        <div class="header__extra desktop-version">
                            <div class="search">
                                <div class="search-icon">
                                    <button class="modal-button" data-modal-target="site-search"><i class="ri-search-line"></i></button>
                                </div>
                            </div>

                            <div class="cta hide-on-sm show-on-lg">
                                <a href="/donations" class="button button--primary button--fill">Give</a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
}
export default HeaderNavigator;