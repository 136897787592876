import React, { useState, useEffect ,useContext } from 'react'
import { Form,  Row, Col } from 'react-bootstrap'
import axios from 'axios'
import AuthContext from '../store/auth'

const HoiDongDataExport = () => {
    const useCtx = useContext(AuthContext)
    const [churchName, setChurchName] = useState('')
    const [downloadLink, setDownloadLink] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [churchList, setChurchList]=useState([])
    
    //get whole list of churches from given excel sheet
    useEffect(()=>{ 
	    fetch('https://api.loichua.church:5001/main/get-hoithanh-options').then(response=>response.json())
	    .then(data=>{
			const addAll='All'
			const unpaid='Unpaid'
			setChurchList([...data, addAll,unpaid])
	    
	    })
	    .catch(error=>console.error(error))
    },[])


    //form action here
    const getHDData = (event) => {
        event.preventDefault()
        console.log('im clicked on submission with event  ' + churchName)
        if (churchName === '' || churchName === 'null') {
            setErrorMessage('Please select a church')
            return

        }

        axios.post(useCtx.getHDDataBy, { churchName: churchName }).then(response => {
            console.log(' i got response ' + response.data.status + ' message ' + response.data.message)
            setErrorMessage('')
            if (response.status === 200) {
                if (response.data.downloadFile) {
                    console.log('i got download file as ' + response.data.downloadFile)
                    //let downloadLink=response.data.downloadFile//'https://api.loichua.church:5001/main/downloadfile/'+response.data.downloadFile 
                    let downloadLink = useCtx.getCsvCampDataUrl + '/' + response.data.downloadFile
                    console.log(' now full link to download file ' + downloadLink)
                    setDownloadLink(downloadLink)
                }
            }//end resp status=200
        })

    }
    const churchNameHandler = event => {
        setChurchName(event.target.value)
    }
    /*
    const getCsvFile = (event) => {
        event.preventDefault()
        console.log(' im clicked on download btn and now i have link ' + downloadLink)
        let dataSubmitted = { link: downloadLink }
        axios.post('https://api.loichua.church:5001/main/downloadfile/' + downloadLink)
    }
    */
    return (
        <div className='container'>
            <div className='row'>
                <div className='col'></div>
                <div className='col-6'>
                    <Form>
                        <Row>
                            <Col>
                                <p>&nbsp;</p>
                                <h6>Export Data By Church Name or All churches or Unpaid registrant list (to CSV file)</h6>
                                  <Form.Label><strong>Your Church:</strong></Form.Label>
                                        <Form.Control as="select" className="rounded-0 shadow" onChange={churchNameHandler}>
                                            <option className="d-none" value="{churchName}">
                                                Select Your Church From List
											</option>
                                            {/** churchList is from backend exel sheet and  */}
                                            {churchList.map(option => ( 
                                                <option key={option}>{option}</option>
                                            ))}
                                        </Form.Control>
                                
                                 
                                
                                <button type="submit" size='sm' className='btn btn-alert' onClick={getHDData}>Get The CSV File</button>
                                <br />

                                <a href={downloadLink ? downloadLink : ''}> {downloadLink ? 'Download' : ''}</a>
                                <p className='text-danger'>{errorMessage ? errorMessage : ''}</p>

                            </Col>

                        </Row>


                    </Form>
                </div>
                <div className='col'></div>
            </div>
        </div>

    )
}

export default HoiDongDataExport