import React from 'react';
import { Helmet } from "react-helmet";

//import SiteHeader from './SiteHeader';
//import Footer from './Footer';
//import BannerAbout from './BannerAbout';
//import HeaderNavigator from './HeaderNavigator';
import ScrollBack from './ScrollBack';
//import AllModals from './AllModals';
//import MissionVision from './MissionVision';
import FooterSimple from './FooterSimple';
import AboutUsThreePoint from './AboutUsThreePoint';
import AboutUsMissionary from './AboutUsMissionary';
import HeaderNavigatorVN from './HeaderNavigatorVN';
import BannerMissionary from './BannerMissionary';

const MissionaryPage = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Hội Thánh Tin Lành Lời Chúa - Trang Truyền Giáo</title>
                <meta name='description' content='Hội Thánh Tin lành cho người Việt Houston, Texas. Nhà thờ Tin lành tọa lạc tại 3134 Frick Rd, Houston , TX 77038 ' />
                <meta name='keywords' content='tin lanh, nha tho, nguoi viet, co doc nhan, hoi thanh, song voi loi chua' />
            </Helmet>
            <header className="header light-text" data-onscroll-classes="fixed-header animate-header">

                <div className="header__top-nav background-off-white">

                    <div className="container">

                        <div className="verse-of-the-day hide-on-md">

                            <button className="button button--secondary button--fill button-block-sm no-shadow modal-button"
                                data-modal-target="verse-of-the-day">Click to see the verse of the day</button>

                        </div>
                        <div className="verse-of-the-day hide-on-sm show-on-md">

                            <div className="row align-items-center">
                                <div className="flex-md-3 flex-lg-2">
                                    <div className="background-secondary text-center-md verse-of-the-day-button">Verse of the day</div>
                                </div>
                                <div className="flex-md-9 flex-lg-10">
                                    <p className="italic">Ngài phán cùng các sứ-đồ rằng: Hãy đi khắp thế-gian, giảng Tin-lành cho mọi người. 
                                        
                   <span className="citation bold">~ Mác 16:15 </span>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <HeaderNavigatorVN />
            </header>
            <BannerMissionary />

            <ScrollBack />
            <AboutUsMissionary />

            <AboutUsThreePoint />
            <div><p>&nbsp;</p></div>

            <FooterSimple footerMessage='Join us this Sunday, enjoy our friendly service' />


        </React.Fragment >
    )
}

export default MissionaryPage;