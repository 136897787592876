import React, { useEffect, useState, useContext } from 'react';
import AuthContext from './store/auth';
import axios from 'axios';

const ShortStories = (props) => {
   const useCtx = useContext(AuthContext);
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        callback2();
    })
     
    const callback2 = () => {
         const instance = axios.create({
            baseURL: useCtx.apiBaseURL 
        });
        //const fetchUrl=useCtx.apiBaseURL+'/main/get-articles-by-id';
        const id=props.id;
        instance.post("/main/get-articles-by-id" ,{'id':id}).then(res=>{
            console.log(res.data.data)
            setArticles(res.data.data);

        }).catch(error => {
            console.log(error)
        })
    }
    return (
        <>

            {
                articles && articles.map((art) => {
                    return (

                        <iframe key={art.id} src={art} height='600' width='800'  title='Tài Liệu Sưu Tầm'/>
                    )

                })

            }







        </>
    )
}
export default ShortStories;