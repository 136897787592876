import React from 'react';
import { Helmet } from "react-helmet";

const AboutUsThreePoint = (props) => {

    return (
        <>
            <Helmet>
                <title>Hội Thánh Tin Lành Lời Chúa -giới thiệu về Tin lành</title>
                <meta name='description' content='Hội Thánh Tin lành cho người Việt Houston, Texas. Nhà thờ Tin lành tọa lạc tại 3134 Frick Rd, Houston , TX 77038 ' />
                <meta name='keywords' content='tin lanh, nha tho, nguoi viet, co doc nhan, hoi thanh, song voi loi chua' />
            </Helmet>
            <div class="single-event__pictures mar-t-sm-2 mar-t-lg-0 mar-b-sm-2 default-section-spacing">

                <div class="container">

                    <div class="row">

                        <div class="flex-md-6 flex-lg-3">
                            <div class="event-picture"><img src="assets/images-cus/all-tet.jpeg" alt="" /></div>
                        </div>
                        <div class="flex-md-6 flex-lg-3">
                            <div class="event-picture"><img src="assets/images-cus/tiec-thanh.jpeg" alt="" /></div>
                        </div>
                        <div class="flex-md-6 flex-lg-3 mar-t-md-2 mar-t-lg-0">
                            <div class="event-picture"><img src="assets/images-cus/nguyen-hien.jpeg" alt="" /></div>
                        </div>
                        <div class="flex-md-6 flex-lg-3 mar-t-md-2 mar-t-lg-0">
                            <div class="event-picture"><img src="assets/images-cus/youth-loichua.jpeg" alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default AboutUsThreePoint;