import React, { useState } from 'react'
import axios from 'axios';

const getRandomObject = (array) => {
    const randomObject = array[Math.floor(Math.random() * array.length)]
    return randomObject;
}
//define the array of verses to be piced kup randomly by the verse display component
//   
const verseData = [
    { verseId: 'JHN.3.16' },
    { verseId: 'MAT.6.33' },
    { verseId: 'ISA.7.14' },
    { verseId: 'JHN.1.14' },
    { verseId: 'JHN.14.6' },
    { verseId: 'JHN.1.12' },
    


]
const imgBckgnd = [
    /*{ imgLoc: '/assets/images-cus/bible-verse.png' },
    { imgLoc: '/assets/images-cus/bible-verse-2.png' },
    { imgLoc:'/assets/images-cus/bible-verse-3.png' },
    */
    { imgLoc: '/assets/images-cus/Xuan-01.png' },
    { imgLoc: '/assets/images-cus/Xuan-02.png' },
    { imgLoc: '/assets/images-cus/Xuan-03.png' },


]

const ShowBible = () => {


    const [imgUrl, setImgUrl] = useState(() => getRandomObject(imgBckgnd))
    const [verse, setVerse] = useState(() => getRandomObject(verseData))
    const [reference, setReference] = useState()
    const [verseContent, setVerseContent] = useState()

    console.log(' here init verse is ' + verse.verseId)
    const userData = {
        verseId: verse.verseId
    }
    const config = {
        headers: {
            'Content-Type': 'application/json',

            'Access-Controll-Allow-Origin': '*'
        }
    }
    axios.post("https://api.loichua.church:5001/main/bible", userData, config)
        .then(resp => {
            console.log(resp)
            if (resp.data.status === 'ok') {
                console.log(' here i got verse ' + resp.data.verseContent)
                //setImgUrl(resp.data.verseContent)
                setVerseContent(resp.data.verseContent)
                setReference(resp.data.referenceBook)
            }
        }).catch(error => {
            console.log(error)
        })



    return (
        <div className="card" style={{ width: '40rem' }}>
            {/*
			<img src="/assets/images-cus/bible-verse-2.png" className="img-fluid" alt="..." />
			*/}
            <img src={imgUrl.imgLoc} className="img-fluid" alt="..." />

            <div className="card-img-overlay">
                <h3 className="card-title" style={{ color: 'red', fontSize: '2rem', paddingTop: '1.5rem' }}>&nbsp;</h3>
                <p className="card-text lead" style={{ color: 'white', fontSize: '1.7rem', textAlign: 'left', paddingTop: '7.5rem', paddingLeft: '1rem' }}>{verseContent}</p>
                <p className="card-text" style={{ color: 'white', fontSize: '1.2rem', textAlign: 'center' }}>({reference})</p>
            </div>
        </div>

    )
}

export default ShowBible