import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-number-input/input';
import axios from 'axios';
import AuthContext from './store/auth';
import './css/reimbursementForm.css';

const ReimbursementForm = (props) => {

    const useCtx = useContext(AuthContext);

    const formik = useFormik({
        initialValues: {
            claimerName: "",
            phoneNumber: "",
            reason: "",
            totalAmount: "",
            image_upload: null
        },

        validationSchema: Yup.object({
            claimerName: Yup.string()
                .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Required"),
            phoneNumber: Yup.number().required("Required"),
            reason: Yup.string().required("Required"),
            totalAmount: Yup.number().required("Required")
        }),
        
        onSubmit: (values) => {
            console.log(values);
            // console.log('receiving data... ' + JSON.stringify(values));

            const formData = new FormData();
            Object.keys(values).forEach(key => {
                // console.log(key, values[key]);
                formData.append(key, values[key]);
            });
            
            console.log(Object.fromEntries(formData));

            const header = {
                headers: { 'Content-Type': 'multipart/form-data' }
            };

            axios.post(useCtx.submitReimbursement, formData, header).then(
                response => {
                    console.log(response.data);
                    console.log('status: ' + response.data.status);
                    console.log('message: ' + response.data.message);

                    if (response.data.status === '200') {
                        console.log('submitting successfully!')
                        window.location.replace(useCtx.successURL);
                    }
                    else {
                        console.log('fail to submit!')
                    }
                }
            ).catch(error => {
                console.log('got error ' + error)
            })
        }
    })

    // console.log(formik.values);

    return (

        <React.Fragment>

            <section class="contact default-section-spacing">

                <div class="container card">

                    <form class="form reimbursement_form" onSubmit={formik.handleSubmit} autoComplete="off">

                        <div class="row mar-t-md-06 mar-b-lg-2">
                            {/* Claimer Name field */}
                            <div class="flex-md-6 mar-b-md-2">
                                <div>
                                    <label for='claimerName' class="form__label">Claimer Name <span class="color-danger">*</span></label>
                                    
                                    <input
                                        id="claimerName"
                                        class="form__input"
                                        name="claimerName"
                                        type="text"
                                        placeholder="Enter your full name"
                                        required
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.claimerName}
                                    />

                                    {formik.touched.claimerName && formik.errors.claimerName ? <p class="form__error">{formik.errors.claimerName}</p> : null}
                                </div>
                            </div>

                            {/* Phone Number field */}
                            <div class="flex-md-6 mar-b-md-2">
                                <div>
                                    <label for='phoneNumber' class="form__label">Phone Number <span class="color-danger">*</span></label>

                                    <PhoneInput
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        class="form__input"
                                        country="US"
                                        placeholder="Enter your phone number: (123)-456-7890"
                                        value={formik.values.phoneNumber}
                                        onChange={(event) => {
                                            formik.setFieldValue("phoneNumber", event)
                                        }}
                                        onBlur={formik.handleBlur}
                                    />

                                    {formik.touched.phoneNumber && formik.errors.phoneNumber ?
                                        <p class="form__error">{formik.errors.phoneNumber}</p> : null
                                    }
                                </div>
                            </div>

                            {/* Reason field */}
                            <div class="flex-md-12 mar-b-md-2">
                                <div>
                                    <label for='reason' class="form__label">Reason <span class="color-danger">*</span></label>

                                    <input
                                        id="reason"
                                        class="form__input"
                                        name="reason"
                                        type="text"
                                        size="255"
                                        placeholder="Enter your reason for reimbursement"
                                        required
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.reason}
                                    />

                                    {formik.touched.reason && formik.errors.reason ?
                                        <p class="form__error">{formik.errors.reason}</p> : null
                                    }
                                </div>
                            </div>

                            {/* Total Amount Field */}
                            <div class="flex-md-4 mar-b-md-2">
                                <div>
                                    <label for='totalAmount' class="form__label">Total Amount <span class="color-danger">*</span></label>

                                    <input
                                        id="totalAmount"
                                        class="form__input"
                                        name="totalAmount"
                                        type="number"
                                        size="8"
                                        placeholder="1234.56"
                                        required
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.totalAmount}
                                    />

                                    {formik.touched.totalAmount && formik.errors.totalAmount ? <p class="form__error">{formik.errors.totalAmount}</p> : null}
                                </div>
                            </div>

                            {/* Upload File field */}
                            <div class="flex-md-8 mar-b-md-2">
                                <div>
                                    <label for='image_uploads' class="form__label">Choose a file to upload (JPG, PNG, JPEG, PDF) <span class="color-danger">*</span></label>

                                    <input
                                        id="image_upload"
                                        class="form__input"
                                        name="image_upload"
                                        type="file"
                                        accept=".jpg, .jpeg, .png, .pdf"
                                        // multiple
                                        required
                                        onChange={(event) =>
                                            formik.setFieldValue("image_upload", event.target.files[0])
                                        }
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>

                            {/* Submit button */}
                            <div class="flex-md-12">
                                <button class="button--primary button--fill" type="submit" name="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>

            </section>

        </React.Fragment>
    )
}

export default ReimbursementForm;