import React from 'react'
//import Image from 'next/image'
const BioCardTopPic = (props) => {
    return (
        <div className="card" style={{ width: "18rem" }}>
            <img src={props.imgUrl} className="card-img-top img-fluid" alt="..." />
            <div className="card-body">

                <p className="card-text lead"> {props.name} <br /> {props.position}</p>
            </div>
        </div>
    )
}

export default BioCardTopPic;