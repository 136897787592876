import React from 'react';
import SiteHeader from './SiteHeader';
import DonationMainBody from './DonationMainBody';
const DonationsComp = (props) => {

    return (
        <>
            <SiteHeader />
            <main>
                <section class="banner full-width">

                    <div class="container">

                        <div class="banner__content">

                            <div class="section-heading">
                                <span class="section-intro">{props.heading}</span>
                                <h1>Donations</h1>
                            </div>
                            <div class="breadcrumb">
                                <div class="breadcrumb__home--link"><a href="/">{props.homeLink}</a></div>
                                <span>/</span>
                                <div class="breadcrumb__current--page-link">{props.donationTxt}</div>
                            </div>
                        </div>
                    </div>
                </section>
                <DonationMainBody />
            </main>
        </>
    )
}
const Donations = () => {
    return (
        <DonationsComp heading='Donations' homeLink='Home' donationTxt='Donations' />
    )
}
export default Donations;