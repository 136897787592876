import React from 'react';

const BannerForMagazine = () => {

    return (
        <React.Fragment>
            <section class="banner single-banner-event full-width">

                <div class="container">

                    <div class="banner__content">

                        <div class="section-heading">
                            <span class="section-intro">Daily Hope</span>
                            <h1>Grow your spiritual life everyday - Connect to Our Lord Jesus Christ - Dr Rick Warren</h1>
                        </div>
                        <div class="breadcrumb">
                            <div class="breadcrumb__home--link"><a href="/">Home</a></div>
                            <span>/</span>
                            <div class="breadcrumb__home--link"><a href="/">Articles</a></div>
                            <span>/</span>
                            <div class="breadcrumb__current--page-link">Daily Hope</div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )

}

export default BannerForMagazine