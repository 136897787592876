import React from 'react';
import { FacebookProvider, EmbeddedPost } from 'react-facebook';
import SmallTeamFooter from './SmallTeamFooter';
//import PreferredFbPosts from './data/PreferredFbPosts';


const preferredPosts = [
    { url: 'https://www.facebook.com/HTLoiChua/posts/1356350234814786', title: 'Mung Xuan 2022' },
    { url: 'https://www.facebook.com/HTLoiChua/posts/1332040417245768', title: 'Christmas 2021' },
    { url: 'https://www.facebook.com/HTLoiChua/posts/1335240190259124', title: 'Thanksgiving 2021' },
    { url: 'https://www.facebook.com/HTLoiChua/posts/1335260513590425', title: 'Tin nhan Chua 2021' },
    { url: 'https://www.facebook.com/HTLoiChua/posts/1335266643589812/', title: 'Tin nhan Chua 2021 Video' },
    { url: 'https://www.facebook.com/HTLoiChua/posts/1333255823790894', title: 'Thu vang' },
    { url: 'https://www.facebook.com/HTLoiChua/posts/1337612086688601', title: 'Phim Chúa Jesus' },

];

//const preferredFbPosts = PreferredFbPosts;
const Facebook = (props) => {
    //const preferredPosts = props.preferredPosts;
    //console.log('i have preferred post as list ' + preferredPosts);
    return (
        <>
            <div className='w3-container'>
                <ul className='short-stories'>
                    {/*
                    <li><a href="#home">Home</a></li>
                    <li><a href="#news">News</a></li>
                    <li><a href="#contact">Contact</a></li>
                    <li><a href="#about">About</a></li>
                    */}
                    <li><a href='/'>Trang Nhà</a></li>

                    <li><div class="sharethis-inline-share-buttons"></div></li>
                </ul>
            </div>
            <div className='w3-container'>
                {preferredPosts && preferredPosts.map((fb) => {
                    return (
                        <FacebookProvider appId="287434946725013" >
                            <EmbeddedPost href={fb.url} width="500" key={fb.id} />

                        </FacebookProvider>
                    )
                })

                }
                <SmallTeamFooter />
            </div>


        </>
    )

}
export default Facebook;