import React from 'react';

const Testimonies = () => {

    return (
        <React.Fragment>
            <main>
                <section class="testimonial default-section-spacing text-center-sm">

                    <div class="container">

                        <div class="section-heading">
                            <span class="section-intro">Testimonial</span>
                            <h2>It is all smile at our church</h2>
                        </div>
                        <div id="testimonial-carousel" class="testimonial__carousel owl-carousel">

                            <div class="testimonial__element">

                                <div class="testimonial__picture">

                                    <div class="avatar avatar--large">
                                        <img src="assets/images/avatar-1.png" alt="" />
                                    </div>

                                </div>
                                <div class="testimonial__text">
                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio temporibus vitae architecto at
                                    harum hic autem facilis veniam. Adipisci, quibusdam. Tempore quasi eum incidunt asperiores illo
                  consequatur perspiciatis, laboriosam ipsam, doloribus enim repellendus quo dolorum.</p>
                                </div>
                                <div class="testimonial__author bold leading">Jackie B. Venn</div>

                            </div>
                            <div class="testimonial__element">

                                <div class="testimonial__picture">

                                    <div class="avatar avatar--large">
                                        <img src="assets/images/avatar-4.png" alt="" />
                                    </div>

                                </div>
                                <div class="testimonial__text">
                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio temporibus vitae architecto at
                                    harum hic autem facilis veniam. Adipisci, quibusdam. Tempore quasi eum incidunt asperiores illo
                  consequatur perspiciatis, laboriosam ipsam, doloribus enim repellendus quo dolorum.</p>
                                </div>

                                <div class="testimonial__author bold leading">John Doe</div>

                            </div>
                            <div class="testimonial__element">

                                <div class="testimonial__picture">

                                    <div class="avatar avatar--large">
                                        <img src="assets/images/avatar-6.png" alt="" />
                                    </div>

                                </div>
                                <div class="testimonial__text">
                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio temporibus vitae architecto at
                                    harum hic autem facilis veniam. Adipisci, quibusdam. Tempore quasi eum incidunt asperiores illo
                  consequatur perspiciatis, laboriosam ipsam, doloribus enim repellendus quo dolorum.</p>
                                </div>

                                <div class="testimonial__author bold leading">H. Hyuas</div>

                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </React.Fragment>
    )
}
export default Testimonies;