import React from 'react';
//import mystyle from './css/loichuastyle.module.css';
import Magazine from './Magazine';
const SinglePageMagazine = (props) => {
    //Main content of single page event here
    return (
        <React.Fragment>
            <section class="single-page single-events default-section-spacing pad-b-sm-0">

                <div class="container">

                    <div class="row">

                        <div class="flex-lg-8">

                            <div class="description pad-b-sm-4">

                                <div class="section-heading">
                                    <span class="section-intro">Daily Hope</span>
                                    <h2>&nbsp;</h2>
                                    <iframe title='httl loi chua' src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Floichua.church%2F&width=450&layout=standard&action=like&size=small&share=true&height=35&appId=268395626549167" width="450" height="35" style={{ border: 'none', overFlow: 'hidden' }} scrolling="no" frameBorder="0" allowFullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                </div>
                                <Magazine />

                            </div>

                            <div class="details pad-b-sm-2">

                                <div class="section-heading">
                                    <span class="section-intro">Weekly Service</span>
                                    <h2>Details</h2>
                                </div>
                                <div class="row">

                                    <div class="flex-md-6 flex-lg-4">

                                        <div class="icon-text mar-b-sm-06">
                                            <span class="icon"><i class="ri-user-star-line"></i></span>
                                            <span class="text bold">Sunday Services</span>
                                        </div>
                                        <div class="icon-text mar-b-sm-06">
                                            <span class="icon"><i class="ri-time-line"></i></span>
                                            <span class="text bold">10:30AM</span>
                                        </div>
                                        <div class="icon-text">
                                            <span class="icon"><i class="ri-calendar-line"></i></span>
                                            <span class="text bold"></span>
                                        </div>
                                    </div>
                                    <div class="flex-md-6 flex-lg-4">

                                        <div class="icon-text mar-b-sm-06">
                                            <span class="icon"><i class="ri-phone-line"></i></span>
                                            <span class="text bold">(713) 239-1466 </span>
                                        </div>
                                        <div class="icon-text mar-b-sm-06">
                                            <span class="icon"><i class="ri-mail-line"></i></span>
                                            <span class="text bold">hloichua@gmail.com</span>
                                        </div>
                                        <div class="icon-text">
                                            <span class="icon"><i class="ri-map-pin-line"></i></span>
                                            <span class="text bold">3134 Frick Rd, Houston TX 77038</span>
                                        </div>
                                    </div>
                                    <div class="flex-md-12 flex-lg-4 mar-t-md-2 mar-t-lg-0">

                                        <div class="icon-text">
                                            <span class="icon"><i class="ri-money-dollar-circle-line"></i></span>
                                            <span class="text bold">Join us this Sunday</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-lg-4">
                            <div>
                                <img src='../assets/images-cus/Fall-Festival.jpeg' alt='HT LoiChua Fall Festival & Thanksgiving' />
                            </div>

                        </div> {/* end of right bar event */}

                    </div>
                </div>
                {/*
                <div class="single-event__pictures mar-t-sm-2 mar-t-lg-0 mar-b-sm-2">

                    <div class="container">

                        <div class="row">

                            <div class="flex-md-6 flex-lg-3">
                                <div class="event-picture"><img src="http://yem.life/wp-content/uploads/2021/04/IMG_5285-2-1-860x1290.jpg" alt="" /></div>
                            </div>
                            <div class="flex-md-6 flex-lg-3">
                                <div class="event-picture"><img src="http://yem.life/wp-content/uploads/2021/04/IMG_5066-860x1290.jpg" alt="" /></div>
                            </div>
                            <div class="flex-md-6 flex-lg-3 mar-t-md-2 mar-t-lg-0">
                                <div class="event-picture"><img src="http://yem.life/wp-content/uploads/2021/04/Picnic2018-0081-Web-860x573.jpg" alt="" /></div>
                            </div>
                            <div class="flex-md-6 flex-lg-3 mar-t-md-2 mar-t-lg-0">
                                <div class="event-picture"><img src="http://yem.life/wp-content/uploads/2021/04/Picnic2018-9713-Web-scaled.jpg" alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                */}
                <div class="single-page__navigation background-off-white pad-t-sm-2 pad-b-sm-2">

                    <div class="container">

                        <div class="display-flex justify-content-between align-items-center">

                            <div class="previous display-flex align-items-center">
                                <span class="arrow"><a href="/" class="button button--outline button--primary share-button"><i
                                    class="ri-arrow-left-s-line"></i></a></span>
                                <span>Previous event</span>
                            </div>
                            <div class="next display-flex align-items-center">
                                <span>Next event</span>
                                <span class="arrow"><a href="/" class="button button--outline button--primary share-button"><i
                                    class="ri-arrow-right-s-line"></i></a></span>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </React.Fragment>
    )
}
export default SinglePageMagazine;
