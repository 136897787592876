import React from 'react';
//import ReactDom from 'react-dom';
import { useHistory} from 'react-router-dom';


const BurgerMenu = (props) => {
    let history = useHistory();

    const buttonModalClickEvt = (evt) => {
        console.log('im click on modal');
        history.push('/sitemap');

    }
    return(
        <>
        <div class="header__mobile--opener hide-on-lg">
                <button class="header__mobile--icon" aria-expanded="false" aria-controls="mobile-menu"
                    data-toggle="mobile-menu" onClick={buttonModalClickEvt}>
                                <span class="line"></span>
                                <span class="line"></span>
                                <span class="line"></span>
                            </button>
        </div>
        </>
    )

}
//const BurgerContainer=document.getElementById('burger')
//const BurgerComp = ({BurgerMenu})=>ReactDom.createPortal(<BurgerMenu/>,BurgerContainer); 
export default BurgerMenu; 

