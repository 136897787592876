import React from 'react';
//import SiteHeader from './SiteHeader';
import PhatThanhTinLanh from './PhatThanhTinLanh';
import BannerPhatThanhTinLanh from './BannerPhatThanhTinLanh';
import SmallTeamFooter from './SmallTeamFooter';
import SiteHeaderWithSlide from './SiteHeaderWithSlide';
const PhatThanhTinLanhContainer = () => {

    return (
        <>
            <SiteHeaderWithSlide />
            <main>
                <BannerPhatThanhTinLanh />
                <PhatThanhTinLanh />
            </main>
            {/*}
            <ScrollBack />
            */}
            <SmallTeamFooter />

        </>
    )

}

export default PhatThanhTinLanhContainer; 