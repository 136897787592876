import React from 'react';
import './BannerEventSingle.css'
const BannerEventSingle = (props) => {

    return (
        <React.Fragment>
            <section className="banner single-banner-event full-width">

                <div className="container">

                    <div className="banner__content">

                        <div className="section-heading">
                            <span className="section-intro">YEM 4th YEAR ANIVERSARY</span>
                            <h1>Let's celebrate together!</h1>
                        </div>
                        <div className="breadcrumb">
                            <div className="breadcrumb__home--link"><a href="/">Home</a></div>
                            <span>/</span>
                            <div className="breadcrumb__home--link"><a href="/">Events</a></div>
                            <span>/</span>
                            <div className="breadcrumb__current--page-link">YEM 4th YEAR ANIVERSARY</div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default BannerEventSingle;