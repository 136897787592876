import React from 'react';
import { Helmet } from "react-helmet";

//import SiteHeader from './SiteHeader';
//import Footer from './Footer';
import BannerAbout from './BannerAbout';
import HeaderNavigatorVN from './HeaderNavigatorVN';
import ScrollBack from './ScrollBack';
//import AllModals from './AllModals';
//import MissionVision from './MissionVision';
import FooterSimple from './FooterSimple';
import AboutUsThreePoint from './AboutUsThreePoint';
import AboutUsWelcome from './AboutUsWelcome';

const AboutUs = (props) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Hội Thánh Tin Lành Lời Chúa -giới thiệu Tin lành</title>
                <meta name='description' content='Hội Thánh Tin lành cho người Việt Houston, Texas. Nhà thờ Tin lành tọa lạc tại 3134 Frick Rd, Houston , TX 77038 ' />
                <meta name='keywords' content='tin lanh, nha tho, nguoi viet, co doc nhan, hoi thanh, song voi loi chua' />
            </Helmet>
            <header class="header light-text" data-onscroll-classes="fixed-header animate-header">

                <div class="header__top-nav background-off-white">

                    <div class="container">

                        <div class="verse-of-the-day hide-on-md">

                            <button class="button button--secondary button--fill button-block-sm no-shadow modal-button"
                                data-modal-target="verse-of-the-day">Click to see the verse of the day</button>

                        </div>
                        <div class="verse-of-the-day hide-on-sm show-on-md">

                            <div class="row align-items-center">
                                <div class="flex-md-3 flex-lg-2">
                                    <div class="background-secondary text-center-md verse-of-the-day-button">Verse of the day</div>
                                </div>
                                <div class="flex-md-9 flex-lg-10">
                                    <p class="italic">Rejoice always, pray without ceasing, give thanks in all circumstances; for this is
                  the will of God in Christ Jesus for you. <span class="citation bold">~1 Thessalonians 5:16-18</span>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <HeaderNavigatorVN />
            </header>
            <BannerAbout />

            <ScrollBack />
            <AboutUsWelcome />

            <AboutUsThreePoint />
            <div><p>&nbsp;</p></div>

            <FooterSimple footerMessage='Join us this Sunday, enjoy our friendly service' />


        </React.Fragment >
    )
}

export default AboutUs;