import React, { useState, useRef } from 'react';
/**
 * So pick those well performed songs and play as list here
 */

import './Events.css';
const preferredSongs = [
    { title: 'Nhac-GS-Bon Chan Chien', url: 'https://loichua.org/songs/song-cd10-bon_chan_chien.mp3' },
    { title: 'Nhac-GS-Dem Thanh Noi Bet Le Hem', url: 'https://loichua.org/songs/song-cd09-dem_thanh_noi_bethlehem.mp3' },
    { title: 'Nhac-GS-Dem Chua Giang Sinh', url: 'https://loichua.org/songs/song-cd04-dem_chua_giang_sinh.mp3' },

]

const SpecialAudioPlay = (props) => {
    const [playUrl, setPlayUrl] = useState();
    const [songTitle, setSongTitle] = useState();
    const audioRef = useRef();
    //const aEl = '';
    const onClickHandler = (e) => {
        e.preventDefault();

        let aEl = document.getElementById('url-name');
        //const aTitle = document.getElementById('url-name2').value;
        console.log('im clicked and value ' + e.currentTarget);// + ' and data ' + JSON.stringify(data));
        console.log(aEl.firstElementChild.textContent);
        console.log('got value ' + aEl.getAttribute('title'));
        setPlayUrl(e.currentTarget);
        setSongTitle(aEl.getAttribute('title'))
        if (audioRef.current) {

            audioRef.current.pause();
            audioRef.current.load();
            audioRef.current.play();

        }
    }
    return (
        <>
            <div class='w3-container'>
                {preferredSongs.map((s) => {

                    return (
                        <li key={s.id} ><a href={s.url} onClick={onClickHandler} id='url-name' title={s.title}><span style={{ color: 'black' }}>{songTitle}</span></a></li>
                    )
                })

                }
            </div>
            <div class='w3-container w3-green'>
                <p></p>
                <audio controls ref={audioRef}>
                    <source src={playUrl} type="audio/mp3" />
                </audio>


            </div>
        </>
    )
}


export default SpecialAudioPlay;


