//import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Carousel } from 'react-bootstrap';
//import '../App.css';
import './TopSlide.css';
//it is a slide
const EvangelicalExpansion = (props) => {
    return (
        <React.Fragment>
            <Carousel variant="dark" className='Topslide_short' fade interval='1000'>
                <Carousel.Item>
                    <img
                        className="d-block w-100 smaller_img"

                        src="http://yem.life/wp-content/uploads/2021/04/Picnic2018-0156-Web-860x573.jpg"
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h5>Picnic </h5>
                        <p>We had fun in the park!</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div class="">
                        <img className="d-block w-100 smaller_img"
                            src="http://yem.life/wp-content/uploads/2021/04/Picnic2018-9979-Web-860x584.jpg" alt="" />
                    </div>
                    {/*
                    <img
                        className="d-block w-100"
                        src="http://yem.life/wp-content/uploads/2021/04/Picnic2018-9979-Web-860x584.jpg"
                        alt="Second slide"
                    />
                    */}
                    <Carousel.Caption>
                        <h5>Children</h5>
                        <div class="testimonial__text">
                            <p>What a day for all!</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 smaller_img"
                        src="http://yem.life/wp-content/uploads/2021/04/IMG_1159-Large-860x569.jpg"
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                        <h5>YEM's music team</h5>
                        <p>We love to sing praises to our Lord</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

        </React.Fragment>
    )
}

export default EvangelicalExpansion;//import 'bootstrap/dist/css/bootstrap.min.css';
