import React, { useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import Navbar from '../xuan2023/navbar'
const movieList = [
    { id: 1, url: 'https://www.mediafire.com/file/dzqrm5f1aynjx3v/01+Bi+bo+lai.mp4/file' },
    { id: 2, url: 'https://www.mediafire.com/file/p79erw3nojq6jx2/02+Bi+bo+lai.mp4/file' },
    { id: 3, url: 'https://www.mediafire.com/file/h9lgi01ho68wh0y/03+Bi+bo+lai.mp4/file' }
]
const MoviePlayer = () => {
    const [movieUrl, setMovieUrl] = useState('https://www.mediafire.com/file/dzqrm5f1aynjx3v/01+Bi+bo+lai.mp4/file')
    const changeTitle = (evt) => {
        evt.preventDefault()
        const butValue = parseInt(evt.target.value) - 1
        console.log('im click on btn with val=' + butValue)
        for (let i = 0; i < movieList.length; i++) {

            if (i === butValue) {

                //console.log('then i got movie url=' + movieList[i]['url'])
                //console.log('need to display verse ctx' + verses[i]['verseCtx'])
                setMovieUrl(movieList[i]['url'])
            }
        }


    }
    return (
        <div className='container d-flex align-items-center justify-content-center'>


            <div>
                <Navbar />
                <div className='row'>
                    <div className='col'>

                    </div>
                    <div className='col'><ReactPlayer url={movieUrl} controls={true} width='800px' height='600px' />

                        <p className='text-primary'>Phim Bị Bỏ Lại: <button className='btn btn-sm' onClick={changeTitle} value='1'>p1</button> | <button className='btn btn-sm' onClick={changeTitle} value='2'>p2</button> | <button className='btn btn-sm' onClick={changeTitle} value='3'>p3</button></p>
                    </div>
                    <div className='col'></div>
                </div>





            </div>

        </div>





    )
}
export default MoviePlayer