import React from 'react';
import './css/common.css';

const DonationMainBody = () => {

    return (
        <>

            <section className="single-page single-ministry default-section-spacing">

                <div className="container">

                    <div className="description pad-b-sm-4">

                        <div class="section-heading">
                            <span className="section-intro"><a href='/DangHien' style={{ color: 'darkblue' }}>Tiếng Việt </a> &nbsp;| &nbsp; Donation</span>
                            <p className='left-align-text'>As we approach the Thanksgiving season and welcome the coming of our Savior, our church can be grateful for many things from this past year! In the spirit of giving thanks, our family of God here looks to give back in support of missions work, our ministries, and church building projects to come.
                            </p>
                            <p className='left-align-text'>With these final months of the year and a missions rally on December 4th, we would like to ask for your cheerful giving to support our church and missions across the globe!
                                All donations are also tax-deductible. Thank you for your generous hearts as we continue to build His Kingdom, for His glory.</p>
                            <h2>Different ways you can donate to help:</h2>
                        </div>
                        <p className='left-align-text'>
                            <span className='section-intro'>1. SECURED ONLINE FORM</span>
                            <br /><br />
                            <a href="https://app.easytithe.com/app/giving/loichua" style={{ backgroundColor: "", fontSize: "0.8em" }}>
                                <span className='section-intro'>&nbsp;&nbsp; General Church Fund -Click Here to Give Online</span></a>
                            <br />
                            <a href="https://app.easytithe.com/App/Form/0b8bdbd9-fffc-46f5-b192-fa75ba0340ce" style={{ backgroundColor: "" }}>
                                <span className='section-intro'>&nbsp;&nbsp; Building Fund -Click Here to Give Online</span></a>
                            <br />

                            <a href="https://app.easytithe.com/App/Form/dd078c94-3dfc-43b6-9b96-cb4c43d27fe9" style={{ backgroundColor: "" }}>
                                <span className='section-intro'>&nbsp;&nbsp; Missionary Fund -Click Here to Give Online</span></a>
                            <br />

                            <a href="https://app.easytithe.com/App/Form/c5af05b8-de08-4d87-b902-5aaa4249ead6" style={{ backgroundColor: "" }}>
                                <span className='section-intro'>&nbsp;&nbsp; Special Ministry -Click Here to Give Online</span></a>
                            <br />
                        </p>
                        <p className='left-align-text'>
                            <span className='section-intro'>2. CASH/CHECKS</span>
                            <br /><br />
                            Please make checks payable to Hoi Thanh Tin Lanh Loi Chua.
                            These checks may be dropped off in the offering plates during service.<br />
                            They may also be mailed to our address below: </p>
                        <p className='left-align-text'>
                            Hoi Thanh Tin Lanh Loi Chua<br />
                            3134 Frick Rd<br />
                            Houston<br />
                            TX 77038
                        </p>
                        <p className='left-align-text'>
                            <span class='section-intro'>3. ZELLE (Electronic Tranfer)</span><br /><br />
                            If you are already with Zelle , please use this email giving@loichua.church.<br />
                            For more information on Zelle,   <a href='https://www.zellepay.com/?gclid=Cj0KCQiA-K2MBhC-ARIsAMtLKRtmTIuFkxLvtcfxm_1Ew7hCxmWDA9ZJDV5nUdOiRhLr1I8P0Q9EdEwaAjrtEALw_wcB'>please visit this official Zelle site</a>
                        </p>
                    </div>
                    <div className="responsibilities pad-b-sm-4">

                        <div className="section-heading">
                            <span className="section-intro">Weekly Program</span>
                            <h2>&nbsp;</h2>
                            <p>Every Sunday at 10:30AM</p>
                        </div>
                        <p></p>
                        <p>
                        </p>
                    </div>
                    <div className="details pad-b-sm-2">
                        <div className="section-heading">
                            <span className="section-intro">Details</span>
                            <h2>&nbsp;</h2>
                        </div>
                        <div className="row">

                            <div className="flex-md-6 flex-lg-4">

                                <div className="icon-text mar-b-sm-06">
                                    <span className="icon"><i class="ri-user-star-line"></i></span>
                                    <span className="text bold">Pastor Le P Thuan</span>
                                </div>
                                <div className="icon-text mar-b-sm-06">
                                    <span className="icon"><i class="ri-group-line"></i></span>
                                    <span className="text bold">150 members </span>
                                </div>
                                <div className="icon-text">
                                    <span className="icon"><i className="ri-calendar-line"></i></span>
                                    <span className="text bold">Every Sunday @ 10:30AM</span>
                                </div>
                            </div>
                            <div className="flex-md-6 flex-lg-4">

                                <div className="icon-text mar-b-sm-06">
                                    <span className="icon"><i className="ri-phone-line"></i></span>
                                    <span className="text bold">713-239-1466</span>
                                </div>

                                <div className="icon-text mar-b-sm-06">
                                    <span className="icon"><i className="ri-mail-line"></i></span>
                                    <span className="text bold">htloichua@gmail.com </span>
                                </div>
                                <div className="icon-text">
                                    <span className="icon"><i className="ri-map-pin-line"></i></span>
                                    <span className="text bold">3134 Frick Rd, Houston , TX 77038</span>
                                </div>
                            </div>
                            <div className="flex-md-12 flex-lg-4 mar-t-md-2 mar-t-lg-0">

                                <div className="icon-text">
                                    <span className="icon"><i className="ri-money-dollar-circle-line"></i></span>
                                    <span className="text bold">FREE to join</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="single-page__share background-off-white pad-t-sm-2 pad-b-sm-2">

                    <div className="container">

                        <div className="display-flex align-items-center">

                            <div className="bold leading text">Share</div>

                            <div className="social">
                                <a href="/" className="transparent button button--primary button--outline share-button"><i
                                    className="ri-facebook-line"></i></a>
                            </div>
                            <div className="social">
                                <a href="/" className="transparent button button--primary button--outline share-button"><i
                                    className="ri-linkedin-line"></i></a>
                            </div>
                            <div className="social">
                                <a href="/" className="transparent button button--primary button--outline share-button"><i
                                    className="ri-twitter-line"></i></a>
                            </div>
                            <div className="social">
                                <a href="/" className="transparent button button--primary button--outline share-button"><i
                                    className="ri-instagram-line"></i></a>
                            </div>

                        </div>

                    </div>

                </div>

                <div className="donation_bg text-center-sm color-off-white default-section-spacing">

                    <h3 className="mar-b-sm-2">Mỗi người nên tùy theo lòng mình đã định mà quyên ra, không phải phàn nàn hay là vì ép uổng;
                        vì Đức Chúa Trời yêu kẻ dâng của cách vui lòng (II Cô-rinh-tô 9:7)</h3>
                    <a href="https://app.easytithe.com/app/giving/loichua" className="button button--primary button--fill">Give today</a>

                </div>

            </section>

        </>
    )
}

export default DonationMainBody;