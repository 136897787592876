import React from 'react';

const VideoChildrenBackground = () => {

    return (
        <>

            <section class="hero hero--video-bg full-width display-flex justify-align-center">

                <div id="video-bg" class="hero__video--bg">
                    <video autoPlay muted loop>
                        <source src="assets/media/children-study-bible.mp4" type="video/mp4"></source>

                    </video>
                </div>
                <div class="hero__content full-width text-center-sm">

                    <div class="container">

                        <div class="heading mar-b-sm-2">
                            <span class="section-intro mar-b-sm-2">Jesus is Lord</span>
                            <h1 class="color-off-white">Bring your children to study and have fun!<br /></h1>
                        </div>

                        <div class="hero__cta"><a href="/about" class="button button--primary button--fill">Learn more</a></div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default VideoChildrenBackground;