import React from 'react'
import { Route, BrowserRouter } from "react-router-dom";
import DataTable, { createTheme } from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { Form, Button, Row, Col, Pagination } from 'react-bootstrap'

//import Export from 'react-data-table-component'
import { useEffect, useContext, useState, useMemo } from 'react';
import AuthContext from '../store/auth'
import axios from 'axios'
//import '../xuan2023/modal.css'
import Popup from "reactjs-popup";
//import "bootstrap/dist/css/bootstrap.min.css";
import "reactjs-popup/dist/index.css";
import ProtectedTwo from '../ProtectedToo'
import EasterComp from '../easter2023/Easter';
//try to download data to csv file
import { CSVLink, CSVDownload } from "react-csv";
//import { MyTextInput, MyCheckBox, MySelect } from '../generalform/GeneralFormik'
// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme('solarized', {
    text: {
        primary: '#1c60f5',
        secondary: '#2aa198',
    },
    background: {
        default: 'transparent',
    },
    context: {
        background: '#80aeab',
        text: '#1c50f5',
    },
    divider: {
        default: '#073642',
    },
    action: {
        button: 'rgba(0,0,0,.60)',
        hover: 'rgba(0,0,0,.09)',
        disabled: 'rgba(0,0,0,.14)',
    },
});
const columns = [
    {
        name: 'User ID',
        selector: row => row.userid,
        sortable: true,
        grow: 2
    },
    {
        name: 'Full Name',
        selector: row => row.fullname,
        grow: 3
    },

    {
        name: 'Phone',
        selector: row => row.phone,
        grow: 2
    },

    {
        name: 'Relation',
        selector: row => row.relation,
        grow: 2
    },

    {
        name: 'Age',
        selector: row => row.age,
        sortable: true,
        grow: 3 //set increase width to show all job number or it is truncated if long string
    },
    {
        name: 'Gender',
        selector: row => row.gender
    },
    {
        name: 'Room No#',
        selector: row => row.roomNum
    },

];

const DownloadCsv = (props) => {
    return (
        <>
            <CSVLink data={props.dataSource}>Download Room Allocation CSV</CSVLink>


        </>
    );
};

//const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

const HoiDongRoomList = () => {

    const useCtx = useContext(AuthContext)

    const [dataTable, setDataTable] = useState([])
    const [rowData, setRowData] = useState([])
    const [formOn, setFormOn] = useState(false)
    const [roomNum, setRoomNum] = useState('')
    const [userId, setUserId] = useState('')
    const [errorOn, setErrorOn] = useState('')
    const [successOn, setSuccessOn] = useState('')


    //useEffect(()=>{
    //fetch('https://api.loichua.church:5001/main/get-room-list').then( res=>res.json()).then( result=>{
    // 	   console.log('get all data' + JSON.stringify(result))

    //	 }, [] )
    useEffect(() => {
        fetch("https://api.loichua.church:5001/main/get-hoidong-room-list").then(res => res.json()).then(
            result => {
                console.log(' got all data ' + JSON.stringify(result))
                if (result.status === 1) {
                    setDataTable(result.data)
                }
            }
        )
    }, [])//end use effect
    //const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(dataTable)} />, []);
    const rowHandler = row => {
        console.log(' im selected and row ' + JSON.stringify(row) + '\n fullname=' + row.fullname + ' id=' + row.userid)
        setRowData(row)
        setFormOn(true)

    }
    const roomHanlder = (e) => {
        e.preventDefault()
        setRoomNum(e.target.value)
    }
    /**
     *  clik on the row to assign room
     * @param {*} props 
     */
    const UpdateRoom = (props) => {
        const userid = props.rowData.userid
        const username = props.rowData.fullname
        const relation = props.rowData.relation
        setErrorOn('')
        if (relation !== 'primary') {
            console.log('error must select primary user')
            setErrorOn('You must select Primary Use Only!')

        }

        const submitHandler = (e) => {
            e.preventDefault()
            const submittedData = {
                userid: userid,
                roomNum: e.target.roomNum.value
            }
            console.log(' got data submited to ' + JSON.stringify(submittedData))
            axios.post(useCtx.updateHotelRoomUrl, submittedData).then(
                response => {
                    console.log('got server resp ' + response.data.status + ' message ' + response.data.message)
                    setSuccessOn(response.data.message + ' ' + username)
                    document.location.reload()
                }
            ).catch(error => {
                setErrorOn(error)
                console.log(' got error ' + error)
            }
            )



        }//end submit handler
        return (
            <>
                <form onSubmit={submitHandler}>
                    <h6>Add/Change/Update Room for {props.rowData.fullname}</h6>
                    <p className='text-danger'>{errorOn ? errorOn : ''}</p>
                    <p className='text-info'>{successOn ? successOn : ''}</p>

                    <input type='text' defaultValue={userid} hidden />
                    <input type='text' defaultValue={roomNum} placeHolder='Enter a room number' maxLength={8} name='roomNum' required />
                    <input type='submit' value='Submit' />
                </form>
            </>
        )
    }
    //if (props.bLogin) {
    //  console.log('bLogin ' + props.bLogin)

    return (
        <div className='container'>
            <DataTableExtensions

                columns={columns}
                data={dataTable}


                print={false}
                export={false}


            >
                <DataTable
                    title='HDGH Listing - Room Allocations'
                    pagination
                    dense={true}

                    theme="solarized"
                    highlightOnHover


                    onRowClicked={rowHandler}

                />
            </DataTableExtensions>
            <div>
                {formOn ? <UpdateRoom rowData={rowData} /> : ''}
            </div>
            <div>
                <a href='/hoidong2024/listing/login' target='new'>Back To HD Admin</a>
            </div>

            <DownloadCsv dataSource={dataTable} />
        </div>
    )
    //}
    //else {
    //    console.log(' no login')
    //   window.location.replace('/login')
    //}
}

export default HoiDongRoomList