import React , {useState, useContext} from 'react'
import { Form, Button, Row, Col, FormGroup, FormControl, FormLabel, FormCheck } from 'react-bootstrap';
import axios from 'axios'
import AuthContext from '../store/auth'

const EmailBot=()=>{
	 const useCtx = useContext(AuthContext)
	 const [formData, setFormData] = useState({
											    sender:'',
											    subjectTxt: '',
											    
											    emailContent: '',
											    emailGroup:''
											  	})
  	const [errors, setErrors] = useState({});
	
	const handleChange = (event) => {
		    const { name, value, checked } = event.target;
		    setFormData((prevState) => ({ ...prevState, [name]: value })); // Handle both text and checkbox values
		    setErrors((prevState) => ({ ...prevState, [name]: null })); // Clear error on change
		  }
	
	const validateForm = () => {
	    let newErrors = {}
	    console.log('im here ')
	    if (!formData.subjectTxt) {
	      newErrors.subjectTxt = 'Email Subject is required!'
	    }
	    if (!formData.sender) {
	      newErrors.sender = 'Sender is required!'
	    }
	    if (!formData.emailContent) {
	      newErrors.emailContent = 'Empty message!'
	    }
	    if (!formData.emailGroup) {
	      newErrors.emailGroup = 'Select a group to send email'
	    }
	    
	   
	    
	
	    return newErrors;
	  }
	
	const handleSubmit = (e) => {
	    e.preventDefault();
	
	    const newErrors = validateForm();
	    setErrors(newErrors);
	
	    if (Object.keys(newErrors).length === 0) {
	      // Submit form data (e.g., send to server using fetch or axios)
	      console.log('Form submitted successfully:', formData);
	      //action to backend
	      
	      axios.post(useCtx.mailUrl, formData).then(
		    response=>{
			   console.log(response.data.status, response.data.message)
                    console.log(' i got ' + response.status)
                    if (response.status === 200) {
                        if (!response.data.status) {
                            console.log(' error in data saving: ' + response.data.message)
                            //setDbResponse(response.data.message)

                        }
                        else {
                            console.log(response.data.message)

                        }
                    }
                }
		    
	      ).catch(error=>{
		       console.log('got error')
	       })
	      
	      
	      
	      setFormData({ sender: '', subjectTxt: '', emailContent:'', mailGroup:''}); // Clear form after successful submission
	    }
    }//and handle submit
    const myStyle={
	  color:'blue',
	  
    }
    const errStyle={
	  color:'red',
	  
    }
	return(
		<div className='container' style={myStyle}>
	<Form noValidate onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Label><span style={myStyle}>Email From</span></Form.Label>
          <Form.Control
            type="text"
            name="sender"
            value={formData.sender}
            onChange={handleChange}
            
            isInvalid={!!errors.sender}
          />
          <Form.Control.Feedback type="invalid"><span style={errStyle}>{errors.sender}</span></Form.Control.Feedback>
        </Form.Group>
      </Row>
      
      
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Label><span style={myStyle}>Email Subject</span></Form.Label>
          <Form.Control
            type="text"
            name="subjectTxt"
            value={formData.subjectTxt}
            onChange={handleChange}
            
            isInvalid={!!errors.subjectTxt}
          />
          <Form.Control.Feedback type="invalid"><span style={errStyle}>{errors.subjectTxt}</span></Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Label><span style={myStyle}>Email Content: (type in default to use premade message)</span></Form.Label>
          <Form.Control
            as="textarea"
            rows="5"
            name="emailContent"
            value={formData.emailContent}
            onChange={handleChange}
            isInvalid={!!errors.emailContent}
          />
          <Form.Control.Feedback type="invalid"><span style={errStyle}>{errors.emailContent}</span></Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label><strong>Send to group of  
             All Registrants, Unpaid Registrants</strong></Form.Label>
            <Form.Select
		            name="emailGroup"
		            value={formData.emailGroup}
		            onChange={handleChange}
		            isInvalid={!!errors.emailGroup}
            >
            <option value="">Select...</option>
            <option value="UNPAID">UNPAID REGISTRANTS</option>
            <option value="ALL">ALL REGISTRANTS</option>
           
          </Form.Select>
              <Form.Control.Feedback type="invalid"><span style={errStyle}>{errors.emailGroup}</span></Form.Control.Feedback>
         </Form.Group>
         
      </Row>
      <Button type="submit">Send Email</Button>
    </Form>
		 
		</div>
	)
	
}

export default EmailBot