import React from 'react';
import ShortStories from './ShortStories';
import BannerPhatThanhTinLanh from './BannerPhatThanhTinLanh';
import SmallTeamFooter from './SmallTeamFooter';
import SiteHeaderWithSlide from './SiteHeaderWithSlide';

const ShortStoriesContainer = () => {

    return (
        <>
            <SiteHeaderWithSlide />
            <main>
                <BannerPhatThanhTinLanh />
                <ShortStories id='1117'/>

            </main>
            <SmallTeamFooter />
        </>
    )
}
export default ShortStoriesContainer;