import React from 'react';
import mystyle from './css/loichuastyle.module.css'

const DocThemDetails = () => {
    const threePointStyle = {
        textAlign: 'left',

    }
    return (
        <>
            <section class="welcome welcome--2 welcome--about pad-t-sm-2 pad-b-sm-2 pad-t-lg-4 pad-b-lg-4">

                <div class="welcome__content">

                    <div class="container">

                        <div class="row align-items-center default-section-spacing pad-b-sm-0 mar-b-lg-4">

                            <div class="flex-lg-6" style={threePointStyle}>

                                <div class="section-heading" >
                                    <span class="section-intro">WELCOME</span>
                                    <h2>Site Map</h2>
                                </div>
                                <ul className={mystyle.card_font}>
                                    <li><a href="/read-dailyhope">Daily Hope -  Niềm Hy Vọng Hằng Ngày</a></li>
                                    <li><a href='/devotions'>Daily Hope - List by theme</a></li>
                                    <li><a href='/videovideo-list' ist>Watch Weekly Services</a></li>
                                    <li><a href='https://tinlanh.org/wp/chuong-trinh-hang-tuan/'>Listen Phat Thanh Tin Lanh</a></li>
                                    <li><a href='/contact'>Contact Us</a></li>


                                </ul>



                            </div>
                            <div class="flex-lg-6">

                                <div class="image-box mar-t-sm-8 mar-t-lg-0 mar-b-sm-8 mar-b-lg-0">

                                    <div class="box box--center">
                                        <img src="assets/images-cus/andrew-yem-standing.png" alt="" />
                                    </div>
                                    <div class="box box--top-right">
                                        <img src="assets/images/happy-kid.jpg" alt="" />
                                    </div>
                                    <div class="box box--bottom-right">
                                        <img src="assets/images/singing-girl.jpg" alt="" />
                                    </div>
                                    <div class="box box--top-left">
                                        <img src="assets/images-cus/church-front.jpeg" alt="" />
                                    </div>
                                    <div class="box box--bottom-left">
                                        <img src="assets/images/bible-sm.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default DocThemDetails;