import React, { useContext } from 'react';
import WelcomeVN from './WelcomeVN';
import VideoBackground from './VideoBackGround';
import YouTubeEmbed from './YouTubeEmbed';
import AuthContext from './store/auth';
import mystyle from './css/loichuastyle.module.css';
import PhatThanhTinLanh from './PhatThanhTinLanh';
//import GiangSinh2021Section from './GiangSinh2021Section';
//import GetLoiSongHangNgay from './GetLoiSongHangNgay';
import CardForAudio from './CardForAudio';
//import SinhHoatNamNuGioi from './SinhHoatNamNuGioi';
const MainSectionVN = (props) => {
    const useCtx = useContext(AuthContext);
    return (
        <React.Fragment>
            <main>

                <VideoBackground intro='Hội Thánh Tin Lành Lời Chúa' greeting="Tìm hiểu về HTTL Lời Chúa" learnmore='Xin xem thêm...' />

                <section class="upcoming-events">

                    <div class="upcoming-events__content">

                        <div class="row no-gutter">

                            <div class="flex-md-6 flex-lg-3">

                                <div class="upcoming-intro background-secondary-img">

                                    <div class="section-heading">
                                        <span class="section-intro" style={{ color: '#fff', fontSize:'1.5em' }}>Lễ Tạ Ơn 2022</span>
                                        <h4 style={{ color: '#fff' }}>Tối thứ năm 11/24/2022 lúc 7:30pm và</h4>
                                        <h4 style={{ color: '#fff' }}>Sáng Chúa Nhật 11/27/2022 lúc 10:30am </h4>
                                        

                                    </div>

                                    <a href="https://thuvien.loichua.church/le-ta-on-thanksgiving-2022/" class="button button--primary button--fill">Xem Thêm...</a>

                                </div>

                            </div>
                            <div class="flex-md-6 flex-lg-3">

                                <div class="card events">

                                    <div class="card__body" className={mystyle.card_font}>

                                        {/*<div class="label uppercase">Join</div> */}

                                        <div class="event__details mar-t-sm-2 mar-b-sm-2">

                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-calendar-line"></i></span>
                                                <span class="text">Chúa Nhật</span>
                                            </div>
                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-time-line"></i></span>
                                                <span class="text">10:30AM - 12:00PM</span>
                                            </div>
                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-map-pin-line"></i></span>
                                                <span class="text">{props.churchaddress}</span>
                                            </div>
                                        </div>
                                        <h3 class="event__tile">Thờ Phượng Chung</h3>

                                    </div>
                                    <div class="card__footer">

                                        <div class="card__cta">
                                            <a href="/about" class="button button--primary button--fill">Xem thêm...</a>
                                        </div>
                                        <div class="card__share text-right">
                                            <button class="transparent button button--primary button--outline share-button"><i
                                                class="ri-share-line"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-md-6 flex-lg-3">

                                <div class="card events">

                                    <div class="card__body" className={mystyle.card_font}>

                                        {/*<div class="label uppercase">Join</div> */}

                                        <div class="event__details mar-t-sm-2 mar-b-sm-2">

                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-calendar-line"></i></span>
                                                <span class="text"> Chúa Nhật</span>
                                            </div>
                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-time-line"></i></span>
                                                <span class="text">10:30AM-12:00PM</span>
                                            </div>
                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-map-pin-line"></i></span>
                                                <span class="text">{props.churchaddress}</span>
                                            </div>
                                        </div>
                                        <h3 class="event__tile">Thanh Thiếu Niên-YEM</h3>

                                    </div>
                                    <div class="card__footer">

                                        <div class="card__cta">
                                            <a href="/yem" class="button button--primary button--fill">Xem thêm...</a>
                                        </div>
                                        <div class="card__share text-right">
                                            <button class="transparent button button--primary button--outline share-button"><i
                                                class="ri-share-line"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-md-6 flex-lg-3">

                                <div class="card events">

                                    <div class="card__body" className={mystyle.card_font}>

                                        {/*<div class="label uppercase">JOIN</div> */}

                                        <div class="event__details mar-t-sm-2 mar-b-sm-2">

                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-calendar-line"></i></span>
                                                <span class="text">Chúa Nhật</span>
                                            </div>
                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-time-line"></i></span>
                                                <span class="text">11:45 AM - 12:45PM</span>
                                            </div>
                                            <div class="icon-text">
                                                <span class="icon"><i class="ri-map-pin-line"></i></span>
                                                <span class="text">{props.churchaddress}</span>
                                            </div>
                                        </div>
                                        <h3 class="event__tile">Nhi Đồng</h3>

                                    </div>
                                    <div class="card__footer">

                                        <div class="card__cta">
                                            <a href="/children-ministry" class="button button--primary button--fill">Xem thêm...</a>
                                        </div>

                                        <div class="card__share text-right">
                                            <button class="transparent button button--primary button--outline share-button"><i
                                                class="ri-share-line"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <CardForAudio />
                {/*<GiangSinh2021Section iframeurl='https://thuvien.loichua.church/chuc-mung-nam-moi-2022/'/> */}
                {/*
                <SinhHoatNamNuGioi iframeurl='https://thuvien.loichua.church/buoi-nhom-ban-nam-nu-gioi/' />
                */}
                <WelcomeVN />


                <section class="recent-sermon default-section-spacing background-off-white">

                    <div class="recent-sermon__content">

                        <div class="container">

                            <div class="row mar-b-sm-4">

                                <div class="flex-md-8 flex-lg-9">
                                    <div class="section-heading mar-b-sm-0">
                                        <span class="section-intro">Sứ điệp</span>
                                        <h2>Gần đây nhất</h2>
                                    </div>
                                </div>
                                <div class="flex-md-4 flex-lg-3 text-right-md">
                                    <a href="https://thuvien.loichua.church/youtube-channel/" class="button button--primary button--fill">Xem lại tất cả</a>
                                </div>

                            </div>

                            <div class="row">

                                <div class="flex-md-6 flex-lg-10">

                                    <div class="card sermon">


                                        <div class="card__body">

                                            <h3 class="sermon__tile mar-b-sm-2">&nbsp;</h3>

                                            <YouTubeEmbed embedId='18QW3sATycg' />
                                        </div>

                                        <div class="card__footer">

                                            <div class="card__cta">
                                                <a href='https://thuvien.loichua.church/youtube-channel/' class="button button--primary button--fill">Xem thêm tại Loi Chua YouTube Channel</a>
                                            </div>
                                            <div class="card__share text-right-sm">
                                                <button class="transparent button button--primary button--outline share-button"><i
                                                    class="ri-share-line"></i></button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="testimonial default-section-spacing text-center-sm">

                    <div class="container">

                        <div class="section-heading">
                            <span class="section-intro"><a href='/read-dailyhope'>Daily Hope</a></span>
                            <h3>Phát Thanh Tin Lành Hằng Tuần</h3>
                            <PhatThanhTinLanh />

                        </div>
                        <div id="testimonial-carousel" class="testimonial__carousel owl-carousel">

                            <div class="testimonial__element">

                                <div class="testimonial__picture">

                                    <div class="avatar avatar--large">
                                        <img src="assets/images/avatar-1.png" alt="" />
                                    </div>

                                </div>
                                <div class="testimonial__text">
                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio temporibus vitae architecto at
                                    harum hic autem facilis veniam. Adipisci, quibusdam. Tempore quasi eum incidunt asperiores illo
                                        consequatur perspiciatis, laboriosam ipsam, doloribus enim repellendus quo dolorum.</p>
                                </div>
                                <div class="testimonial__author bold leading">Jackie B. Venn</div>

                            </div>
                            <div class="testimonial__element">

                                <div class="testimonial__picture">

                                    <div class="avatar avatar--large">
                                        <img src="assets/images/avatar-4.png" alt="" />
                                    </div>

                                </div>
                                <div class="testimonial__text">
                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio temporibus vitae architecto at
                                    harum hic autem facilis veniam. Adipisci, quibusdam. Tempore quasi eum incidunt asperiores illo
                                        consequatur perspiciatis, laboriosam ipsam, doloribus enim repellendus quo dolorum.</p>
                                </div>

                                <div class="testimonial__author bold leading">John Doe</div>

                            </div>
                            <div class="testimonial__element">

                                <div class="testimonial__picture">

                                    <div class="avatar avatar--large">
                                        <img src="assets/images/avatar-6.png" alt="" />
                                    </div>

                                </div>
                                <div class="testimonial__text">
                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio temporibus vitae architecto at
                                    harum hic autem facilis veniam. Adipisci, quibusdam. Tempore quasi eum incidunt asperiores illo
                                        consequatur perspiciatis, laboriosam ipsam, doloribus enim repellendus quo dolorum.</p>
                                </div>

                                <div class="testimonial__author bold leading">H. Hyuas</div>

                            </div>
                        </div>
                    </div>
                </section>
                <div class="separator background-primary"></div>


                <section class="ministries default-section-spacing">

                    <div class="ministries__content">

                        <div class="container">

                            <div class="row mar-b-sm-4">

                                <div class="flex-md-8 flex-lg-9">
                                    <div class="section-heading mar-b-sm-0">
                                        <span class="section-intro">Các Mục vụ</span>
                                        <h3>&nbsp;</h3>
                                    </div>
                                </div>
                                <div class="flex-md-4 flex-lg-3 text-right-md">
                                    <a href="/coming-soon" class="button button--primary button--fill">Xem tất cả Mục vụ</a>
                                </div>
                            </div>

                            <div class="row">

                                <div class="flex-md-6 flex-lg-4">

                                    <div class="card ministry">

                                        <div class="card__header">

                                            <img src="assets/images-cus/yem-meeting.jpeg" alt="YEM - Youth activities at church Tinlanh " class="card__image" />

                                            <div class="card__details">

                                                <div class="ministry__details">

                                                    <div class="icon-text">
                                                        <span class="icon"><i class="ri-group-line"></i></span>
                                                        <span class="text color-off-white"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card__body">

                                            <h3 class="ministry__tile mar-b-sm-2">Mục vụ Thanh Thiếu Niên (YEM)</h3>
                                            <p class="excerpt" className={mystyle.card_font}>
                                                <p>Welcome to the Youth & English Ministry at Houston Vietnamese Christian Church! We are a united family Living In Fellowship Everyday with our Creator and community of believers. In all our ministries, we seek to glorify God and share the Gospel of Jesus Christ!
                                                    ...</p>
                                            </p>

                                        </div>
                                        <div class="card__footer">

                                            <div class="card__cta">
                                                <a href="ministries-single.html" class="button button--primary button--fill">Xem thêm...</a>
                                            </div>

                                            <div class="card__share text-right-sm">
                                                <button class="transparent button button--primary button--outline share-button"><i
                                                    class="ri-share-line"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex-md-6 flex-lg-4">

                                    <div class="card ministry" >

                                        <div class="card__header">

                                            <img src="assets/images-cus/children-choir.jpeg" alt="" class="card__image" />

                                            <div class="card__details">

                                                <div class="ministry__details">

                                                    <div class="icon-text">
                                                        <span class="icon"><i class="ri-group-line"></i></span>
                                                        <span class="text color-off-white"> {/*12 podcasters */}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card__body" >

                                            <h3 class="ministry__tile mar-b-sm-2">Mục vụ Thiếu Nhi</h3>
                                            <p class="excerpt" className={mystyle.card_font}>
                                                Mục Vụ Thiếu Nhi là nơi các em từ 3 tuổi đến 12 tuổi được vui chơi,
                                                làm thủ công và học hỏi về Lời Chúa dạy trong Kinh Thánh.
                                                Một môi trường lành mạnh để các em được nuôi dưỡng và lớn lên
                                                trong tình yêu Chúa. Mời quí phụ huynh mang con em mình đến với chúng tôi.

                                            </p>

                                        </div>
                                        <div class="card__footer">

                                            <div class="card__cta">
                                                <a href="/children-ministry" class="button button--primary button--fill">Xem thêm...</a>
                                            </div>
                                            <div class="card__share text-right-sm">
                                                <button class="transparent button button--primary button--outline share-button"><i
                                                    class="ri-share-line"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex-md-12 flex-lg-4 mar-t-md-2 mar-t-lg-0">

                                    <div class="card ministry">

                                        <div class="card__header">

                                            <img src="assets/images-cus/loichua-adult-choir.jpeg" alt="" class="card__image" />

                                            <div class="card__details">

                                                <div class="ministry__details">

                                                    <div class="icon-text">
                                                        <span class="icon"><i class="ri-group-line"></i></span>
                                                        <span class="text color-off-white"></span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="card__body">

                                            <h3 class="ministry__tile mar-b-sm-2">Ban Nam Giới & Phụ Nữ</h3>
                                            <p class="excerpt" className={mystyle.card_font}>Hội thánh chào mừng các quý ông, quý bà , các cô tham gia
                                            vào sinh hoạt của các ban nam & nữ giới để cùng thông công học hỏi Lời Chúa, trao đổi kinh nghiệm và chia xẻ
                                            những gánh nặng đời sống, cùng thờ phượng sống động và vinh hiển danh Chúa.
                                                <br />

                                            </p>

                                        </div>

                                        <div class="card__footer">

                                            <div class="card__cta">
                                                <a href="coming-up" class="button button--primary button--fill">Learn more</a>
                                            </div>

                                            <div class="card__share text-right-sm">
                                                <button class="transparent button button--primary button--outline share-button"><i
                                                    class="ri-share-line"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="podcast-cta pad-t-sm-6 pad-t-lg-8 pad-b-sm-6 pad-b-lg-8">

                    <div class="container">

                        <div class="row text-center-sm text-left-lg align-items-center">

                            <div class="flex-lg-9">
                                <h2 class="color-off-white">Xem lại các chương trình thờ phượng hằng tuần tại  kênh YouTube HT Lời Chúa.
                                </h2>
                            </div>
                            <div class="flex-lg-3 text-right-lg">
                                <a href={useCtx.youtubeURL} class="button button--outline button--off-white">Xem thêm</a>
                            </div>

                        </div>
                    </div>
                </section>

                <div class="combine-section">

                    <section class="staffs default-section-spacing text-center-sm">

                        <div class="staffs__content">

                            <div class="container">

                                <div class="section-heading">
                                    <span class="section-intro"></span>
                                    <h2>Các Mục sư tại HT Lời Chúa</h2>
                                </div>
                                <div class="row">

                                    <div class="flex-md-6 flex-lg-3">

                                        <div class="card staff">

                                            <div class="card__header no-overlay">

                                                <img src="assets/images-cus/ms-le-thuan-2.jpg" alt="" class="card__image" />

                                            </div>
                                            <div class="card__body">

                                                <h3 class="cause__tile mar-b-sm-2">MS Lê Phước Thuận <br />(quản nhiệm) </h3>

                                                <p>Senior pastor</p>

                                            </div>
                                            <div class="card__footer justify-align-center">

                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-facebook-line"></i></a>
                                                </div>
                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-linkedin-line"></i></a>
                                                </div>
                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-twitter-line"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex-md-6 flex-lg-3">

                                        <div class="card staff">

                                            <div class="card__header no-overlay">

                                                <img src="assets/images-cus/pastor-ngon-bui.jpg" alt="" class="card__image" />

                                            </div>
                                            <div class="card__body">
                                                <h3 class="cause__tile mar-b-sm-2">MS Bùi Trung Ngôn <br />(phụ tá)</h3>
                                                <p>Associate Pastor</p>
                                            </div>

                                            <div class="card__footer justify-align-center">

                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-facebook-line"></i></a>
                                                </div>
                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-linkedin-line"></i></a>
                                                </div>
                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-twitter-line"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-md-12 flex-lg-3 mar-t-md-2 mar-t-lg-0">

                                        <div class="card staff">

                                            <div class="card__header no-overlay">

                                                <img src="assets/images-cus/pastor-sang.jpg" alt="" class="card__image" />

                                            </div>
                                            <div class="card__body">

                                                <h3 class="cause__tile mar-b-sm-2">Truyền đạo Trần Sang (YEM)</h3>

                                                <p>Associate Pastor</p>

                                            </div>
                                            <div class="card__footer justify-align-center">

                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-facebook-line"></i></a>
                                                </div>
                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-linkedin-line"></i></a>
                                                </div>

                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-twitter-line here"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Added pastor Huyen */}
                                    <div class="flex-md-12 flex-lg-3 mar-t-md-2 mar-t-lg-0">

                                        <div class="card staff">

                                            <div class="card__header no-overlay">

                                                <img src="assets/images-cus/ms-huyen.jpeg" alt="" class="card__image" />

                                            </div>
                                            <div class="card__body">

                                                <h3 class="cause__tile mar-b-sm-2">MS Phạm Văn Huyên (cộng tác viên)</h3>

                                                <p>Associate Pastor</p>

                                            </div>
                                            <div class="card__footer justify-align-center">

                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-facebook-line"></i></a>
                                                </div>
                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-linkedin-line"></i></a>
                                                </div>

                                                <div class="card__share">
                                                    <a href="/" class="transparent button button--primary button--outline share-button"><i
                                                        class="ri-twitter-line here"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="decorative-bg"></div>
                    <section class="default-section-spacing">

                    </section>


                </div>

            </main>
        </React.Fragment>
    )
};

export default MainSectionVN;