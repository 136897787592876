import React from 'react';

const ScrollBack = (props)=>{
	return(
	<React.Fragment>
       <div class="scroll-to-top"><i class="ri-arrow-up-line"></i></div>
	</React.Fragment>
	)
}

export default ScrollBack;