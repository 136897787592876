import React from 'react';
//import SiteHeader from './SiteHeader';
import HeaderNavigator from './HeaderNavigator';
import ContactForm from './ContactForm';
import FooterSimple from './FooterSimple';
const Contact = (props) => {

    return (
        <React.Fragment>
            <header class="header light-text" data-onscroll-classes="fixed-header animate-header">

                <div class="header__top-nav background-off-white">

                    <div class="container">
                        <div class="verse-of-the-day hide-on-md">

                            <button class="button button--secondary button--fill button-block-sm no-shadow modal-button"
                                data-modal-target="verse-of-the-day">Click to see the verse of the day</button>

                        </div>

                        <div class="verse-of-the-day hide-on-sm show-on-md">

                            <div class="row align-items-center">
                                <div class="flex-md-3 flex-lg-2">
                                    <div class="background-secondary text-center-md verse-of-the-day-button">Verse of the day</div>
                                </div>
                                <div class="flex-md-9 flex-lg-10">
                                    <p class="italic">Rejoice always, pray without ceasing, give thanks in all circumstances; for this is
                  the will of God in Christ Jesus for you. <span class="citation bold">~1 Thessalonians 5:16-18</span>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <HeaderNavigator />
            </header>

            <main>
                {/* Begin banner */}
                <section class="banner full-width">

                    <div class="container">

                        <div class="banner__content">

                            <div class="section-heading">
                                <span class="section-intro">Jesus is Lord</span>
                                <h1>Contact</h1>
                            </div>
                            <div class="breadcrumb">
                                <div class="breadcrumb__home--link"><a href="/">Home</a></div>
                                <span>/</span>
                                <div class="breadcrumb__current--page-link">Contact</div>
                            </div>
                        </div>

                    </div>

                </section> {/* banner section end */}
                <ContactForm />
            </main>
            <FooterSimple footerMessage='Feeling down, anxious , challenge in life, feel free to call us' />

        </React.Fragment>

    )
}

export default Contact;