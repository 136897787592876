import React from 'react';
//import Navbar from '../christmas/navbar';
//import AuthContext from '../store/auth'
//import CommonFooter from '../christmas/common_footer'
import ChurchDashboard from './ChurchDashBoard';

const Protected = (props) => {
	if(!props.bLogin){
		
		return 'You are not authorized...'
	}
    else{
		 const bLogin = localStorage.getItem('bLogin')
		 console.log('check local storage cookie login='+bLogin)
		if(!bLogin){
			//return 'You are not authorized...'
			localStorage.setItem('bLogin',true)
			
		}
		
		 console.log(' before i go to church dashboard blogin ' + bLogin + ' but cookie = '+ localStorage.getItem('bLogin'))
		    return <ChurchDashboard bLogin={props.bLogin}/>	
		
	   
	
	}
}

export default Protected