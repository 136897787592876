import React, { useState, useContext } from 'react';
import axios from 'axios';
import AuthContext from './store/auth';

const JobSubmissionForm = (props) => {
    const useCtx = useContext(AuthContext);
    const [fname, setFname] = useState('');
    const [email, setEmail] = useState('');
    //const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('')
    const [phone, setPhone] = useState('')
    const [groupname, setGroupName] = useState('')
    const [hourCount, setHourCount] = useState('')
    const [daterequired, setJobTimeRequired] = useState('')

    const fnameHandler = (event) => {
        setFname(event.target.value);
    }
    const emailHandler = (event) => {
        setEmail(event.target.value);
    }
    //const subjectHandler = (event) => {
    //    setSubject(event.target.value);
    //}
    const messageHandler = (event) => {
        setMessage(event.target.value);
    }
    const phoneHandler = (event) => {
        setPhone(event.target.value);
    }
    const groupNameHandler = (event) => {
        setGroupName(event.target.value);
    }
    const hourCountHandler = (event) => {
        setHourCount(event.target.value);
    }
    const jobDateTimeHandler = (event) => {
        setJobTimeRequired(event.target.value);
    }
    const formHandler = (event) => {
        event.preventDefault();
        const submitData = {
            fname: fname,
            email: email,
            phone: phone,
            groupname: groupname,
            hourCount: hourCount,
            daterequired: daterequired,
            //subject: subject,
            message: message
        }
        console.log('got all submitDaata ' + JSON.stringify(submitData));
        axios.post(useCtx.sendJobSubmissionURL, submitData).then(
            response => {
                console.log(response.data)
                console.log('i got ' + response.status);
                if (response.status === 200) {
                    console.log('im here')
                    window.location.replace(useCtx.successURL);
                }
                else {
                    console.log('cant do')
                }
            }
        ).catch(error => {
            console.log('got error ' + error)
        })

    }
    return (

        <React.Fragment>

            <section class="contact default-section-spacing">

                <div class="container">

                    <form class="form contact__form" onSubmit={formHandler}>

                        <div class="row">

                            <div class="flex-md-6">

                                <div class="form__group">
                                    <label for="fname" class="form__label">Your full name <span class="color-danger">*</span></label>
                                    <input type="text" id="fname" class="form__input" name="fname" placeholder="Enter your full name" onChange={fnameHandler} required />
                                </div>
                            </div>
                            <div class="flex-md-6 mar-b-md-2">

                                <div class="form__group">
                                    <label for="email-2" class="form__label">Your email address <span
                                        class="color-danger">*</span></label>
                                    <input type="email" id="email-2" class="form__input" name="email"
                                        placeholder="Enter your email address" onChange={emailHandler} required />
                                </div>
                            </div>
                            <div class="flex-md-6">

                                <div class="form__group">
                                    <label for="phone" class="form__label">Phone Number <span class="color-danger">*</span></label>
                                    <input type="text" id="phone" class="form__input" name="phone" placeholder="Enter your phone " onChange={phoneHandler} maxLength='12' required />
                                </div>
                            </div>
                            <div class="flex-md-6 mar-b-md-2">

                                <div class="form__group">
                                    <label for="email-2" class="form__label">Work Group<span
                                        class="color-danger">*</span></label>
                                    <input type="text" id="email-2" class="form__input" name="groupname"
                                        placeholder="group name , i.e outdoor lawn & landscaping  " onChange={groupNameHandler} maxLength='255' />
                                </div>
                            </div>
                            <div class="flex-md-6">

                                <div class="form__group">
                                    <label for="fname" class="form__label">Total Labour (hours) <span class="color-danger">*</span></label>
                                    <input type="text" id="phone" class="form__input" name="hourCount" placeholder="i.e  10 hours " onChange={hourCountHandler} maxLength='100' />
                                </div>
                            </div>
                            <div class="flex-md-6 mar-b-md-2">

                                <div class="form__group">
                                    <label for="email-2" class="form__label">Job Done on Date<span
                                        class="color-danger">*</span></label>
                                    <input type="text" id="email-2" class="form__input" name="daterequired"
                                        placeholder="Enter date i.e 4/4/2022 from 1pm to 4pm " onChange={jobDateTimeHandler} />
                                </div>
                            </div>


                            <div class="flex-md-12 mar-b-md-2">

                                <div class="form__group">
                                    <label for="message-2" class="form__label">Work Description<span class="color-danger">*</span></label>
                                    <textarea name="message" id="message-2" class="form__textarea form__input"
                                        placeholder="Briefly describe work done, i.e mowing front /back e.t.c" onChange={messageHandler}></textarea>
                                </div>
                            </div>
                            <div class="flex-md-12">

                                <button class="button--primary button--fill" type="submit" name="submit">Send message</button>

                            </div>

                        </div>
                    </form>
                </div>
            </section>
        </React.Fragment>
    )
}

export default JobSubmissionForm;
