import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import AuthContext from './store/auth';
//import { Link } from 'react-router-dom';

//import AboutUs from './AboutUs';
import './HeaderNavigator.css';
import BurgerMenu from './BurgerMenu';
const HeaderNavigatorVN = (props) => {

    const useCtx = useContext(AuthContext);
    return (
        <React.Fragment>
            <Helmet>
                <title>Hội Thánh Tin Lành Lời Chúa </title>
                <meta name='description' content='Hội Thánh Tin Lành Lời Chúa trực thuộc Truyền Giáo Phước Âm Liên Hiệp CMA. Nhà thờ Tin Lành cơ sở tại 3134 Frick Rd, Houston , TX 77038' />
                <meta name='keywords' content='tin lanh, song dung muc dich, purposed driven life, CMA , Houston, loi chua, nguoi viet' />


            </Helmet>
            <nav className="bottom-nav">

                <div className="container">

                    <div className="header__nav">

                        {/*<div className="header__logo brand--logo"> */}
                        <div className='header__logo brand--logo'>
                            {/*<div style={logoStyle}><a href="/"><img src="assets/images-cus/cma-logo.png" alt="loichua" /></a></div> */}
                            <h2 style={{ marginBottom: '2.5rem', fontSize: '2.5em', color: 'white' }}>LoiChua.Church</h2>
                        </div>
                        {/*
                        <div className="header__mobile--opener hide-on-lg">
                            <button className="header__mobile--icon" aria-expanded="false" aria-controls="mobile-menu" data-toggle="mobile-menu">
                                <span className="line"></span>
                                <span className="line"></span>
                                <span className="line"></span>
                            </button>
                        </div>
                        */}
                        <BurgerMenu />


                        <ul className="header__navitems show-on-lg" id="mobile-menu">

                            <li className="header__extra">
                                <div className="cta">
                                    <a href="/donations" className="button button-block-sm button--primary button--fill">Give</a>
                                </div>
                            </li>

                            <li className="header__list active">
                                <a href="/trang-nha" className="dropdown-link caret" >Trang nhà</a>

                                <div className="header__submenu">

                                    <ul>
                                        <li className="header__list active"><a href="/">Trang nhà </a></li>
                                        <li className="header__list"><a href='/about'>Mục Đích</a></li>
                                    </ul>

                                </div>
                            </li>
                            <li className="header__list"><a href='https://thuvien.loichua.church/tim-hieu-tin-lanh-2/'>Tìm Hiểu Tin Lành</a></li>
                            {/*<li className="header__list"><a href='/about'>Giới thiệu</a></li> */}

                            <li className="header__list">
                                <a href="/" className="dropdown-link caret" >Mục vụ</a>

                                <div className="header__submenu">
                                    <ul>
                                        { /* remove or add as need  */}
                                        <li className="header__list"><a href="/yem">Mục vụ Thanh Thiếu Niên</a></li>
                                        <li className="header__list"><a href="/children-ministry">Mục vụ Thanh Thiếu Nhi</a></li>
                                        <li className="header__list"><a href="/coming-soon">Ban Nam giới</a></li>
                                        <li className="header__list"><a href="/coming-soon">Ban Phụ nữ</a></li>
                                        <li className="header__list"><a href="/laos-mission">Truyền Giáo</a></li>



                                    </ul>
                                </div>
                            </li>
                            {/* 
                            <li className="header__list">
                                <a href="" className="dropdown-link caret">Chương trình</a>

                                <div className="header__submenu">
                                    <ul>
                                        <li className="header__list"><a href="/giang-sinh-2021">Giáng Sinh 2021</a></li>
                                        <li className="header__list"><a href="/event">Lễ Tạ Ơn & Mùa Thu</a></li>


                                    </ul>
                                </div>
                            </li>
                            */}
                            <li className="header__list">
                                <a href="/" className="dropdown-link caret">Truyền thông</a>

                                <div className="header__submenu">
                                    <ul>
                                        <li className="header__list"><a href="/phat-thanh-tin-lanh-houston-texas">Phát Thanh Tin Lành (MS Nguyễn Thỉ)</a></li>
                                        {/*<li className="header__list"><a href="/phat-thanh-lien-huu-tin-lanh">Phát Thanh Tin Lành Liên Hữu</a></li> */}

                                        <li className="header__list"><a href="/song-dung-muc-dich">Sống Đúng Mục Đích -Audio Rick Warren</a></li>

                                        {/*<li className="header__list"><a href="/video-list">Sứ điệp (YouTube)</a></li> */}
                                        <li className="header__list"><a href="https://thuvien.loichua.church/youtube-channel/">Bài Giảng Hằng Tuần (YouTube)</a></li>

                                        <li className="header__list"><a href="/devotions">Hy vọng hằng ngày</a></li>
                                        <li className="header__list"><a href="/read-dailyhope">Nối kết với Chúa</a></li>

                                        {/*<li className="header__list"><a href='/facebook-page'>Lời Chúa Facebook</a></li>*/}
                                        <li className="header__list"><a href='https://thuvien.loichua.church/hoi-thanh-tin-lanh-loi-chua-facebook/'>Lời Chúa Facebook</a></li>

                                        <li className="header__list"><a href={useCtx.youtubeURL}>Lời Chúa YouTube</a></li>

                                        <li className="header__list"><a href='https://thuvien.loichua.church'>Thư viện Lời Chúa</a></li>
                                    </ul>
                                </div>
                            </li>
                            <li className="header__list">
                                <a href="/" className="dropdown-link caret">Tài liệu</a>

                                <div className="header__submenu">
                                    <ul>
                                        {/*}
                                        <li className="header__list"><a href='/tai-lieu-suu-tam'>Trang Sưu tầm</a></li>
                                        */}
                                        <li className="header__list"><a href='https://thuvien.loichua.church/tuyen-tap/'>Trang Truyện Ngắn</a></li>


                                        <li className="header__list"><a href={useCtx.blogURL}>Lời Chúa Blog</a></li>



                                    </ul>
                                </div>
                            </li>

                            <li className="header__list"><a href="/contact">Liên lạc</a></li>


                        </ul>

                        <div className="header__extra desktop-version">
                            <div className="search">
                                <div className="search-icon">
                                    <button className="modal-button" data-modal-target="site-search"><i className="ri-search-line"></i></button>
                                </div>
                            </div>

                            <div className="cta hide-on-sm show-on-lg">
                                <a href="/DangHien" className="button button--primary button--fill">Dâng hiến</a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
}
export default HeaderNavigatorVN;