import React from 'react';
import './BannerAbout.css';
const BannerDocThem = () => {
    return (
        <>
            <section class="banner full-width">

                <div class="container">

                    <div class="banner__content">

                        <div class="section-heading">
                            <span class="section-intro">Jesus is Lord</span>
                            <h1>Please feel free to explore our site map for more useful resource & info. </h1>
                        </div>
                        <div class="breadcrumb">
                            <div class="breadcrumb__home--link"><a href="/">Home</a></div>
                            <span>/</span>
                            <div class="breadcrumb__current--page-link">SiteMap</div>
                        </div>
                    </div>
                </div>

            </section>

        </>
    )
}

export default BannerDocThem; 