import React from 'react';
import SiteHeader from './SiteHeader';
import SmallTeamFooter from './SmallTeamFooter';


const EmailSuccess = () => {
    return (
        <>
            <SiteHeader />
            <main>

                <section class="banner banner--success full-width text-center-sm">

                    <div class="container">

                        <div class="banner__content">

                            <div class="section-heading">
                                <div class="special"><i class="ri-checkbox-circle-line"></i></div>
                                <h3 class="special">Message successfully sent</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="success default-section-spacing text-center-sm">

                    <div class="container default-section-spacing">

                        <h2 class="regular-weight mar-b-sm-2">Thank you for contacting us.</h2>
                        <h2 class="regular-weight">We receive your message and will be in contact with you as soon as possible. God bless!</h2>
                        <p class="regular-weight">Cám ơn bạn đã liên lạc.  Chúng tôi đã nhận điện thư, sẽ hồi âm trong thời gian sớm nhất. </p>

                    </div>

                </div>
            </main>
            <SmallTeamFooter />
        </>
    )
}
export default EmailSuccess;