import React, { useContext } from 'react'
import CommonFooter from '../christmas/common_footer'
import Navbar from '../christmas/navbar'
import AuthContext from '../store/auth'
import GrabPostsComp from '../thuvien/GrabPostComp'

const FollowMeBlock = (props) => {
    const authCtx = useContext(AuthContext)
    /*
    const fontBold={
		color: 'darkblue', fontSize: '1.2rem', fontWeight: 'bold'
    }
    */
    return (
        <div className='container'>
            <Navbar />
            <div className='row'>
                <div className='col'>
                    <br />
                    <img src='https://static.wixstatic.com/media/b9fa65_5ba161b4672c4e40af64eb6414d1fce5~mv2.jpg/v1/fill/w_460,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_20210523_135410352.jpg' className='img-fluid' alt='...' />
                    <br />
                    <img src='https://static.wixstatic.com/media/b9fa65_e68b45b92fe5410f9d749e2bdd75ffdc~mv2.jpg/v1/fill/w_460,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_20201220_144440499.jpg' className='img-fluid' alt='...' />
                    <br />
                    <img src="/assets/images-cus/FollowMeMinistry.png" className='img-fuild' alt='...follow me ministry' />
                </div>
                <div className='col-6'>
                    <div className="shadow-lg p-3 mb-5 bg-body rounded">
                        {/*
		                     <img src='https://static.wixstatic.com/media/b9fa65_90f6bfe6949c4a199348f15831ced567~mv2.jpg/v1/fill/w_940,h_370,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_20210411_153507339_HDR.jpg' className='img-fluid' alt='...'/>
		                   
                            <p className='left-align-text lead'><span style={fontBold}>What we provide:</span></p>
                                <ul className='left-align-text lead'>
                                  <li style={fontBold}>Evangelism Class Training:</li>
                                   <li>-Evangelism in class training involves<br/></li>
                                  <li style={fontBold}>Evangelism In Action Training: </li>
                                   <li>-Actively share the gospel of Jesus Christ together with the trainer.<br/></li>
                                  <li style={fontBold}>Preparing the New Believer for a life with Christ:</li>

									<li>-Learning how to connect the new believer with contact, tools for their new life and with a dynamic Bible based fellowship that deciples believers
									<br/></li>
                                  <li style={fontBold}>Personal and spiritual preparation for Evangelism:</li>

									<li>-Every believer needs to understand their uniqueness and Identity in Christ.  They need to learn how to be empowered and led by the Holy Spirit, before they are ready to evangelize.  The art of evangelism is the work of the Holy Spirit and the one sharing Christ is just the faithful servant. </li>
                                </ul>
                                <p className='left-align-text lead'><span style={fontBold}>Contact:</span>
                                  <br/>
                                  Phone: 936-235-0118
                                  <br/>
								  
                                  
                                </p>
                                */}
                        {/** Grab post by cat id  */}
                        <GrabPostsComp url='https://thuvien.loichua.church/wp-json/wp/v2/posts?categories=93' />


                    </div>
                </div>
                <div className='col'>
                    <br />
                    <img src='https://static.wixstatic.com/media/b9fa65_0ca2c60c7f784ab28f5979c07c2a4d70~mv2.jpg/v1/fill/w_460,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_20210523_142645110.jpg' className='img-fluid' alt='...' />
                    <br />
                    <img src='https://static.wixstatic.com/media/b9fa65_b34b421627f34a36a523273ccc823ade~mv2.jpg/v1/fill/w_460,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_20210523_141337910.jpg' className='img-fluid' alt='...' />

                </div>

            </div>
            <CommonFooter authConfig={authCtx} />
        </div>
    )
}

export default FollowMeBlock