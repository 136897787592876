import React, { useState, useEffect  } from 'react'
//import { Form, Button, Row, Col } from 'react-bootstrap'
//import axios from 'axios'
//import AuthContext from '../store/auth'
//for payment schedule prices

//import NavBarHoiDongRego from './NavBarHoiDongRego'
//import backgroundImg from '../../../public/assets/images-cus/room-sheraton.jpeg' 
//./components/hoidong2024/img/room-sheraton.jpeg'
//../../../public/assets/images-cus/room-sheraton.jpeg'     
///assets/img-cus/room-sheraton.jpeg'
//import Popup from "reactjs-popup";



const RoomStat = () => {
	    /*
	    const styles = {
		    backgroundImage: `url(${backgroundImg})`,
		    backgroundSize: 'cover', // Adjust background size as needed (cover, contain, etc.)
		    backgroundRepeat: 'no-repeat', // Prevent image tiling
		    width: '100%', // Set width and height for the div
		    height: '400px', // Adjust height as needed
		  };
		  */
	    const [kingCnt, setKingCnt]=useState(0)
	    const [doubleCnt, setDoubleCnt]=useState(0) 
	    const [errorMessage, setErrorMessage]=useState('')
	    const [success,setSuccess]=useState(0)
	    useEffect(()=>{
			fetch('https://api.loichua.church:5001/main/get-room-stat').then(response=>response.json()).then(data=>{
			    //console.log('now getting data ' + JSON.stringify(data))
			    if (data.status){
				  setSuccess(1)
				  //console.log('got king '+ data.kingcnt )
				  setKingCnt(data.kingcnt)
				  //console.log(' got double ' + data.doublecnt)
				  setDoubleCnt(data.doublecnt)
			    }
			    else{
				  setErrorMessage('Data Not Available')
			   }
			    
			 })
			.catch(error=>console.error(error))
	    
	    },[])
	    
	    return (
        <div className='container'>
              
                     
                           
                 

                <div className="mb-3">
                     <img src='/assets/images-cus/room-sheraton.jpeg' style={{width:'60%', height:'300px'}} />
                     <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>  {/* Overlapping content */}
                     
                     <p style={{fontSize:'1rem', color:'lightgoldenrodyellow', marginTop:'150px', marginLeft:'8%'}}>
                            <br/>
                            Room Available To Book (Sold out soon!):
                            <br/>King Suite ={kingCnt}<br/>
                            Double Suite = {doubleCnt}
                            <br/>
                            
                     </p>
                     
                     {!success?errorMessage:''}
                     </div> 
                    
                
            </div>
        </div >
    )
}
export default RoomStat