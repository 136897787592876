/**
 * Page as container for all latest video list from blogspot of htloichua
 */

import React from 'react';
import VideoList from './VideoList';
import SiteHeader from './SiteHeader';
import ScrollBack from './ScrollBack';
import SmallTeamFooter from './SmallTeamFooter';
import BannerForVideoContainer from './BannerForVideoContainer';

const VideoContainer = () => {

    return (
        <>
            <SiteHeader />
            <main>
                <BannerForVideoContainer />
                <VideoList />
            </main>


            <ScrollBack />
            <SmallTeamFooter />
        </>
    )

}

export default VideoContainer;