import React from 'react';

const AllModals=(props)=>{
	return(
	
    <React.Fragment>
			<div id="verse-of-the-day" class="modal modal--verse hide-on-md modal--overlay">

      <div class="modal__content text-center-sm">
        <h3 class="mar-b-sm-2">Verse of the day</h3>
        <p class="italic mar-b-sm-06">Rejoice always, pray without ceasing, give thanks in all circumstances; for this
          is the will of God in Christ Jesus for you.</p>
        <span class="bold citation italic">~1 Thessalonians 5:16-18</span>
      </div>
    </div>
    <div id="site-search" class="modal modal--search modal--overlay text-center-sm">

      <div class="modal__content">
        <h3 class="mar-b-sm-2">Search our site</h3>

        <div class="container">

          <form class="form form--search">

            <div class="row">

              <div class="flex-lg-9">
                <div class="form__group">
                  <input type="text" class="form__input" placeholder="Search..." />
                </div>
              </div>
              <div class="flex-lg-3">
                <button type="submit" class="button--fill button--primary button-block-lg">Search our site</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
	

	</React.Fragment>
    
   	
	)

}
export default AllModals;