import React from 'react';
import SiteHeader from './SiteHeader';
import MainSection from './MainSection';
import ScrollBack from './ScrollBack';
import AllModals from './AllModals';
//import Footer from './Footer';
import FooterSimple from './FooterSimple';
const IndexOne = (props) => {

    return (
        <React.Fragment>
            <SiteHeader />
            <MainSection churchaddress='3134 Frick Rd, Houston, TX 77038' />
            <ScrollBack />
            <AllModals />
            <FooterSimple intro="Come fellowship with us"/>
        </React.Fragment>
    )
}
export default IndexOne;