import React from 'react';
import { Helmet } from 'react-helmet';
import SiteHeaderVN from './SiteHeaderVN';
import MainSectionVN from './MainSectionVN';
import ScrollBack from './ScrollBack';
import AllModals from './AllModals';
//import Footer from './Footer';
import FooterSimple from './FooterSimple';
const IndexOneVN = (props) => {

    return (
        <React.Fragment>
            <Helmet>
                <title>Welcome to Vietnamese Christian Church-Hội Thánh Tin Lành Lời Chúa</title>
                <meta name='description' content='Tin lành cho người Việt Houston, Texas. Mời bạn đến sinh hoạt với chúng tôi để tìm hiểu thêm Tin lành. Địa chỉ nhà thờ Tin lành 3134 Frick Rd, Houston TX 77038, phone 713 239 1466' />
                <meta name='keywords' content='tin lanh, phat thanh tin lanh, CMA , Houston, loi chua, nguoi viet' />
            </Helmet>
            <SiteHeaderVN />
            <MainSectionVN churchaddress='3134 Frick Rd, Houston, TX 77038' />
            <ScrollBack />
            <AllModals />
            <FooterSimple intro='Mời bạn nhóm thông công với chúng tôi' />
        </React.Fragment>
    )
}
export default IndexOneVN;