//import React, { useState, useEffect, useContext } from 'react';
import React  from 'react';
import VerseOfDay from './VerseOfDay';
//import AuthContext from './store/auth';
import HeaderNavigator from './HeaderNavigator';
//import AboutUs from './AboutUs';

const SiteHeader = (props) => {
    /*
    const useCtx = useContext(AuthContext);
    const [quote, setQuote] = useState('Be very careful, then, how you live—not as unwise but as wise, making the most of every opportunity, because the days are evil. Ephesians 5:15-16');
  
    useEffect(() => {
        //getQuote(); //turn off so we can see the padlock on loichua.org for now
        const intervalID = setInterval(() => {
            getQuote()
        }, 24 * 60 * 60 * 1000);
        return () => {
            clearInterval(intervalID);
        }
    }, []);

    function getQuote() {
        fetch(useCtx.quoteURL)
            .then(res => res.json())
            .then(data => {
                console.log(data);
                setQuote(data.verse);
                //setAuthor(data.contents.quotes[0].author);
                //setImgSrc(data.contents.quotes[0].background);
            }).catch(error => {
                //Here is still promise
                console.log(error);
                //error.json().then((body) => {
                //Here is already the payload from API
                //console.log(body);
            })
    }
  */
    return (

        <React.Fragment>

            <header class="header light-text" data-onscroll-classes="fixed-header animate-header">

                <div class="header__top-nav background-off-white">

                    <div class="container">

                        <div class="verse-of-the-day hide-on-md">

                            <button class="button button--secondary button--fill button-block-sm no-shadow modal-button"
                                data-modal-target="verse-of-the-day">Click to see the verse of the day</button>

                        </div>


                        <VerseOfDay verse='Blessed are the pure in heart,for they will see God. Matthew 5:8' frombib={''} language='Vietnamese version'/>
                    </div>
                </div>

                <HeaderNavigator />



            </header>


        </React.Fragment>
    )


}

export default SiteHeader;
